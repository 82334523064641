import {notification} from "antd";
import {ENTITY, STAFF} from "../constants";

export default class App {
    static showError(message) {
        notification['error']({
            message: 'Error',
            duration: 5,
            description: message,
        });
    }
    static showSuccess(message) {
        notification['success']({
            message: message,
            duration: 5,
            description: '',
        });
    }
    static getCatalogListAsArray(catalogList) {
        let arr = [];
        Object.keys(catalogList).forEach(function(key) {
            arr.push(catalogList[key]);
        });
        return arr;
    }
    static getTagColorByStatus(status, entityType = null) {
        if(entityType === ENTITY.TYPE.STAFF) {
            switch (status) {
                case STAFF.STATUS.ACTIVE:
                    return "success";
                case STAFF.STATUS.BANNED:
                    return "error";
                default:
                    return "default";
            }
        }
        else if(entityType === ENTITY.TYPE.GUARDS) {
            switch (status) {
                case STAFF.STATUS.ACTIVE:
                    return "success";
                case STAFF.STATUS.BANNED:
                    return "error";
                default:
                    return "default";
            }
        }
        else {
            switch (status) {
                case ENTITY.STATUS.ACTIVE:
                    return "success";
                case ENTITY.STATUS.INACTIVE:
                    return "error";
                default:
                    return "default";
            }
        }
    }
}
