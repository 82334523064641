export default class Route {
    windowContainsRoute = (route) => {
        if(typeof window !== undefined) {
            return (window.location.href.includes(route));
        }
    };
    windowContainsExactRoute = (route) => {
        if(typeof window !== undefined) {
            return (window.location.pathname === route);
        }
    };
}
