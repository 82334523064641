import React from "react";
import styled from "styled-components";
import {Table} from "antd";

const DataTable = props => {
    const {columns, dataSource, loading, onRowClick, pagination = null, emptyComponent = null} = props;
    return (
        <DataTableWrapper>
            <DataTableContainer>
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                event.stopPropagation();
                                onRowClick(record, rowIndex, event);
                            },
                        };
                    }}
                    locale={{
                        emptyText: !loading ? emptyComponent : " "
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={pagination}
                    rowKey="uuid" />
            </DataTableContainer>
        </DataTableWrapper>
    );
};

DataTable.defaultProps = {
    onRowClick: () => {}
};

const DataTableWrapper = styled.div`
  td {font-size: 14px !important;}
`;
const DataTableContainer = styled.div``;

export default DataTable;
