import React from "react";
import styled from "styled-components";
import {Badge, Tooltip} from "antd";

const MenuItem = props => {
    const {id, active, title, icon: Icon, iconActive: IconActive, onClick, showTitle, badgeCount = 0} = props;
    const handleOnClick = () => {
        onClick(id);
    };
    return (
        <Tooltip title={title} placement="right" color="#1d0047">
            <MenuItemWrapper onClick={handleOnClick} className={active ? "active" : ""}>
                <ActiveIndicator className={active ? "active" : ""} />
                <IconContainer style={active ? {backgroundColor:'var(--primary-color-light-shade-1)'} : {}}>
                    <Badge count={badgeCount}>
                        {!active ? <Icon style={{color: '#969aa1'}} /> : <IconActive style={{color: 'var(--primary-color)'}} />}
                    </Badge>
                </IconContainer>
                {showTitle && <Title>{title}</Title>}
            </MenuItemWrapper>
        </Tooltip>
    );
};
const ActiveIndicator = styled.div`
  width: 4px;
  background-color: transparent;
  height: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  float: left;
  margin-top: 8px;
  &.active {
    background-color: var(--primary-color);
  }
`; 
const MenuItemWrapper = styled.div`
  font-size: 20px;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  color: #fff;
  //border-left: 4px solid transparent;
  //border-right: 4px solid transparent;
  &.active {
    //border-left: 4px solid var(--primary-color);
  }
  &:hover {
    cursor: pointer;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 14px;
  margin: 0 10px;
  .anticon {
    font-size: 20px;
    padding: 3px;
  }
`;
const Title = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #969aa1;
`;
MenuItem.defaultProps = {
    showTitle: false
};
export default MenuItem;
