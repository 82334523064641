export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_MEMBER = 'SET_AUTH_MEMBER';
export const SET_AUTH_RESIDENT = 'SET_AUTH_RESIDENT';
export const SET_AUTH_ACCOUNT = 'SET_AUTH_ACCOUNT';
export const SET_AUTH_RESIDENTSHIPS = 'SET_AUTH_RESIDENTSHIPS';
export const SET_AUTH_MEMBERSHIPS = 'SET_AUTH_MEMBERSHIPS';
export const SET_CURRENT_MANAGEMENT_SECTION = 'SET_CURRENT_MANAGEMENT_SECTION';
export const SET_CURRENT_MANAGEMENT_SECTION_PAGE = 'SET_CURRENT_MANAGEMENT_SECTION_PAGE';
export const SET_CURRENT_MEMBER_REQUESTS_COUNT = 'SET_CURRENT_MEMBER_REQUESTS_COUNT';
export const SET_AUTH_CLIENT = 'SET_AUTH_CLIENT';
export const SET_AUTH_SUBSCRIPTION_STATUS = 'SET_AUTH_SUBSCRIPTION_STATUS';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_AUTH_IS_NEW_USER = 'SET_AUTH_IS_NEW_USER';
