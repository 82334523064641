import Api from "./Api";

export const getCurrentSubscription = () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/clients/subscription')).data);
        }
        catch(error) {
            reject(error);
        }
    });
};