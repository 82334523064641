import styled from "styled-components";

const FormActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  button {
    margin-left: 10px;
  }
`;

export default FormActionsContainer;
