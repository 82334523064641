// Generic International Phone Number https://phoneregex.com/
// eslint-disable-next-line react-hooks/exhaustive-deps
export const INTERNATIONAL_PHONE_REGEX = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

// Almost perfect email regex https://emailregex.com/
// Note: There is no perfect email regex
//eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type EmailOrPhone = "email" | "phone";

export function detectIfEmailOrPhoneIsValid(s: string) {
    if(s.match(INTERNATIONAL_PHONE_REGEX) || s.match(EMAIL_REGEX)) {
        return true;
    }
    return false;
}

export function detectIfEmailOrPhone(s: string): EmailOrPhone | null {
    if(detectIfEmailOrPhoneIsValid(s)) {
        if(s.match(INTERNATIONAL_PHONE_REGEX)) {
            return "phone";
        }
        else {
            return "email";
        }
    }
    return null;
}

export function isValidPhone(s: string) {
    return s.match(INTERNATIONAL_PHONE_REGEX);
}