import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Row, Tag} from "antd";
import {useParams} from "react-router-dom";
import App from "../utils/App";
import Api from "../utils/Api";
import {Spin} from "antd";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import * as StaffRating from "../utils/StaffRating";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY} from "../constants";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import Profile from "../components/layout/Profile";
import Rating from "../components/layout/Rating";

const StaffReviewDetail = props => {
    const {uuid} = useParams();
    const [entity, setEntity] = useState(null);
    const [newEntity, setNewEntity] = useState(true);
    const [loading, setLoading] = useState(false);
    const fetchEntity = async () => {
        setLoading(true);
        try {
            const entityData = (await StaffRating.getByUuid(uuid));
            setEntity(entityData);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if(!!uuid) {
            setNewEntity(false);
            fetchEntity().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha eliminado correctamente la reseña");
        window.history.back();
    };
    return (
        <EntityDetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={entity !== null ? `Reseña de ${entity?.author?.user?.first_name}` : (loading ? <LoadingOutlined /> : 'Reseña')}
                subTitle={entity !== null ? `${entity?.staff?.first_name} ${entity?.staff?.last_name}` : ``}
            />
            <Container>
                <Spin spinning={loading}>
                    {(!!entity || newEntity) &&
                    <EntityDetailContainer>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Profile
                                    style={{margin: '85px 0 25px 0'}}
                                    name={`${entity?.author?.user?.first_name} ${entity?.author?.user?.last_name}`}
                                    imageSource={entity?.author?.user?.profile_image?.url}
                                    attributes={[
                                        {title:'Residencia', description:<Tag>{entity?.account?.section?.name} {entity?.account?.number}</Tag>},
                                        {title:'Reseña', description:<Rating rating={entity?.rating_value}/>},
                                        {title:'Comentario', description:entity?.review_body}
                                    ]}
                                />
                            </Col>
                        </Row>
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <DeleteEntityButton
                                    modalTitle="¿Eliminar Reseña?"
                                    modalContent={() => <span>Ya no podrás volver a ver sus datos ni modificarla.</span>}
                                    uuid={entity.uuid}
                                    onSubmit={onDeleteSubmit}
                                    entity={ENTITY.TYPE.STAFF_REVIEWS} block danger>
                                    Eliminar Reseña
                                </DeleteEntityButton>
                            </Col>
                        </Row>
                        }
                    </EntityDetailContainer>
                    }
                </Spin>
            </Container>
        </EntityDetailWrapper>
    );
};

const EntityDetailWrapper = styled.div``;
const EntityDetailContainer = styled.div``;

export default StaffReviewDetail;
