import React from "react";
import {Form, Button, Input} from 'antd';
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import {ArrowLeftOutlined} from "@ant-design/icons";

const StaffCategoryForm = (props) => {
    const {
        entity,
        loading,
        onSubmit,
        cancelText = <span><ArrowLeftOutlined /> Regresar</span>,
        showCancelButton = true,
        onCancel = () => { window.history.back(); },
    } = props;
    const onFinish = values => {
        onSubmit(values);
    };
    return (
        <Form layout={"vertical"}
              initialValues = {!!entity ? {
                  name: entity?.name
              } : {}}
              name="control-ref"
              onFinish={onFinish}>
            <Form.Item
                name="name"
                label="Nombre"
                rules={[{required: true}]}
            >
                <Input />
            </Form.Item>
            <FormActionsContainer>
                {showCancelButton &&
                    <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                        {cancelText}
                    </Button>
                }
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

StaffCategoryForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(StaffCategoryForm);
