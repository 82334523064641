export const AUTH = {
    ACCESS_TOKEN: 'accessToken',
    MEMBER_ACCESS_TOKEN: 'memberAccessToken',
    MEMBER: 'member',
    USER: 'user',
    CLIENT: 'client',
    ACCOUNT: 'account',
    RESIDENT: 'resident',
    RESIDENTSHIPS: 'residentships',
};
export const ROUTE = {
    TYPE: {
        GUEST: "guest",
        PRIVATE: "private"
    },
    ROUTES: {
        HOME: "/",
        LOGIN: "/login",
        DASHBOARD: "/dashboard",
        MANAGEMENT: "/management",
        MEMBERS: "/management/members",
        MEMBER: "/management/member",
        RESIDENTS: "/management/residents",
        RESIDENT: "/management/resident",
        MEMBER_INVITE: "/management/member/invite",
        RESIDENT_INVITE: "/management/resident/invite",
        ACCOUNTS: "/management/accounts",
        ACCOUNT: "/management/account",
        GUARDS: "/management/guards",
        GUARD: "/management/guard",
        ENTRANCES: "/management/entrances",
        ENTRANCE: "/management/entrance",
        PARCELS: "/management/parcels",
        PARCEL: "/management/parcel",
        STAFF: "/management/staff",
        VISITS: "/visits",
        VISIT: "/visit",
        USER_ACCOUNT: "/profile",
        ACCOUNT_SELECT: "/account-select",
        NOTICE_BOARD: "/notice-board",
        MEMBER_REQUESTS: "/requests",
        MEMBER_REQUEST: "/request",
        RESIDENT_REQUESTS: "/requests",
        RESIDENT_REQUEST: "/request",
        NOTICE: "/notice",
        SECTIONS: "/management/sections",
        SECTION: "/management/section",
        STAFF_REVIEWS: "/management/staff/reviews",
        STAFF_REVIEW: "/management/staff/review",
        EMERGENCY_CONTACTS: "/management/emergency-contacts",
        EMERGENCY_CONTACT: "/management/emergency-contact",
        EMERGENCY_CONTACT_CATEGORY: "/management/emergency-contact-category",
        EMERGENCY_CONTACT_CATEGORIES: "/management/emergency-contact-categories",
        STAFF_CATEGORY: "/management/staff-category",
        STAFF_CATEGORIES: "/management/staff-categories",
        VISIT_INVITATION: "/visit-invitation",
        VISIT_INVITATIONS: "/visit-invitations",
        ACCOUNT_GUEST: "/account-guest",
        ACCOUNT_GUESTS: "/account-guests",
        ACCOUNT_ACTIVITY: "/account-activity",
        MY_PROFILE: "/profile",
        RECOVER_PASSWORD: "/login/recover-password",
        COMPLETE_SIGNUP: "/complete-signup"
    },
    MANAGEMENT: {
        SECTIONS: {
            MEMBERS: "members",
            RESIDENTS: "residents",
            ACCOUNTS: "accounts",
            GUARDS: "guards",
            ENTRANCES: "entrances",
            PARCELS: "parcels",
            SECTIONS: "sections",
            STAFF: "staff",
            EMERGENCY_CONTACT: "emergency-contact",
            EMERGENCY_CONTACT_CATEGORY: "emergency-contact-category",
            STAFF_CATEGORY: "staff-category",
        }
    },
    KEYWORDS: {
        NEW: "new",
        EDIT: "edit",
        LIST: "list"
    }
};
export const ENTITY = {
    TYPE: {
        MEMBERS: 'members',
        RESIDENTS: 'residents',
        ACCOUNTS: 'accounts',
        VISITS: 'visits',
        GUARDS: 'guards',
        ENTRANCE: 'entrance',
        PARCEL: 'parcel',
        ALERTS: 'alerts',
        SECTIONS: 'sections',
        STAFF: 'staff',
        NOTICE_BOARD: 'notice-board',
        MEMBER_REQUESTS: 'member-requests',
        STAFF_REVIEWS: 'staff-ratings',
        EMERGENCY_CONTACT: 'emergency-contact',
        EMERGENCY_CONTACT_CATEGORY: 'emergency-contact-category',
        STAFF_CATEGORY: 'staff-category',
        ACCOUNT_GUEST: 'account-guest',
        VISIT_INVITATION: 'visit-invitation',
        ACCOUNT_ACTIVITY: 'account-activity',
        RESIDENT_REQUEST: 'resident-request'
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    }
};
export const USER = {
    TYPE: {
        ADMIN: 1,
        CLIENT: 2,
        ACCOUNT_MEMBER: 3
    }
};
export const CLIENT = {
    STATUS: {
        PAID: 1,
        UNPAID: 2
    }
};
export const MEMBER = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        BANNED: 3,
        PENDING: 4,
        REJECTED: 5,
        INVITE_PENDING: 6
    },
    ROLE: {
        OWNER: 2,
        TENANT_WITH_FAMILY: 3,
        TENANT_WITH_OTHERS: 4,
        OTHER_RESIDENT: 5,
        ADMIN: 1,
    }
};
export const RESIDENT = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        BANNED: 3,
        PENDING: 4,
        REJECTED: 5,
        INVITE_PENDING: 6
    },
    ROLE: {
        ADMIN: 1,
        RESIDENT: 2,
    }
};
export const VISIT =  {
    TYPE: {
        GUEST: 1,
        DELIVERY: 2,
        CAB: 3,
        STAFF: 4,
        PROVIDER: 5,
        OTHER: 6
    },
    STATUS: {
        APPROVED: 1,
        DENIED: 2,
        PRE_APPROVED: 3,
        PENDING: 4,
        NOT_ANSWERED: 5,
        NOT_ANSWERED_2: 6,
        NOT_ANSWERED_3: 7,
        CHECKED_IN: 8,
        CHECKED_OUT: 9,
        NOTIFICATION_ONGOING: 10,
        CALL_ONGOING: 11,
        CALL_2_ONGOING: 12,
        CALL_ERROR: 13,
        UNREACHABLE: 14,
    },
    NOTIFICATION_SENT: 1,
    FIRST_CALL_FORWARDED: 1,
    SECOND_CALL_FORWARDED: 1,
    CHECK_IN_TYPE: {
        NEW_VISIT: 1,
        PRE_APPROVED: 2,
    },
    TIMELINE_STATUS: {
        SHOWN: 1,
        HIDDEN: -1,
    }
};
export const STATUS = {
    INACTIVE: -1,
    ACTIVE: 1
};
export const VISIT_DESTINATION = {
    TYPE: {
        GUEST: 1,
        DELIVERY: 2,
        CAB: 3,
        STAFF: 4,
        PROVIDER: 5,
        OTHER: 6
    },
    STATUS: {
        APPROVED: 1,
        DENIED: 2,
        PENDING: 4,
        NOT_ANSWERED: 5,
        NOT_ANSWERED_2: 6,
        NOT_ANSWERED_3: 7,
        NOTIFICATION_ONGOING: 10,
        CALL_ONGOING: 11,
        CALL_2_ONGOING: 12,
        CALL_ERROR: 13,
        UNREACHABLE: 14,
        EXPIRED: 15,
    },
    NOTIFICATION_SENT: 1,
    FIRST_CALL_FORWARDED: 1,
    SECOND_CALL_FORWARDED: 1,
    CHECK_IN_TYPE: {
        NEW_VISIT: 1,
        PRE_APPROVED: 2,
    },
    TIMELINE_STATUS: {
        SHOWN: 1,
        HIDDEN: -1,
    }
};
export const PASSCODE = {
    TYPE: {
        MEMBER: 1,
        PARCEL: 2,
        VISIT: 3,
        GUARD: 4,
        STAFF: 5,
        OTHER_SERVICES: 6
    },
    STATUS: {
        ACTIVE: 1,
        EXPIRED: 2
    }
};
export const ACCOUNT = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        BANNED: 3,
    }
};
export const SECTION = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    }
};
export const REGEX = {
    UUID: '([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'
};
export const GUARD = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        BANNED: 3,
    },
    TYPE: {
        ENTRANCE_GUARD: 1
    }
};
export const ENTRANCE = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    },
    TYPE: {
        MAIN: 1
    }
};
export const PARCEL = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    },
    TYPE: {
        PACKAGE: 1
    }
};
export const NOTICE_BOARD = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    },
    TYPE: {
        ANNOUNCEMENT: 1
    }
};
export const ALERT = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    },
    TYPE: {
        SOS: 1
    }
};
export const STAFF = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        BANNED: 3,
    },
    TYPE: {
        DAILY_HELP: 1
    }
};
export const DATE = {
    FORMAT: {
        DEFAULT: "YYYY-MM-DD HH:mm:ss",
        TABLE_DISPLAY: "DD MM YYYY",
        DATE_TIME_HUMANIZED: "DD [de] MMM YYYY[,] h:mm:ss A",
        DATE_TIME_HUMANIZED_SHORT: "DD [de] MMM YYYY[,] h:mm A",
        DATE_TIME: "YYYY-MM-DD HH:mm:ss"
    }
};
export const MEMBER_REQUEST = {
    STATUS: {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
    }
};
export const RESIDENT_REQUEST = {
    STATUS: {
        PENDING: 1,
        APPROVED: 2,
        REJECTED: 3,
    }
};
export const STAFF_HAS_RATING = {
    STATUS: {
        ACTIVE: 1,
    },
    TYPE: {
        STAFF: 1
    }
};
export const MEDIA = {
    STATUS: {
        ACTIVE: 1
    },
    TYPE: {
        STAFF_PROFILE: 1,
        STAFF_IDENTIFICATION: 2,
        VISIT_IDENTIFICATION: 3,
        USER_PROFILE: 4,
        GUARD_PROFILE: 5,
        GUARD_IDENTIFICATION: 6,
        NOTICE_BOARD_ATTACHMENT: 7,
        MEMBER_REQUEST_ATTACHMENT: 8,
        COMPANY: 9,
        OTHER: 10,
    }
};

export const EMERGENCY_CONTACT = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: -1,
    }
};

export const EMERGENCY_CONTACT_CATEGORY = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: -1,
    }
};

export const STAFF_CATEGORY = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: -1,
    }
};

export const ACCOUNT_ACTIVITY = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: -1,
    },
    TYPE: {
        VISIT: 1,
        PARCEL: 2,
        MESSAGE: 3,
        VISIT_INVITATION: 4,
    }
};

export const ACCOUNT_GUEST = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: -1,
    },
    TYPE: {
        GUEST: 1,
    }
};

export const VISIT_INVITATION = {
    STATUS: {
        ACTIVE: 1,
        INACTIVE: -1,
        EXPIRED: 2,
    },
    TYPE: {
        GUEST: 1,
        DELIVERY: 2,
        CAB: 3,
        STAFF: 4,
        PROVIDER: 5,
        OTHER: 6
    },
};
export const REVIEWS = {
    MIN_COUNT_DISPLAY: 2
};
export const REQUEST = {
    ERROR_CODE: {
        UNDEFINED: 1000,
        CLIENT_SUBSCRIPTION_NOT_ACTIVE: 1001,
        REQUEST: 1002,
        DATABASE: 1003,
        WEBSERVICE: 1004,
        SERVER: 1005,
        MISSING_REQUIRED_FIELDS: 1007,
        EMAIL_SPAM_TIMEOUT: 1008,
        SEND_GRID: 1009,
        TWILIO: 1010,
        SENTRY: 1011,
        EMAIL_ALREADY_EXISTS: 1012,
        USERNAME_ALREADY_EXISTS: 1013,
        ENTITY_NOT_FOUND: 1014,
        WHATSAPP_RATE_LIMIT: 1015,
        SMS_RATE_LIMIT: 1016,
        IVR_RATE_LIMIT: 1017,
        TRIAL_PERIOD_ENDED: 1018,
        ACCOUNT_LIMIT_REACHED: 1019,
        CLOUDINARY: 1020,
        UPLOAD: 1021,
        DOWNLOAD: 1022,
        UPLOAD_SIZE_LIMIT: 1022,
        UPLOAD_FORMAT: 1023,
        REQUEST_LIMIT_REACHED: 1023,
        AUTHENTICATION_ERROR: 1024,
        CREDENTIALS_ERROR: 1025,
        VISIT_ALREADY_AUTHORIZED: 1026,
        VISIT_NOT_ACTIVE: 1027,
        SMS_CODE_NOT_VALID: 1028,
        SMS_CODE_EXPIRED: 1029,
        PASSCODE_NOT_VALID: 1030,
        PASSCODE_UNABLE_TO_BE_GENERATED: 1031,
        MEMBER_ALREADY_EXISTS_WITHIN_ACCOUNT: 1032,
        INVALID_STATUS_PROVIDED: 1033,
        MEMBER_REQUEST_ALREADY_HANDLED: 1034,
        MEMBER_ALREADY_AUTHORIZED: 1035,
        ACCOUNT_NAME_ALREADY_EXISTS: 1036,
        PASSCODE_INVALID_TYPE: 1037,
        PASSCODE_NOT_LINKED_TO_ANY_ENTITY: 1038,
        SECTION_NAME_ALREADY_EXISTS: 1039,
        ROLE_PROVIDED_IS_INVALID: 1040,
        MEMBER_ALREADY_EXISTS_WITHIN_CLIENT: 1041,
        MEMBER_REQUEST_ALREADY_PENDING: 1042,
        QUERY_PARAMETERS_MISSING: 1043,
        VISIT_APPROVAL_ALREADY_HANDLED: 1044,
        EMAIL_FORMAT_NOT_VALID: 1045,
        DATE_FORMAT_NOT_VALID: 1046,
        PHONE_NUMBER_FORMAT_NOT_VALID: 1046,
        NOT_AUTHORIZED: 1047,
        SERVER_RUNTIME_ERROR: 1048,
        SERVER_DB_ERROR: 1049,
        INVALID_TENANT_SLUG: 1050,
        RESIDENT_NOT_ACTIVE: 1051,
        AUTHORIZATION_RESIDENT_NOT_FOUND: 1052,
        AUTHORIZATION_ACCOUNT_NOT_FOUND: 1053,
        RESIDENT_ALREADY_EXISTS_WITHIN_ACCOUNT: 1054,
        RESIDENT_REQUEST_ALREADY_HANDLED: 1055,
    }
};
