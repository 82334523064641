import { ClockCircleOutlined, MailOutlined } from "@ant-design/icons";
import { RESIDENT } from "../constants";
import Api from "./Api";

export const getAll = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/residents', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getByUuid = uuid => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/residents/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const updateByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/residents/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const deleteByUuid = (uuid) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/residents/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const invite = data => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/residents`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const resendInvitationEmail = (uuid) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/residents/${uuid}/re-send/invitation-email`, null, {})).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getStatusColor = (status) => {
    switch (status) {
        case RESIDENT.STATUS.ACTIVE:
            return "success";
        case RESIDENT.STATUS.INVITE_PENDING:
            return "processing";
        case RESIDENT.STATUS.INACTIVE:
            return "error";
        case RESIDENT.STATUS.PENDING:
            return "warning";
        default:
            return "default";
    }
};
export const getStatusIcon = (status) => {
    switch (status) {
        case RESIDENT.STATUS.ACTIVE:
        case RESIDENT.STATUS.INACTIVE:
            return null;
        case RESIDENT.STATUS.PENDING:
            return <ClockCircleOutlined/>;
        case RESIDENT.STATUS.INVITE_PENDING:
            return <MailOutlined />;
        default:
            return null;
    }
};