import React from "react";
import styled from "styled-components";
import {Avatar} from "antd";
import {injectIntl} from "react-intl";
import { Link } from "react-router-dom";
import { CLIENT, ROUTE } from "../../constants";

const ResidentProfile = props => {
    const { residentship, onClick, intl, route = ROUTE.ROUTES.HOME, client } = props;
    const handleOnClick = () => {
        onClick(residentship);
    };
    return (
      <Link to={route}>
        <AccountProfileWrapper onClick={handleOnClick}>
              <AvatarContainer>
                  <Avatar style={{fontSize: "18px"}} size={48}>
                      {client?.name[0]}
                  </Avatar>
              </AvatarContainer>
              <DescriptionContainer>
                  <AccountTitle>{client?.name}</AccountTitle>
                  <Role>{intl.formatMessage({id:`resident.role.${residentship?.role}`})}</Role>
                  {client?.status === CLIENT.STATUS.UNPAID &&
                    <WarningContainer>
                        {/* <WarningTitle>Advertencia</WarningTitle> */}
                        <WarningDescription>
                            Servicio inactivo
                        </WarningDescription>
                    </WarningContainer>
                  }
              </DescriptionContainer>
          </AccountProfileWrapper>
      </Link>  
    );
};

const WarningContainer = styled.div`
  margin-top: 10px;
  `;
// const WarningTitle = styled.div`
//   color: var(--status-warning-color);
//   font-size: 10px;
//   font-weight: 600;
// `;
const WarningDescription = styled.div`
  color: var(--status-warning-color);
  font-size: 10px;
`;
const AccountProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 20px 20px;
  color: #3c3c3c;
  border-radius: 5px;
  border: var(--container-border);
  transition: background-color ease-in 0.2s;
  h1, h2, h3, h4, h5 {
    display: block;
    width: auto;
    margin: 0 auto 0 auto;
  }
  &:hover {
    cursor: pointer;
    background-color: var(--btn-default-hover-background-color);
  }
`;
const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;
const DescriptionContainer = styled.div`
  
`;
const Role = styled.div`
  font-size: 11px;
  color: var(--unselected-text-color);
  font-weight: 500;
`;
const AccountTitle = styled.div`
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
`;
export default injectIntl(ResidentProfile);
