import { SET_CURRENT_MEMBER_REQUESTS_COUNT } from "../actionTypes";
import Member from "../../utils/Member";
import { AppDispatch, RootState } from "../store";

const memberUtil = new Member();

export function setCurrentMemberRequestsCount(count = 0) {
    return {
        type: SET_CURRENT_MEMBER_REQUESTS_COUNT,
        currentMemberRequestsCount: count
    }
}

export function fetchCurrentMemberRequestsCount(): any {
    return async (dispatch: AppDispatch, getState: RootState) => {
        try {
            const memberRequests = await memberUtil.getPendingRequests();
            dispatch(setCurrentMemberRequestsCount(memberRequests?.metadata?.total || 0));
        }
        catch(error) {
            console.log(error);
        }
    }    
}
