import React from "react";
import styled from "styled-components";
import {PageHeader} from "antd";
import Header from "./Header";

const DetailHeader = props => {
    return (
        <Header>
            <DetailHeaderWrapper>
                <PageHeader className="site-page-header" {...props} />
            </DetailHeaderWrapper>
        </Header>
    )
};

const DetailHeaderWrapper = styled.div`
  .ant-page-header {
    margin-bottom: 0;
  }
  .ant-page-header-back-button {
    
    background: var(--contrast-background-color) !important;
    color: var(--unselected-text-color) !important;
    padding: 10px !important;
    border-radius: 100%;
    transition: background-color 300ms;
    &:hover {
      background: var(--primary-color-light-shade-1) !important;
      color: var(--primary-color) !important;
    }
  }
`;

export default DetailHeader;
