import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import Auth from "../utils/Auth";
import ResidentProfile from "../components/member/ResidentProfile";
import {setAuthMember, setAuthMemberships, setAuthClient, setAuthResidentships, setAuthResident} from "../redux/actions/authActions";
import {Button, Spin} from "antd";
import Api from "../utils/Api";
import App from "../utils/App";
import {RESIDENT, ROUTE} from "../constants";
import SplashScreen from "../components/layout/SplashScreen";
import {LogoutOutlined} from "@ant-design/icons";
import Header from "../components/layout/Header";
import Layout from "../components/layout/Layout";

const authUtils = new Auth();

const AccountSelect = props => {
    const {
        setAuthMember,
        autoselect,
        setAuthResident,
        setAuthResidentships,
        setAuthClient,
    } = props;
    const [residentships, setResidentships] = useState([]);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [accountSelectedLoading, setAccountSelectedLoading] = useState(false);

    const autoSelectLogin = async (membership) => {
        try {
            const member = await authUtils.memberLogin(membership.uuid);
            setAuthMember(member);
        }
        catch(error) {
            console.log(error);
            throw Api.parseResponseError(error);
        }
    };

    useEffect(() => {
        fetchUser();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [autoselect, setAuthMember]);

    const fetchUser = async () => {
        try {
            setAccountsLoading(true);
            const currentUser = await authUtils.getCurrentUser();
            const residentships = currentUser.resident.filter(residentship => residentship.role === RESIDENT.ROLE.ADMIN);
            if(autoselect && residentships.length > 0) {
                try {
                    await autoSelectLogin(residentships[0]);
                }
                catch(error) {
                    App.showError(error);
                }
            }
            else {
                setResidentships(residentships);
            }
            setAccountsLoading(false);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
            setAccountsLoading(false);
        }
    };

    const profileOnClick = async resident => {
        try {
            setAccountSelectedLoading(true);
            setAuthClient(resident?.client || null);
            setAuthResident(resident);
            setAuthResidentships(residentships);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
            setAccountSelectedLoading(false);
        }
    };

    const onLogoutClick = async () => {
        try {
            const auth = new Auth();
            await auth.signout();
            setAuthClient(null);
            window.location.reload();
        }
        catch(error) {
            console.log(error);
            App.showError("Ha ocurrido un error al intentar de cerrar sesión");
        }
    };
    return (
        <Layout showNavbar={false}>
            <Header />
            <AccountSelectWrapper>
                {!accountsLoading ?
                    <AccountSelectContainer>
                        <Spin spinning={accountSelectedLoading}>
                            {residentships.length > 0 ?
                                <React.Fragment>
                                    <h1 style={{marginBottom: "40px"}}>Selecciona una residencial</h1>
                                </React.Fragment>
                                : <React.Fragment>
                                    <h1>No tienes ninguna residencial disponible</h1>
                                    <AccountSelectDescription>
                                        Si ya perteneces a una residencial es posible que no cuentes con permisos de administrador. Si crees que esto es un error por favor envía un correo a soporte@hausly.mx o contacta a un asesor.
                                    </AccountSelectDescription>
                                    <Button type="primary" onClick={onLogoutClick}>
                                        <LogoutOutlined/> Cerrar sesión
                                    </Button>
                                </React.Fragment>}
                            <AccountsContainer>
                                {residentships.length > 0 && residentships.map(
                                    residentship =>
                                        <ResidentProfile route={ROUTE.ROUTES.HOME} residentship={residentship} onClick={profileOnClick} key={residentship.uuid} client={residentship?.client} />
                                )}
                            </AccountsContainer>
                        </Spin>
                    </AccountSelectContainer> : <SplashScreen />}
            </AccountSelectWrapper>
        </Layout>
    );
};
const mapStateToProps = state => ({
    ...state
});
const mapDispatchToProps = dispatch => ({
    setAuthMember: (member) => dispatch(setAuthMember(member)),
    setAuthMemberships: (memberships) => dispatch(setAuthMemberships(memberships)),
    setAuthResidentships: (residentships) => dispatch(setAuthResidentships(residentships)),
    setAuthResident: (resident) =>  dispatch(setAuthResident(resident)),
    setAuthClient: (client) => dispatch(setAuthClient(client)),
});
AccountSelect.defaultProps = {
    autoselect: false
};
const AccountSelectWrapper = styled.div`
  height: calc(100vh - 98px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  h1, h2, h3, h4, h5 {
    margin-bottom: 10px;
  }
`;
const AccountSelectContainer = styled.div`
  width: 100%;
`;
const AccountSelectDescription = styled.div`
  font-size: 20px;
  margin-bottom: 50px;
`;
const AccountsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-gap: 15px;
`;
export default connect(mapStateToProps, mapDispatchToProps)(AccountSelect);
