import Api from "./Api";

export const getAll = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/account-activity', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/account-activity/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
