import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Avatar, Col, Row, Spin, Tag} from "antd";
import MemberForm from "../forms/MemberForm";
import {useParams} from "react-router-dom";
import Member from "../utils/Member";
import App from "../utils/App";
import Api from "../utils/Api";
import Card from "../components/layout/Card";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY, MEMBER} from "../constants";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import Column from "../components/layout/Column";
import Description from "../components/layout/Description";
import {getDateDifference, getNowDate, getDate} from "../utils/Date";
import TimerButton from "../components/layout/TimerButton";

const memberUtil = new Member();
const MIN_CD_FOR_INVITATION_EMAIL = 60;

const MemberDetail = props => {
    const {auth, intl} = props;
    const {uuid} = useParams();
    const [member, setMember] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const fetchMember = async () => {
        setLoading(true);
        try {
            const memberData = await memberUtil.getMemberByUuid(uuid);
            setMember(memberData);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchMember().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha eliminado correctamente al residente");
        window.history.back();
    };
    const onEditSubmit = async data => {
        setSubmitLoading(true);
        try {
            await memberUtil.updateMemberByUuid(uuid, data);
            App.showSuccess('Usuario actualizado correctamente');
            setSubmitLoading(false);
            window.history.back();
        }
        catch(error) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };
    const [buttonInvitationLoading, setButtonInvitationLoading] = useState(false);
    const resendInvitationEmailButtonOnClick = async () => {
        setButtonInvitationLoading(true);
        try {
            if(!!member?.last_sent_email_invitation) {
                await memberUtil.resendInvitationEmail(member?.uuid);
                App.showSuccess('Se ha reenviado correctamente la invitación.');
            }
            else {
                const prevDateSent = getDate(member?.last_sent_email_invitation);
                const now = getNowDate();
                const timeDifferenceInMinutes = getDateDifference(prevDateSent, now, "minutes");
                if(timeDifferenceInMinutes <= MIN_CD_FOR_INVITATION_EMAIL) {
                    await memberUtil.resendInvitationEmail(member?.uuid);
                    App.showSuccess('Se ha reenviado correctamente la invitación.');
                }
                else {
                    App.showError(`Es necesario esperar al menos ${timeDifferenceInMinutes} minutos antes de reenviar la invitación.`)
                }
            }
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setButtonInvitationLoading(false);
        }
    };
    return (
        <MemberDetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={member !== null ? `${member.user?.first_name} ${member.user?.last_name}` : (loading ? <LoadingOutlined /> : 'Nuevo Residente')}
                subTitle=""
            />
            <Container>
                <Spin spinning={loading}>
                    {!!member && (member.status !== MEMBER.STATUS.INVITE_PENDING && member.status !== MEMBER.STATUS.PENDING) &&
                    <React.Fragment>
                        <MemberDetailContainer>
                            <Row>
                                <Col xs={24}  sm={24} md={24} lg={24}>
                                    <Card style={{marginTop: !!member ? "85px" : "0"}}>
                                        <ProfilePictureContainer>
                                            <AvatarContainer>
                                                <Avatar style={{fontSize: "50px"}} size={120} src={member?.user?.profile_image_url}>
                                                    {!!member?.user?.first_name && member?.user?.first_name.length > 0 && member?.user?.first_name[0]}
                                                </Avatar>
                                            </AvatarContainer>
                                        </ProfilePictureContainer>
                                        <Row>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <MemberDetailsTitle>Nombre</MemberDetailsTitle>
                                                <MemberDetailsDescription>{member.user?.first_name} {member.user?.last_name} {member.user?.mother_surname}</MemberDetailsDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <MemberDetailsTitle>Teléfono</MemberDetailsTitle>
                                                <MemberDetailsDescription>{member.user?.username}</MemberDetailsDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <MemberDetailsTitle>Correo electrónico</MemberDetailsTitle>
                                                <MemberDetailsDescription>{member.user?.email}</MemberDetailsDescription>
                                            </Col>
                                            {member.uuid === auth?.member?.uuid &&
                                                <Col xs={24}  sm={24} md={12} lg={8}>
                                                    <MemberDetailsTitle>Residencia</MemberDetailsTitle>
                                                    <MemberDetailsDescription>
                                                        <Tag>{member.account?.section?.name} {member.account?.number}</Tag>
                                                    </MemberDetailsDescription>
                                                </Col>
                                            }
                                            {member.uuid === auth?.member?.uuid &&
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <MemberDetailsTitle>Rol</MemberDetailsTitle>
                                                <MemberDetailsDescription><Tag>{intl.formatMessage({id:`member.role.${member.role}`})}</Tag></MemberDetailsDescription>
                                            </Col>
                                            }
                                            {member.uuid === auth?.member?.uuid &&
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <MemberDetailsTitle>Estatus</MemberDetailsTitle>
                                                <MemberDetailsDescription>
                                                    <Tag icon={memberUtil.getStatusIcon(member.status)} color={memberUtil.getStatusColor(member.status)}>{intl.formatMessage({id:`member.status.${member.status}`})}</Tag>
                                                </MemberDetailsDescription>
                                            </Col>
                                            }
                                            <Column title="Estatus" text={<Tag color={memberUtil.getStatusColor(member?.status)}>{intl.formatMessage({id:`member.status.${member?.status}`})}</Tag>} className="info-column" />
                                        </Row>
                                    </Card>
                                </Col>
                                {member.uuid !== auth?.member?.uuid &&
                                    <Col xs={24} sm={24} md={24} lg={24} style={{marginTop: "20px"}}>
                                        <Card>
                                            <MemberForm loading={submitLoading} entity={member} onSubmit={onEditSubmit} />
                                        </Card>
                                    </Col>
                                }
                            </Row>
                            {!!member && member.uuid !== auth?.member?.uuid &&
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <DeleteEntityButton
                                        modalTitle={`¿Deseas eliminar a ${member?.user?.first_name} ${member?.user?.last_name}?`}
                                        modalContent={() => <span>{member?.user?.first_name} perderá el acceso a Hausly y sus datos.</span>}
                                        uuid={member.uuid}
                                        onSubmit={onDeleteSubmit}
                                        entity={ENTITY.TYPE.MEMBERS} block danger>
                                        Eliminar Residente
                                    </DeleteEntityButton>
                                </Col>
                            </Row>
                            }
                        </MemberDetailContainer>
                    </React.Fragment>
                    }
                    {!!member && member.uuid !== auth?.member?.uuid && (member.status === MEMBER.STATUS.INVITE_PENDING || member.status === MEMBER.STATUS.PENDING) &&
                    <MemberDetailContainer>
                        <Card>
                            <Row>
                                <Column title="Nombre" text={`${!!member?.user?.first_name && member?.user?.first_name} ${member?.user.last_name && member?.user?.last_name}`} className="info-column" />
                                <Column title="Correo electrónico" text={member?.user?.email} className="info-column" />
                                <Column title="Teléfono" text={`${member?.user?.username}`} className="info-column" />
                                <Column title="Estatus" text={<Tag color={memberUtil.getStatusColor(member?.status)}>{intl.formatMessage({id:`member.status.${member?.status}`})}</Tag>} className="info-column" />
                            </Row>
                        </Card>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card style={{marginTop: '20px'}}>
                                    <h3>Reenviar Invitación</h3>
                                    <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                                        El registro del usuario sigue pendiente, puedes mandarle un recordatorio reenviandole la invitación nuevamente.
                                    </Description>
                                    <TimerButton
                                        buttonProps = {{
                                            type:"default",
                                            className:"ant-btn-form",
                                        }}
                                        loading={buttonInvitationLoading}
                                        timerDate={member?.last_sent_invitation_email}
                                        onClick={resendInvitationEmailButtonOnClick}>Reenviar Invitación</TimerButton>
                                </Card>
                            </Col>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <DeleteEntityButton
                                        modalTitle={`¿Deseas eliminar a ${member?.user?.first_name} ${member?.user?.last_name}?`}
                                        modalContent={() => <span>{member?.user?.first_name} perderá el acceso a Hausly y sus datos.</span>}
                                        uuid={member.uuid}
                                        onSubmit={onDeleteSubmit}
                                        entity={ENTITY.TYPE.MEMBERS} block danger>
                                        Eliminar Residente
                                    </DeleteEntityButton>
                                </Col>
                            </Row>
                        </Row>
                    </MemberDetailContainer>
                    }
                </Spin>
            </Container>
        </MemberDetailWrapper>
    );
};

const MemberDetailWrapper = styled.div``;
const MemberDetailContainer = styled.div`
  .info-column {
    margin-bottom: 15px;
  }
`;
const MemberDetailsTitle = styled.div`
  font-weight: 600;
  color: var(--unselected-text-color);
`;
const MemberDetailsDescription = styled.div`
  font-weight: 500;
  margin-bottom: 15px;
`;
const ProfilePictureContainer = styled.div`
  margin: -100px 0 35px 0;
  display: flex;
  justify-content: flex-start;
`;
const  AvatarContainer =styled.div`
  display: inline-block;

  border: solid 10px white;
  border-radius: 50%;
`;

const mapStateToProps = state => ({
    ...state
});
export default connect(mapStateToProps)(injectIntl(MemberDetail));
