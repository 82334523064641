import React, {useEffect, useState} from "react";
import {Form, Button, Input, Select} from 'antd';
import App from "../utils/App";
import {ACCOUNT} from "../constants";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import * as SectionUtils from "../utils/Sections";
import Api from "../utils/Api";
import {ArrowLeftOutlined} from "@ant-design/icons";

const AccountForm = (props) => {
    const [form] = Form.useForm();
    const {entity, loading, onSubmit} = props;
    const [sections, setSections] = useState([]);
    const [sectionsLoading, setSectionsLoading] = useState(true);
    const onFinish = values => {
        onSubmit({
            ...values,
            status: !!values.status ? values.status : ACCOUNT.STATUS.ACTIVE
        });
    };
    const onCancel = () => {
        window.history.back();
    };
    useEffect( () => {
        fetchSections();
        if(!!entity) {
            form.setFieldsValue({
                phone: entity.phone,
                number: entity.number,
                country_code: '52',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchSections = async (params = {page: 0, pageSize: 100}) => {
        try {
            const sectionsData = (await SectionUtils.getSections(params)).data;
            setSections(sectionsData);
            if(!!entity) {
                form.setFieldsValue({ section_uuid: entity?.section?.uuid });
            }
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setSectionsLoading(false);
        }
    };
    return (
        <Form
            form={form}
            layout={"vertical"}
            name="control-ref"
            onFinish={onFinish}>
            <Form.Item
                name="section_uuid"
                label="Zona / Edificio"
                rules={[{required: true}]}
            >
                <Select
                    placeholder="Selecciona una de las siguientes opciones"
                    loading={sectionsLoading}
                    disabled={sectionsLoading}
                >
                    {!sectionsLoading && sections.map(section => <Select.Option key={section.uuid} value={section.uuid}>{section.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name="number"
                label="Nombre o número"
                rules={[{required: true}]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phone"
                label="Teléfono (celular o fijo)"
            >
                <Input
                    prefix="+52"
                />
            </Form.Item>
            <FormActionsContainer>
                <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                    <ArrowLeftOutlined />
                    Regresar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

AccountForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(AccountForm);
