import React from "react";
import styled from "styled-components";
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import {Avatar, Tag} from "antd";
import App from "../utils/App";
import {getTimeZoneDate} from "../utils/Date";

const EmptyColumn = () => <div></div>;

const VisitInvitations = props => {
    const {intl} = props;
    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            render: (name) => <b>{name}</b>
        },
        {
            title: 'Destino',
            responsive: ['md'],
            render: (record, index) => {
                return !!record.account ? <Tag>{record.account?.section?.name} {record.account?.number}</Tag> : <EmptyColumn/>
            },
        },
        {
            title: 'Empresa',
            responsive: ['md'],
            ellipsis: true,
            render: (record, index) => {
                if(!!record.company) {
                    return <React.Fragment>
                        <Avatar style={{fontSize: "15px", backgroundColor: !!record.company?.image?.url ? 'var(--default-color)' : 'var(--unselected-text-color)'}} size={30} src={record.company?.image?.url}>
                            {!!record.company?.name && record.company?.name.length > 0 && record.company?.name[0]}
                        </Avatar>
                        <b style={{marginLeft: '8px'}}>{record.company?.name}</b>
                        </React.Fragment>;
                }
                else if(!!record.company_name) {
                    return record.company_name
                }
                return <EmptyColumn />
            }
        },
        {
            title: 'Fecha',
            dataIndex: 'created_at',
            responsive: ['md'],
            render: date => (
                <span className={'table-datetime'}>
                    {!!date ? getTimeZoneDate({date:date}) : <EmptyColumn />}
                </span>
            )
        },
        {
            title: 'Vencimiento',
            dataIndex: 'expires',
            responsive: ['md'],
            render: date => (
                <span className={'table-datetime'}>
                    {!!date ? getTimeZoneDate({date:date}) : <EmptyColumn />}
                </span>
            )
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            responsive: ['md'],
            render: status => (
                <Tag color={App.getTagColorByStatus(status, ENTITY.TYPE.VISIT_INVITATION)}>{intl.formatMessage({id:`visit-invitation.status.${status}`})}</Tag>
            ),
        },
    ];
    return (
        <Wrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title="Pases de Entrada"
            />
            <Container>
                <EntityWrapper>
                    <EntityContainer>
                        <EntityDataTable
                            entity={ROUTE.ROUTES.VISIT_INVITATION}
                            emptyTitle={'¡Aún no no hay pases para invitados!'}
                            emptyMessage={'Una vez que los residentes generen pases aquí podrás ver toda su información.'}
                            columns={columns}
                            entityName={ENTITY.TYPE.VISIT_INVITATION} />
                    </EntityContainer>
                </EntityWrapper>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div``;
const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;

export default injectIntl(VisitInvitations);
