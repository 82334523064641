import React from "react";
import styled from "styled-components";
import {Button} from "antd";
import Auth from "../utils/Auth";
import App from "../utils/App";
import {ROUTE} from "../constants";
import {LogoutOutlined} from "@ant-design/icons";
import LinkButton from "../components/layout/LinkButton";
import {setAuthMember} from "../redux/actions/authActions";
import {connect} from "react-redux";

const PayWall = (props) => {
    const {auth, clientName = null} = props;
    const onLogoutClick = async () => {
        try {
            const auth = new Auth();
            await auth.signout();
            window.location.reload();
        }
        catch(error) {
            console.log(error);
            App.showError("Ha ocurrido un error al intentar de cerrar sesión");
        }
    };
    const getClientName = () => {
        if(!!clientName) {
            return `${clientName}`;
        }
        else if(!!auth.client) {
            return `${auth.client?.name}`;
        }
        return "esta residencial";
    };
    return (
        <Wrapper>
            <SectionContainer>
                <h1>El acceso a {getClientName()} no se encuentra disponible.</h1>
                <Description>
                    Esto podría deberse una falta de pago o por algún otro motivo. Si crees que esto es un error, por favor, envía un correo a soporte@hausly.mx o contáctate con un asesor.
                </Description>
                <ActionContainer>
                    <Button type="default" className="ant-btn-form" onClick={onLogoutClick}>
                        <LogoutOutlined/> Cerrar sesión
                    </Button>
                    <LinkButton type="primary" to={`${ROUTE.ROUTES.ACCOUNT_SELECT}`} style={{marginLeft: '15px'}}>
                        <span>Cambia de Residencial</span>
                    </LinkButton>
                </ActionContainer>
            </SectionContainer>
        </Wrapper>
    );
};

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const Wrapper = styled.div`
  height: calc(100vh - 98px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  h1, h2, h3, h4, h5 {
    margin-bottom: 10px;
  }
`;
const SectionContainer = styled.div`
`;
const Description = styled.div`
  font-size: 20px;
  margin-bottom: 50px;
`;

const mapStateToProps = state => ({
    ...state
});
const mapDispatchToProps = dispatch => ({
    setAuthMember: (member) => dispatch(setAuthMember(member)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayWall);
