import React from "react";
import styled from 'styled-components';

const CardSubtitle = React.memo((props) => <CardSubtitleWrapper {...props}>{props.children}</CardSubtitleWrapper>);

const CardSubtitleWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: var(--unselected-text-color);
`;

export default CardSubtitle;
