import React from "react";
import { Form, Input, Button } from "antd";
import styled from "styled-components";
import PhoneInput, { CountryCodeSelectPrefix } from "../../components/PhoneInput";

export type CompleteSignUpSubmitData = {
    first_name?: string,
    last_name?: string,
    email?: string,
    phone?: string,
    country_code?: string
}

export type CompleteSignUpFormProps = {
    onSubmit?: (data: CompleteSignUpSubmitData) => void,
    initialValues?: CompleteSignUpSubmitData,
    loading?: boolean
};

const CompleteSignUpForm: React.FC<CompleteSignUpFormProps> = (props) => {
    return(
        <Form
            initialValues={props.initialValues}
            layout="vertical"
            requiredMark="optional"
            onFinish={props.onSubmit}
        >
            <Form.Item
                name="first_name"
                rules={[{ required: true }]}
                label="Nombre"
            >
                <Input type="text" />
            </Form.Item>
            <Form.Item
                name="last_name"
                rules={[{ required: true }]}
                label="Apellido(s)"
            >
                <Input type="text" />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[{ type: 'email', required: true }]}
                label="Correo electrónico"
            >
                <Input type="email" disabled={!!props.initialValues?.email} />
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[{ required: true }]}
                label="Teléfono celular"
            >
                <PhoneInput 
                    disabled={!!props.initialValues?.phone}
                    addonBefore={
                        <Form.Item
                            name="country_code"
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <CountryCodeSelectPrefix disabled={!!props.initialValues?.phone} />
                        </Form.Item>
                    } />
            </Form.Item>
            <SubmitButton loading={props.loading} type="primary" className="pull-right" htmlType="submit">Finalizar</SubmitButton>
        </Form>
    );
};

export default CompleteSignUpForm;

const SubmitButton = styled(Button)`
    margin-top: 15px;
`;