import {
    createStore,
    applyMiddleware,
    Store
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

let middleware: any = [thunkMiddleware];

let store: Store | null = null; // to fix store, null type
const initialState = {};

if(process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require('redux-devtools-extension');
    store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
    );
}
else {
    store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middleware)
    );
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store as Store;