import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DetailHeader from "../components/layout/DetailHeader";
import App from "../utils/App";
import Api from "../utils/Api";
import * as VisitUtil from "../utils/Visit";
import {Spin, Tag, Row, Col, Avatar} from "antd";
import {useParams} from "react-router-dom";
import Container from "../components/layout/Container";
import {VISIT} from "../constants";
import {injectIntl} from "react-intl";
import moment from "moment";
import Card from "../components/layout/Card";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {getTimeZoneDate} from "../utils/Date";

const Text = styled.span`
  display: block;
  font-weight: 500;
  &.rowTitle {
    color: var(--unselected-text-color);
    font-weight: 600;
  }
`;
const View = styled.div``;
const BoxTitle = styled.h3``;

const Column = React.memo((props) => {
    const {title, text = null} = props;
    return (
        <Col xs={24}  sm={24} md={12} lg={8}>
            <View className="cardRow" {...props}>
                <Text className="rowTitle">{title}</Text>
                <Text className="rowText">{text ? text : '-'}</Text>
            </View>
        </Col>
    );
});

const VisitsDetail = props => {
    const {intl} = props;
    const {uuid} = useParams();
    const [entity, setEntity] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // fix 1 to args warning
        const fetchEntity = async () => {
            setLoading(true);
            try {
                const entityData = (await VisitUtil.getVisit(uuid));
                setEntity(entityData);
            }
            catch(error) {
                App.showError(Api.parseResponseError(error));
            }
            finally {
                setLoading(false);
            }
        };
        fetchEntity();
        // fix 2
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);
    const approvedByText = (visitData) => {
        switch (visitData?.status) {
            case VISIT.STATUS.APPROVED:
                return 'Autorizado por';
            case VISIT.STATUS.DENIED:
                return 'Negado por';
            case VISIT.STATUS.PRE_APPROVED:
                if (visitData?.type === VISIT.TYPE.GUEST) {
                    return 'Invitado por';
                }
                return 'Pre-autorizado por';
            default:
                return 'Autorizado por';
        }
    };
    const formattedDate = (date, datetime = false) => {
        if (datetime) {
            return getTimeZoneDate({date:date, calendar:true, calendarIsDateTime:true})
        }
        return getTimeZoneDate({date:date, calendar:true, calendarIsDateTime:false})
    };
    const renderDestination = () => {
        if(!!entity.destination && Array.isArray(entity.destination) && entity.destination?.length > 0) {
            return entity.destination.map(destination => <Tag>{destination?.account?.section?.name} {destination?.account?.number}</Tag>)
        }
        else if(!!entity.account) {
            return <Tag>{entity?.account?.section?.name} {entity?.account?.number}</Tag>;
        }
    };
    const isExpired = (date) => {
        return (moment(date).isBefore(moment(), 'minute'));
    };
    const statusText = (status, type) => {
        if (status === VISIT.STATUS.PRE_APPROVED && type === VISIT.TYPE.GUEST) {
            return intl.formatMessage({id: `visit.status.invited`});
        }
        return intl.formatMessage({id: `visit.status.${status}`});
    };
    const getName = (visit) => {
        return !!visit?.name ? visit.name :  intl.formatMessage({id: `visit.type.${visit?.type}`})
    };
    const getGuardAvatar = (guard) => {
        return (
            <React.Fragment>
                <Avatar style={{fontSize: "15px"}} size={30} src={guard?.profile_image?.url}>
                    {!!guard?.user?.first_name && guard?.first_name.length > 0 && guard?.first_name[0]}
                </Avatar>
                <b style={{marginLeft: '10px'}}>{guard?.first_name} {guard?.last_name}</b>
            </React.Fragment>
        );
    };
    const getVisitAvatar = (visit) => {
        return (
            <Avatar style={{fontSize: "40px", marginBottom: "15px"}} size={120} src={visit?.identification_image?.url}>
                {!!visit?.name && visit?.name.length > 0 && visit?.name[0]}
            </Avatar>
        );
    };
    return (
        <VisitsDetailWrapper>
            <VisitsDetailContainer>
                <DetailHeader
                    onBack={() => window.history.back()}
                    title={entity !== null ? getName(entity) : (loading ? <LoadingOutlined /> : '')}
                    subTitle={entity !== null ? `` : ``}
                />
                <Container>
                    <Spin spinning={loading}>
                        {!loading && !!entity &&
                            <VisitsDetailContainer>
                                <Card className={"infoCard"}>
                                    {!!entity?.identification_image &&
                                        <Row>
                                            <Column title="" text={getVisitAvatar(entity)} />
                                        </Row>
                                    }
                                    <Row>
                                        <Column title="Nombre" text={getName(entity)} />
                                        <Column title="Fecha" text={formattedDate(entity?.created_at, true)} />
                                        {(entity?.company_name || entity?.company) && <Column title="Empresa" text={
                                            !!entity.company ?
                                            <React.Fragment>
                                                <Avatar style={{fontSize: "15px", backgroundColor: !!entity.company?.image?.url ? 'var(--default-color)' : 'var(--unselected-text-color)'}} size={30} src={entity.company?.image?.url}>
                                                    {!!entity.company?.name && entity.company?.name.length > 0 && entity.company?.name[0]}
                                                </Avatar>
                                                <b style={{marginLeft: '8px'}}>{entity.company?.name}</b>
                                            </React.Fragment> : entity.company_name
                                        } />}
                                        {entity?.plate_number && <Column title="Placas" text={entity?.plate_number} />}
                                        <Column title="Tipo" text={intl.formatMessage({id: `visit.type.${entity?.type}`})} />
                                        <Col xs={24}  sm={24} md={12} lg={8}>
                                            <Text className="rowTitle">Estatus</Text>
                                        {!!entity?.expires && isExpired(entity.expires) ?
                                            <Tag icon={VisitUtil.getStatusIcon(entity?.status)}
                                                 color={VisitUtil.getStatusColor(entity?.status)}>
                                                {!isExpired(entity?.expires) ? intl.formatMessage({id: `visit.status.${entity?.status}`}): 'Expirado'}
                                            </Tag> :
                                            <Tag icon={VisitUtil.getStatusIcon(entity?.status)}
                                                 color={VisitUtil.getStatusColor(entity?.status)}>
                                                {!isExpired(entity?.expires) ? statusText(entity?.status, entity?.type) : 'Expirado'}
                                            </Tag>
                                        }
                                        </Col>
                                        <Col xs={24}  sm={24} md={12} lg={8}>
                                            <Text className="rowTitle">Destino</Text>
                                            {renderDestination()}
                                        </Col>
                                    </Row>
                                </Card>
                                {(  !!entity?.resolved_by_phone_call ||
                                    !!entity?.resolved_by_external_method ||
                                    !!entity.approved_by ||
                                    (entity.status === VISIT.STATUS.PRE_APPROVED && !!entity?.expires)) &&
                                <Card className={"infoCard"}>
                                    <Row>
                                    {
                                        (   entity.status === VISIT.STATUS.APPROVED ||
                                            entity.status === VISIT.STATUS.CHECK_IN ||
                                            entity.status === VISIT.STATUS.CHECK_OUT ||
                                            entity.status === VISIT.STATUS.DENIED ) &&
                                        <Column title={approvedByText(entity)}
                                             icon="smartphone"
                                             text={ entity?.resolved_by_phone_call ? 'Intercom' : (entity?.resolved_by_external_method ? 'Método externo a Hausly' :
                                                 entity?.member?.user && `${entity?.member?.user?.first_name} ${entity?.member?.user?.last_name}`)} />
                                    }
                                    {entity.status === VISIT.STATUS.PRE_APPROVED &&
                                    <Column title="Fecha de llegada"
                                         icon="clock"
                                         text={!!entity?.expires ? !isExpired(entity?.expires) ? formattedDate(entity?.expires) : 'Expirado' : formattedDate(entity?.expires)} />
                                    }
                                    </Row>
                                </Card>
                                }
                                {(!!entity?.check_in_guard || !!entity?.check_in) &&
                                <Card className={"infoCard"}>
                                    <BoxTitle>Entrada</BoxTitle>
                                        <Row>
                                        <React.Fragment>
                                            <Column title="Fecha"
                                                 icon="clock"
                                                 text={!!entity?.check_in && getTimeZoneDate({date:entity?.check_in})} />
                                            <Column title="Guardia"
                                                 icon="log-in"
                                                 text={getGuardAvatar(entity?.check_in_guard)} />
                                        </React.Fragment>
                                    </Row>
                                </Card>
                                }
                                {(!!entity?.check_out_guard || !!entity?.check_out) &&
                                <Card className={"infoCard"}>
                                    <BoxTitle>Salida</BoxTitle>
                                    <Row>
                                        <React.Fragment>
                                            <Column title="Fecha"
                                                 icon="clock"
                                                 text={!!entity?.check_out && getTimeZoneDate({date:entity?.check_out})} />
                                            <Column title="Guardia"
                                                 icon="log-out"
                                                 text={getGuardAvatar(entity?.check_out_guard)} />
                                        </React.Fragment>
                                    </Row>
                                </Card>
                                }
                            </VisitsDetailContainer>
                        }
                    </Spin>
                </Container>
            </VisitsDetailContainer>
        </VisitsDetailWrapper>
    )
};

const VisitsDetailWrapper = styled.div`
  
`;
const VisitsDetailContainer = styled.div`
  .infoCard {
    margin-bottom: 20px;
  }
  .cardRow {
    margin-bottom: 10px;
  }
`;

export default injectIntl(VisitsDetail);
