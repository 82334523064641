import React, {useState} from "react";
import styled from "styled-components";
import {Button, Modal} from "antd";
import * as entityUtil from "../../utils/Entity";
import App from "../../utils/App";
import Api from "../../utils/Api";
import {DeleteOutlined, WarningFilled} from "@ant-design/icons";

const DeleteEntityButton = ({modalContent = () => {}, modalTitle="", onSubmit = () => {}, entity, containerStyle = {}, style={}, uuid, children}) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const onOk = async () => {
        setSubmitLoading(true);
        try {
            await entityUtil.deleteEntity(entity, uuid);
            setSubmitLoading(false);
            onSubmit();
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
    };
    const onDeleteButtonClick = () => {
        Modal.confirm({
            title: modalTitle,
            icon: <WarningFilled />,
            cancelButtonProps: {className:"ant-btn-form"},
            content: modalContent(),
            okText: "Eliminar",
            okType: 'danger',
            cancelText: 'Cancelar',
            confirmLoading: submitLoading,
            onOk: onOk,
        });
    };
    return (
        <DeleteEntityButtonWrapper style={containerStyle}>
            <Button style={style} onClick={onDeleteButtonClick} danger><DeleteOutlined/> {children}</Button>
        </DeleteEntityButtonWrapper>

    );
};

const DeleteEntityButtonWrapper = styled.div`
`;

export default DeleteEntityButton;
