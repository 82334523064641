import React from "react";
import {Form, Button, Input} from 'antd';
import {ENTRANCE} from "../constants";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import {ArrowLeftOutlined} from "@ant-design/icons";

class EntranceForm extends React.Component {
    formRef = React.createRef();
    componentDidMount() {
        const {entity} = this.props;
        if(!!entity) {
            if(!!this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    name: entity.name,
                    status: !!entity.status ? entity.status : ENTRANCE.STATUS.ACTIVE,
                    type: !!entity.type ? entity.type : ENTRANCE.TYPE.MAIN
                });
            }
        }
    }
    onFinish = values => {
        this.props.onSubmit({
            name: values.name,
            status: !!values.status ? values.status : ENTRANCE.STATUS.ACTIVE,
            type: !!values.type ? values.type : ENTRANCE.TYPE.MAIN
        });
    };
    onCancel = () => {
        window.history.back();
    };
    render() {
        return (
            <Form ref={this.formRef} layout={"vertical"} name="control-ref" onFinish={this.onFinish}>
                <Form.Item
                    name="name"
                    label="Nombre"
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>
                <FormActionsContainer>
                    <Button htmlType="button" className="ant-btn-form" onClick={this.onCancel}>
                        <ArrowLeftOutlined />
                        Regresar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={this.props.loading}>
                        Guardar
                    </Button>
                </FormActionsContainer>
            </Form>
        );
    }
}

EntranceForm.defaultProps = {
    loading: false,
    entity: null
};

export default injectIntl(EntranceForm);
