import React from "react";
import styled from "styled-components";

const ImportantNumber = React.memo(({children}) => {
    return (<ImportantNumberWrapper>{children}</ImportantNumberWrapper>)
});

const ImportantNumberWrapper = styled.div`
  background-color: var(--default-color);
  color: var(--text-color);
  font-size: 16px;
  padding: 10px 10px;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 5px;
  border-radius: var(--card-border-radius);
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
`;

export default ImportantNumber;
