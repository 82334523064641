import Api from "./Api";

export const getAccounts = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/accounts', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getByUuid = uuid => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/accounts/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const updateByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/accounts/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const create = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/accounts`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/accounts/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getAllActiveMembersByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/accounts/${uuid}/members`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
