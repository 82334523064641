import Api from "./Api";

export default class Entrance {
    getEntrances = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/entrances', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getByUuid = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/entrances/${uuid}`)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    updateByUuid = (uuid, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/entrances/${uuid}`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    create = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.post(`/entrances`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    deleteByUuid = (uuid, params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.delete(`/entrances/${uuid}`, params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
}
