import React from 'react';
import { Route, Redirect, RouteProps} from 'react-router-dom'
import AuthUtils from "../../utils/Auth";
import {ROUTE} from "../../constants";
import {connect} from "react-redux";
import { RootState } from '../../redux/store';

const authUtils = new AuthUtils();

type PrivateRouteProps = {
    role: string[], 
    type?: "guest" | "private", 
    auth?: any
}

const PrivateRoute = (props: RouteProps & PrivateRouteProps) => {
    const {location, role, type, auth} = props;
    if(
        type === ROUTE.TYPE.PRIVATE &&
        auth.isAuthenticated &&
        !!auth.member &&
        !authUtils.userIsAllowed(auth.user, role)) {
        return (
            <Redirect to={{ pathname:ROUTE.ROUTES.HOME, state:{from:location} }} />
        )
    }
    return <Route {...props} />
};
PrivateRoute.defaultProps = {
    role: []
};
const mapStateToProps = (state: RootState) => ({
    ...state
});
export default connect(mapStateToProps)(PrivateRoute);