import React from "react";
import {Form, Button, Input} from 'antd';
import {SECTION} from "../constants";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import {ArrowLeftOutlined} from "@ant-design/icons";

const SectionForm = (props) => {
    const {entity, loading, onSubmit} = props;
    const onFinish = values => {
        onSubmit({
            ...values,
            status: !!values.status ? values.status : SECTION.STATUS.ACTIVE
        });
    };
    const onCancel = () => {
        window.history.back();
    };
    return (
        <Form
            initialValues={
                !!entity ? {
                    name: entity.name,
                } : {}
            }
            layout={"vertical"}
            name="control-ref"
            onFinish={onFinish}>
            <Form.Item
                name="name"
                label="Nombre"
                rules={[{required: true}]}
            >
                <Input
                    placeholder="Escribe el nombre de la zona o edificio"
                />
            </Form.Item>
            <FormActionsContainer>
                <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                    <ArrowLeftOutlined />
                    Regresar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

SectionForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(SectionForm);
