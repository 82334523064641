import * as types from "../actionTypes";

export function setCurrentSection(currentManagementSection) {
    return {
        type: types.SET_CURRENT_MANAGEMENT_SECTION,
        currentManagementSection
    }
}
export function setCurrentManagementSectionPage(currentManagementSectionPage) {
    return {
        type: types.SET_CURRENT_MANAGEMENT_SECTION_PAGE,
        currentManagementSectionPage
    }
}