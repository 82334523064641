import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import styled from "styled-components";
import DataTable from "../layout/DataTable";
import App from "../../utils/App";
import Api from "../../utils/Api";
import * as entityUtil from "../../utils/Entity";
import emptyRequestsAnimation from "../../assets/lottie/empty/629-empty-box";
import Lottie from "react-lottie";
import { useHistory, useLocation } from "react-router-dom";
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import useDebounce from "../../hooks/useDebounce";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_TOTAL = 0;
const DEFAULT_SEARCH_DELAY = process.env.REACT_APP_DEBOUNCED_SEARCH_DELAY;

const EntityDataTable = ({
        entityName,
        columns,
        emptyMessage,
        emptyTitle,
        entity = null,
        showSearch = false,
        searchPlaceholder = "Buscar...",
        entityFetchOpts = {},
        ...props
    }, ref) => {

    let history = useHistory();
    const search = useLocation().search;

    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState(DEFAULT_TOTAL);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState(null);
    const [queryDebounceActive, setQueryDebounceActive] = useState(false);
    const [page, setPage] = useState(new URLSearchParams(search).get('page') || DEFAULT_PAGE);
    const [pageSize, setPageSize] = useState(new URLSearchParams(search).get('pageSize') || DEFAULT_PAGE_SIZE);

    const getSearchQuery = () => {
        return new URLSearchParams(search).get('q') || null;
    };
    const setSearchQuery = (q) => {
        history.push({search: `?q=${q}&page=${DEFAULT_PAGE}&pageSize=${DEFAULT_PAGE_SIZE}`});
    };

    const fetchData = async (params = null) => {
        setLoading(true);
        try {
            let data = null;
            if(!!entityFetchOpts.fetchEntity) {
                data = await entityFetchOpts.fetchEntity(params);
            }
            else {
                data = await entityUtil.getEntity(entityName, params, entityFetchOpts);
            }

            const entityData = data?.data;
            setTotal(data?.metadata?.total || DEFAULT_TOTAL);
            setDataSource(entityData);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        update () {
            fetchData(getFetchParams()).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const getFetchParams = () => {
        let params = null;
        if(!!page) {
            // API paging starts from 0
            params = {...params, page: page - 1}
        }
        if(!!pageSize) {
            params = {...params, pageSize: pageSize}
        }
        if(!!getSearchQuery()) {
            params = {...params, q: getSearchQuery()}
        }
        return params;
    };

    // delayed search fetch
    const debouncedSearch = useDebounce(() => {
        if(query !== null) {
            fetchData(getFetchParams())
                .then(() => {})
                .catch(() => {})
                .finally(() => {
                    setQueryDebounceActive(false);
                });
        }
    }, DEFAULT_SEARCH_DELAY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(debouncedSearch, [query]);
    useEffect(() => {
        if(query === null) {
            fetchData(getFetchParams());
        }

        return () => {
            // clear search params before component unmount,
            // IMPORTANT: if entity wasn't clicked

            // if(!rowClicked) {
            //     history.push({search: ''});
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // not on first render custom hook
    useDidMountEffect(() => {
        if(!queryDebounceActive) {
            fetchData(getFetchParams()).then();
        }
    }, [page, pageSize]);

    const handleOnRowClick = (record) => {
        if(!!entity) {
            history.push(`${entity}/${record.uuid}`);
        }
    };
    const handlePagingOnChange = (newPage, newPageSize) => {
        if(!!query) {
            history.push({search: `?q=${query}&page=${newPage}&pageSize=${newPageSize}`});
        }
        else {
            history.push({search: `?page=${newPage}&pageSize=${newPageSize}`});
        }
        setPage(newPage);
        setPageSize(newPageSize);
    };
    const onSearchChange = (e) => {
        // avoid double fetch
        setQueryDebounceActive(true);

        const value = e?.target?.value;

        // reset paging
        setPage(DEFAULT_PAGE);
        setPageSize(DEFAULT_PAGE_SIZE);

        // add query to url
        setSearchQuery(value);

        // update query state
        setQuery(value);
    };

    return(
        <EntityDataTableWrapper>
            {showSearch &&
                <Input 
                    allowClear 
                    value={getSearchQuery()} 
                    onChange={onSearchChange} 
                    prefix={<SearchOutlined />} 
                    className="ant-datatable-search-input" 
                    placeholder={searchPlaceholder}
                    disabled={loading}
                />
            }
            {(!loading && Array.isArray(dataSource) && dataSource.length === 0) ?
                <EmptyDataTableWrapper>
                    <EmptyDataTableContainer>
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: emptyRequestsAnimation,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            width={250}
                        />
                        <h2>{emptyTitle}</h2>
                        <EmptyListDescription>{emptyMessage}</EmptyListDescription>
                    </EmptyDataTableContainer>
                </EmptyDataTableWrapper>
                 :
                <EntityDataTableContainer>
                    <DataTable onRowClick={handleOnRowClick}
                               columns={columns}
                               pagination={{
                                   current: parseInt(page),
                                   pageSize: pageSize,
                                   total: total,
                                   onChange: handlePagingOnChange,
                                   showSizeChanger: false,
                               }}
                               emptyComponent={"No se han encontrado resultados."}
                               dataSource={dataSource}
                               loading={loading} />
                </EntityDataTableContainer>
            }
        </EntityDataTableWrapper>
    );
};

const EntityDataTableWrapper = styled.div``;
const EntityDataTableContainer = styled.div``;
const EmptyDataTableWrapper = styled.div`
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
const EmptyDataTableContainer = styled.div`
  margin-top: 10%;
`;
const EmptyListDescription = styled.div`
  font-size: 15px;
  color: var(--unselected-text-color);
  font-weight: 500;
  max-width: 450px;
`;

export default forwardRef(EntityDataTable);
