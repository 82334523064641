import React from "react";
import styled from "styled-components";
import Header from "./Header";

const HeaderTitle = props => {
    return (
        <Header {...props}>
            <HeaderTitleWrapper>
                {props.children}
            </HeaderTitleWrapper>
        </Header>
    );
};

const HeaderTitleWrapper = styled.div`
    color: var(--text-color);
    font-family: var(--header-font);
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
`;

export default HeaderTitle;
