import React, {useState} from "react";
import styled from "styled-components";
import VerificationCodeInput from "../../components/VerificationCodeInput";
import { VerificationCode } from "../../types";
import { Button } from "antd";

type VerificationCodeFormProps = {
    onSubmit?: (data: VerificationCode) => void,
    loading?: boolean,
    submitButtonText?: string
}

const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
    loading = false,
    submitButtonText = "Siguiente",
    ...props
}) => {
    const [finished, setFinished] = useState(false);
    const [code, setCode] = useState<string | null>(null);
    const handleOnFinish = (code: string) => {
        setCode(code);
        setFinished(true);
    }
    const handleOnSubmitClick = () => {
        if(props.onSubmit && !!code) {
            return props.onSubmit({code: code});
        }
        return;
    }
    const handleOnChange = (code: string[]) => {
        if(code.filter(digit => digit === '').length === 0) {
            setFinished(true);
        }
        else {
            setFinished(false);
        }
    };
    return (
        <Wrapper>
            <VerifyCodeInput onChange={handleOnChange} numberOfDigits={6} loading={loading} onFinish={handleOnFinish} />
            {!!props.onSubmit &&
                <SubmitButton block loading={loading} type="primary" onClick={handleOnSubmitClick} disabled={!finished}>{submitButtonText}</SubmitButton>
            }
        </Wrapper>
    );
};

export default VerificationCodeForm;

const Wrapper = styled.div`
    margin-top: 30px;
`;
const SubmitButton = styled(Button)`
    margin-top: 30px;
`;
const VerifyCodeInput = styled(VerificationCodeInput)`
    margin: 40px 0;
`;