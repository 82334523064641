import React from "react";
import styled from "styled-components";
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import {PlusOutlined, SettingOutlined} from "@ant-design/icons";
import Container from "../components/layout/Container";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import LinkButton from "../components/layout/LinkButton";
import EntityDataTableActionsSubtitle from "../components/entity/EntityDataTableActionsSubtitle";
import { Tag } from "antd";

const EmergencyContacts = props => {
    const columns = [
        {
            title: 'Nombre',
            ellipsis: true,
            dataIndex: 'name',
            render: (name) => <b>{name}</b>
        },
        {
            title: 'Teléfono',
            responsive: ['md'],
            ellipsis: true,
            render: (record, index) => <b>{record?.country_code} {record?.phone}</b>
        },
        {
            title: 'Categoría',
            ellipsis: true,
            responsive: ['md'],
            render: (record, index) =>
                <Tag>{!!record.category ? record.category?.name : '-'}</Tag>
        },
    ];
    return (
        <Container>
            <EntityWrapper>
                <EntityDataTableActions>
                    <EntityDataTableActionsSubtitle>Administración de Contactos</EntityDataTableActionsSubtitle>
                    <LinkButton type="default" to={`${ROUTE.ROUTES.EMERGENCY_CONTACT_CATEGORIES}`} style={{marginRight: '15px'}}>
                        <SettingOutlined /> <span>Administrar Categorías</span>
                    </LinkButton>
                    <LinkButton to={`${ROUTE.ROUTES.EMERGENCY_CONTACT}/${ROUTE.KEYWORDS.NEW}`}>
                        <PlusOutlined /> <span>Nuevo Contacto</span>
                    </LinkButton>
                </EntityDataTableActions>
                <EntityContainer>
                    <EntityDataTable
                        entity={ROUTE.ROUTES.EMERGENCY_CONTACT}
                        emptyTitle={'¡Aún no tienes contactos de emergencia!'}
                        emptyMessage={'Una vez que agregues contactos de emergencia podrás ver su estatus... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.EMERGENCY_CONTACT} />
                </EntityContainer>
            </EntityWrapper>
        </Container>
    );
};

const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;

export default injectIntl(EmergencyContacts);
