import React, {useEffect} from "react";
import { 
    Form, 
    Button, 
    Select, 
    // Spin 
} from 'antd';
// import * as accountUtil from "../../utils/Account";
import App from "../utils/App";
import {RESIDENT} from "../constants";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
// import Api from "../../utils/Api";
import {ArrowLeftOutlined} from "@ant-design/icons";

const { Option } = Select;

const ResidentForm = (props) => {
    const {entity, intl, loading, onSubmit} = props;
    // const [accountsLoading, setAccountsLoading] = useState(false);
    // const [accounts, setAccounts] = useState([]);
    const onFinish = values => {
        onSubmit(values);
    };
    const onCancel = () => {
        window.history.back();
    };
    // const fetchAccounts = async (params = null) => {
    //     setAccountsLoading(true);
    //     setAccounts([]);
    //     try {
    //         const accountsData = (await accountUtil.getAccounts(params)).data;
    //         setAccounts(accountsData);
    //     }
    //     catch(error) {
    //         console.log(error);
    //         App.showError(Api.parseResponseError(error));
    //     }
    //     finally {
    //         setAccountsLoading(false);
    //     }
    // };
    useEffect(() => {
        //fetchAccounts().then();
    }, []);
    // const onAccountSearch = (query) => {
    //     fetchAccounts({q:query});
    // };
    return (
        <Form layout={"vertical"}
              initialValues = {!!entity ? {
                  role: entity.role,
                  account_uuid: entity?.account?.uuid
              } : {}}
              name="control-ref"
              onFinish={onFinish}>
            {/* <Form.Item
                name="account_uuid"
                label="Casa / Departamento"
                rules={[{required: true}]}
            >
                <Select
                    placeholder="Selecciona una de las siguientes opciones"
                    showSearch
                    onSearch={onAccountSearch}
                    filterOption={false}
                    notFoundContent={accountsLoading ? <Spin /> : `No hay resultados que cumplan con tu búsqueda`}
                    showArrow={false}
                    loading={accountsLoading}
                >
                    {!!entity ?
                        accounts.length > 0 ? accounts.map(account =>
                            <Option key={`${account?.uuid}${account?.number}`} value={account.uuid}>{account?.section?.name} {account.number}</Option>)
                            :
                            <Option key={`${entity?.account?.uuid}${entity?.account?.number}`} value={entity?.account?.uuid}>{entity?.account?.section?.name} {entity?.account?.number}</Option>

                    : null
                    }

                </Select>
            </Form.Item> */}
            <Form.Item
                name="role"
                label="Rol"
                rules={[{required: true}]}
            >
                <Select
                    placeholder="Selecciona una de las siguientes opciones"
                    allowClear
                >
                    {App.getCatalogListAsArray(RESIDENT.ROLE).map(
                        role =>
                            <Option key={`resident-role-${role}`}
                                    value={role}>{intl.formatMessage({id:`resident.role.${role}`})}
                            </Option>
                    )}
                </Select>
            </Form.Item>
            <FormActionsContainer>
                <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                    <ArrowLeftOutlined />
                    Regresar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

ResidentForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(ResidentForm);
