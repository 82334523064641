import React from "react";
import styled from "styled-components";
import {Avatar, Tag} from 'antd';
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import {PlusOutlined, SettingOutlined} from "@ant-design/icons";
import Container from "../components/layout/Container";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import LinkButton from "../components/layout/LinkButton";
import App from "../utils/App";
import EntityDataTableActionsSubtitle from "../components/entity/EntityDataTableActionsSubtitle";

const Guards = props => {
    const {intl} = props;
    const columns = [
        {
            title: 'Nombre',
            render: (record, index) =>
                <React.Fragment>
                    <Avatar style={{fontSize: "18px"}} size={40} src={record.profile_image_url}>
                        {!!record.first_name && record.first_name.length > 0 && record.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{record.first_name} {record.last_name}</b>
                </React.Fragment>
        },
        {
            title: 'Teléfono',
            responsive: ['xxl'],
            dataIndex: 'phone'
        },
        {
            title: 'Correo electrónico',
            responsive: ['xxl'],
            dataIndex: 'email'
        },
        {
            title: 'Caseta',
            dataIndex: 'entrance',
            responsive: ['md'],
            render: entrance => <Tag>{entrance.name}</Tag>
        },
        {
            title: 'Acceso',
            dataIndex: 'status',
            responsive: ['md'],
            render: status => (
                <Tag color={App.getTagColorByStatus(status, ENTITY.TYPE.GUARDS)}>{intl.formatMessage({id:`guard.status.${status}`})}</Tag>
            ),
        },
    ];
    return (
        <Container>
            <EntityWrapper>
                <EntityDataTableActions>
                    <EntityDataTableActionsSubtitle>Administración de Guardias</EntityDataTableActionsSubtitle>
                    <LinkButton type="default" to={`${ROUTE.ROUTES.ENTRANCES}`} style={{marginRight: '15px'}}>
                        <SettingOutlined /> <span>Administrar Casetas</span>
                    </LinkButton>
                    <LinkButton  to={`${ROUTE.ROUTES.GUARD}/${ROUTE.KEYWORDS.NEW}`}>
                        <PlusOutlined /> <span>Nuevo Guardia</span>
                    </LinkButton>
                </EntityDataTableActions>
                <EntityContainer>
                    <EntityDataTable
                        entity={ROUTE.ROUTES.GUARD}
                        emptyTitle={'¡Aún no tienes Guardias!'}
                        emptyMessage={'Una vez que agregues Guardias podrás ver su estatus, asignarlos en una caseta... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.GUARDS} />
                </EntityContainer>
            </EntityWrapper>
        </Container>
    );
};

const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;

export default injectIntl(Guards);
