import { AnyAction } from "redux";
import * as types from "../actionTypes";

// parseLocalStorage utility function
function parseLocalStorage(key: string, fallbackValue: string | null = null) {
    try {
        const value: string | null = localStorage.getItem(key);
        if(!!value) {
            return JSON.parse(value);
        }
        return null;
    }
    catch(error) {
        console.log(error);
        return fallbackValue;
    }
}

// authReducer implementation
const authReducer = (
    state = {
        isNewUser: false,
        isAuthenticated: false,
        user: parseLocalStorage('user') || null,
        member: null,
        memberships: [],
        client: parseLocalStorage('client') || null,
        resident: parseLocalStorage('resident') || null,
        residentships: parseLocalStorage('residentships') || null,
        account: parseLocalStorage('account') || null,
        subscriptionStatus: null,
        loading: true,
    },
    action: AnyAction
) => {
    switch (action.type) {
        case types.SET_AUTH_USER:
            return { ...state, isAuthenticated: !!action.user, user: action.user || null};
        case types.SET_AUTH_MEMBER:
            return { ...state, member: action.member || null};
        case types.SET_AUTH_MEMBERSHIPS:
            return { ...state, memberships: action.memberships || []};
        case types.SET_AUTH_CLIENT:
            return { ...state, client: action.client || null};
        case types.SET_AUTH_ACCOUNT:
            return { ...state, account: action.account || null};
        case types.SET_AUTH_RESIDENT:
            return { ...state, resident: action.resident || null};
        case types.SET_AUTH_RESIDENTSHIPS:
            return { ...state, residentships: action.residentships || []};
        case types.SET_AUTH_SUBSCRIPTION_STATUS:
            return { ...state, subscriptionStatus: action.subscriptionStatus || null};
        case types.SET_AUTH_LOADING:
            return { ...state, loading: action.loading };
        case types.SET_AUTH_IS_NEW_USER:
            return { ...state, isNewUser: action.isNewUser || false };
        default:
            return state;
    }
};

export default authReducer;
