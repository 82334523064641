import React, {useState} from "react";
import styled from "styled-components";
import {Col, Row} from "antd";
import * as Resident from "../utils/Residents";
import App from "../utils/App";
import Api from "../utils/Api";
import {Spin} from "antd";
import Card from "../components/layout/Card";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import InviteResidentForm from "../forms/InviteResidentForm";

const InviteResident = props => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async data => {
        setSubmitLoading(true);
        try {
            await Resident.invite(data);
            App.showSuccess('Se ha enviado una invitación al usuario correctamente');
            setSubmitLoading(false);
            window.history.back();
        }
        catch(error) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };
    return (
        <NewWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={`Nuevo Residente`}
                subTitle=""
            />
            <Container>
                <Spin spinning={false}>
                    <NewContainer>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card>
                                    <InviteResidentForm loading={submitLoading} onSubmit={onSubmit} />
                                </Card>
                            </Col>
                        </Row>
                    </NewContainer>
                </Spin>
            </Container>
        </NewWrapper>
    );
};

const NewWrapper = styled.div``;
const NewContainer = styled.div``;

export default InviteResident;
