import React, {useEffect, useState} from "react";
import {Form, Button, Select, Input} from 'antd';
import * as api from "../utils/EmergencyContactCategories";
import App from "../utils/App";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import Api from "../utils/Api";
import {ArrowLeftOutlined} from "@ant-design/icons";

const EmergencyContactForm = (props) => {
    const {entity, loading, onSubmit} = props;
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const onFinish = values => {
        onSubmit({...values, country_code: '52'});
    };
    const onCancel = () => {
        window.history.back();
    };
    const fetchCategories = async (params = null) => {
        setCategoriesLoading(true);
        setCategories([]);
        try {
            const data = (await api.getAll(params)).data;
            setCategories(data);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setCategoriesLoading(false);
        }
    };
    useEffect(() => {
        //fetchAccounts().then();
        fetchCategories();
    }, []);
    return (
        <Form layout={"vertical"}
              initialValues = {!!entity ? {
                  category_uuid: entity?.category?.uuid,
                  name: entity?.name,
                  phone: entity?.phone,
              } : {}}
              name="control-ref"
              onFinish={onFinish}>
            <Form.Item
                name="name"
                label="Nombre"
                rules={[{required: true}]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phone"
                label="Teléfono"
                rules={[{required: true}]}
            >
                <Input
                    prefix="+52"
                />
            </Form.Item>
            <Form.Item
                name="category_uuid"
                label="Categoría"
                rules={[{required: true}]}
            >
                {/*<Select*/}
                {/*    placeholder="Selecciona una de las siguientes opciones"*/}
                {/*    showSearch*/}
                {/*    onSearch={onCategorySearch}*/}
                {/*    filterOption={false}*/}
                {/*    notFoundContent={categoriesLoading ? <Spin /> : `No hay resultados que cumplan con tu búsqueda`}*/}
                {/*    showArrow={false}*/}
                {/*    loading={categoriesLoading}*/}
                {/*>*/}
                {/*    {!!entity ?*/}
                {/*        categories.length > 0 ? categories.map(category =>*/}
                {/*                <Option key={`${category.uuid}-category`} value={category.uuid}>{category.name}</Option>)*/}
                {/*            :*/}
                {/*            <Option key={`${entity?.category?.uuid}-category-entity`} value={entity?.category?.uuid}>{entity?.category?.name}</Option>*/}

                {/*        : null*/}
                {/*    }*/}

                {/*</Select>*/}
                <Select
                    placeholder="Selecciona una de las siguientes opciones"
                    allowClear
                    loading={categoriesLoading}
                >
                    {categories.map(category => <Select.Option key={category.uuid} value={category.uuid}>{category.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <FormActionsContainer>
                <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                    <ArrowLeftOutlined />
                    Regresar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

EmergencyContactForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(EmergencyContactForm);
