import React from 'react';
import styled from "styled-components";
import {connect} from 'react-redux';
import PendingRequestsList from "../components/member/PendingRequestsList";
import {Row, Col} from "antd";
import AdoptionProgress from "../components/AdoptionProgress";
import VisitStatistics from "../components/VisitStatistics";
import Container from "../components/layout/Container";
import Card from "../components/layout/Card";
import HeaderTitle from "../components/layout/HeaderTitle";

const HomeSection = props => {
    const {user} = props.auth;
    return(
        <>
        <HeaderTitle>
            Hola <HomeTItleUserFirstName>{user.first_name}</HomeTItleUserFirstName> 👋
        </HeaderTitle>
        <Container>
            <HomeWrapper>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card style={{marginBottom: "25px"}}>
                            <h3>Adopción</h3>
                            <StatisticsContainer>
                                <AdoptionProgress />
                            </StatisticsContainer>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card style={{marginBottom: "25px"}}>
                            <h3>Visitas</h3>
                            <StatisticsContainer>
                                <VisitStatistics />
                            </StatisticsContainer>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <PendingRequestsContainer>
                            <h3>Solicitudes de Registro</h3>
                            <PendingRequestsList />
                        </PendingRequestsContainer>
                    </Col>
                </Row>
            </HomeWrapper>
        </Container>
        </>
    );
};
const HomeWrapper = styled.div`
`;
const StatisticsContainer = styled.div`
  padding: 10px 0;
`;
// const HomeTitle = styled.div`
//   font-family: var(--header-font);
//   font-size: 2.5em;
//   font-weight: bold;
//   margin-bottom: 25px;
// `;
const HomeTItleUserFirstName = styled.span`
  font-weight: normal;
`;
const PendingRequestsContainer = styled.div`
  @media(max-width: 768px) {
    display: none;
  }
`;
const mapStateToProps = state => ({
    ...state
});
export default connect(mapStateToProps)(HomeSection);
