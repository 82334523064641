import React from "react";
import styled from "styled-components";
import {REVIEWS} from "../../constants";
import {Tag} from "antd";
import Description from "./Description";
import {DislikeOutlined, LikeOutlined, MehOutlined} from "@ant-design/icons";

const RatingSummary = (props) => {
    const getRatingPercentageAndCategoryNameAndColor = (positiveRatingsTotal = 0, totalRatings) => {
        const successColor = "var(--status-success-color)";
        const successTagColor = "success";
        const successBackgroundColor = "var(--status-success-background-color)";
        const errorTagColor = "danger";
        const errorColor = "var(--status-danger-color)";
        const errorBackgroundColor = "var(--status-danger-background-color";
        const defaultTagColor = "default";
        const defaultColor = "var(--unselected-text-color)";
        const defaultBackgroundColor = "var(--default-background-color)";
        const successIcon = <LikeOutlined />;
        const dangerIcon = <DislikeOutlined />;
        const defaultIcon = <MehOutlined />;
        const defaultText = "Sin Reseñas";
        const warningTagColor = "warning";
        if(totalRatings === 0) {
            return {categoryName: defaultText, color: defaultColor, percentage: 0, icon: defaultIcon, tagColor: defaultTagColor};
        }
        const percentage = Number((positiveRatingsTotal/totalRatings) * 100).toFixed(1);
        let categoryName = defaultText;
        let color = defaultColor;
        let backgroundColor = defaultBackgroundColor;
        let icon = <MehOutlined />;
        let tagColor = defaultTagColor;
        // steam
        /*
            95 - 99% : Overhwelmingly Positive
            94 - 80% : Very Positive
            80 - 99% + few reviews: Positive
            70 - 79% : Mostly Positive
            40 - 69% : Mixed
            20? - 39% : Mostly Negative
            0 - 39% + rew reviews: Negative
            0 - 19% : Very Negative
            0 - 19% + many reviews: Overwhelmingly Negative
         */
        if(totalRatings < 2 && totalRatings > 0) {
            categoryName = "Sin reseñas suficientes";
            color = defaultColor;
            backgroundColor = defaultBackgroundColor;
            icon = "";
            tagColor = defaultTagColor;
        }
        else if(percentage >= 80 && totalRatings <= 5) {
            categoryName = "Positivo";
            color = successColor;
            backgroundColor = successBackgroundColor;
            icon = successIcon;
            tagColor = successTagColor;
        }
        else if(percentage >= 95 && totalRatings > 5) {
            categoryName = "Extremadamente Positivo";
            color = successColor;
            backgroundColor = successBackgroundColor;
            icon = successIcon;
            tagColor = successTagColor;
        }
        else if(percentage >= 80 && percentage < 95 && totalRatings > 5) {
            categoryName = "Muy Positivo";
            color = successColor;
            backgroundColor = successBackgroundColor;
            icon = successIcon;
            tagColor = successTagColor;
        }
        else if(percentage >= 70 && percentage < 80) {
            categoryName = "Mayormente Positivo";
            color = successColor;
            backgroundColor = successBackgroundColor;
            icon = successIcon;
            tagColor = successTagColor;
        }
        else if(percentage >= 40 && percentage < 70) {
            categoryName = "Mezclado";
            color = defaultColor;
            backgroundColor = defaultBackgroundColor;
            icon = defaultIcon;
            tagColor = warningTagColor;
        }
        else if(percentage >= 0 && percentage < 40 && totalRatings <= 5) {
            categoryName = "Negativo";
            color = errorColor;
            backgroundColor = errorBackgroundColor;
            icon = dangerIcon;
            tagColor = errorTagColor;
        }
        else if(percentage >= 20 && percentage < 40 && totalRatings > 5) {
            categoryName = "Mayormente Negativo";
            color = errorColor;
            backgroundColor = errorBackgroundColor;
            icon = dangerIcon;
            tagColor = errorTagColor;
        }
        else if(percentage >= 0 && percentage < 20 && totalRatings <= 5) {
            categoryName = "Muy Negativo";
            color = errorColor;
            backgroundColor = errorBackgroundColor;
            icon = dangerIcon;
            tagColor = errorTagColor;
        }
        else if(percentage >= 0 && percentage < 20 && totalRatings > 5) {
            categoryName = "Extremadamente Negativo";
            color = errorColor;
            backgroundColor = errorBackgroundColor;
            icon = dangerIcon;
            tagColor = errorTagColor;
        }
        return {categoryName: categoryName, color: color, percentage: percentage, backgroundColor: backgroundColor, icon: icon, tagColor: tagColor};
    };
    const getRatingPercentage = () => {
        if(total > 0) {
            const percentage = Math.floor(recommended/total * 100);
            return `${percentage}%`;
        }
    };
    const {
        recommended = 0,
        total = 0,
        showDescription = false,
        rating = getRatingPercentageAndCategoryNameAndColor(recommended, total),
        personName = "esta persona",
        size = 12
    } = props;
    return (
        <ReviewsSummaryWrapper {...props}>
            {total >= REVIEWS.MIN_COUNT_DISPLAY ?
                <Tag style={{fontSize:size}} icon={rating.icon} color={rating.tagColor}>{rating.categoryName} ({total})</Tag> :
                <Tag color="default">{total} reseña(s)</Tag>
            }
            {showDescription &&
                <Description style={{marginTop: '10px'}}>El {getRatingPercentage()} de {Number(total)} residente(s) recomiendan a {personName}</Description>
            }
        </ReviewsSummaryWrapper>
    );
};

const ReviewsSummaryWrapper = styled.div``;

export default RatingSummary;
