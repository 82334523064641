import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Statistic, Spin} from "antd";
import App from "../../utils/App";
import Api from "../../utils/Api";
import * as Analytics from "../../utils/Analytics";

const VisitStatistics = props => {
    const [visitsSummary, setVisitsSummary] = useState({
       today: 0,
       currentWeek: 0,
       dailyAverage: 0
    });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchAnalyticsSummary().then();
    }, []);
    const fetchAnalyticsSummary = async () => {
        setLoading(true);
        try {
            const summary = await Analytics.getVisitsSummary();
            setVisitsSummary({
                today: summary.today,
                currentWeek: summary.current_week,
                dailyAverage: summary.total
            });
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    return (
        <VisitStatisticsWrapper>
            <Spin spinning={loading}>
                <VisitStatisticsContainer>
                    <Statistic title={"Hoy"} value={visitsSummary.today} />
                    <Statistic title={"En la semana"} value={visitsSummary.currentWeek} />
                    <Statistic title={"Total"} value={visitsSummary.dailyAverage} />
                </VisitStatisticsContainer>
            </Spin>
        </VisitStatisticsWrapper>
    );
};

const VisitStatisticsWrapper = styled.div`
  width: 100%;
`;
const VisitStatisticsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;
export default VisitStatistics;
