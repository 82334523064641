import React from "react";
import {Switch, Redirect} from "react-router-dom";
import Login from "../Auth/Login";
import PrivateRoute from "../../components/Routes/PrivateRoute";
import {CLIENT, ROUTE} from "../../constants";
import AccountSelectSection from "../AccountSelect";
import PaidUserRoutes from "../../components/Routes/PaidUserRoutes";
import UnpaidUserRoutes from "../../components/Routes/UnpaidUserRoutes";
import { useAppSelector } from '../../hooks/useAppSelector';
import CompleteSignUp from "../Auth/CompleteSignUp";

const RootPage: React.FC = () => {
    const auth = useAppSelector(state => state.auth);

    // verify current subscription status
    const clientSubscriptionIsActive = () => {
        return auth?.client?.status === CLIENT.STATUS.PAID; 
    };

    const userNeedsToFinishSignUp = () => {
        if(auth.isNewUser) {
            return true;
        }
        if(!!auth.user && !auth.user.registered) {
            return true;
        }
        return false;
    }

    // if not loading and logged in
    if(!auth.loading && !!auth.user && !userNeedsToFinishSignUp()) {

        // if there is a client (tenant) selected
        if(!!auth.client) {
            if(clientSubscriptionIsActive()) {
                return (
                    <Switch>
                        <PaidUserRoutes />
                    </Switch> 
                )
            }
            else {
                return (
                    <Switch>
                        <UnpaidUserRoutes />
                    </Switch>
                );
            }
        }

        // if no client (tenant) is selected yet
        else {
            // if user profile isn`t complete
            return (
                <Switch>
                    <PrivateRoute exact path={ROUTE.ROUTES.ACCOUNT_SELECT} type="private">
                        <AccountSelectSection />
                    </PrivateRoute>
                    <Redirect to={ROUTE.ROUTES.ACCOUNT_SELECT} />
                </Switch>
            );
        }
    }

    // if new user logged in
    else if(!auth.loading && userNeedsToFinishSignUp()) {
        return (
            <Switch>
                <PrivateRoute exact path={ROUTE.ROUTES.COMPLETE_SIGNUP} type="guest">
                    <CompleteSignUp />
                </PrivateRoute>
                <Redirect to={ROUTE.ROUTES.COMPLETE_SIGNUP} />
            </Switch>
        );
    }
    
    // if not logged in
    else {
        return (
            <Switch>
                <PrivateRoute exact path={ROUTE.ROUTES.LOGIN} type="guest">
                    <Login />
                </PrivateRoute>
                <Redirect to={ROUTE.ROUTES.LOGIN} />
            </Switch>
        );
    }
}

export default RootPage;