import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { Progress, Spin } from 'antd';
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import Container from "../components/layout/Container";
import App from "../utils/App";
import LinkButton from "../components/layout/LinkButton";
import {CheckCircleFilled, PlusOutlined, SettingOutlined} from "@ant-design/icons";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import * as Analytics from "../utils/Analytics";
import Api from "../utils/Api";
import Description from "../components/layout/Description";
import EntityDataTableActionsSubtitle from "../components/entity/EntityDataTableActionsSubtitle";
import CountTag from "../components/layout/CountTag";
import { getCurrentSubscription } from "../utils/Clients";
import Card from "../components/layout/Card";

const Accounts = props => {
    const [accountsSummary, setAccountsSummary] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    const [accountsSummaryLoading, setAccountsSummaryLoading] = useState(true);
    const columns = [
        {
            title: 'Residencia',
            render: record => <b>{record?.section?.name} {record.number}</b>
        },
        {
            title: 'Intercom',
            responsive: ['xxl'],
            dataIndex: 'phone'
        },
        {
            title: 'Residentes',
            dataIndex: 'members',
            responsive: ['lg'],
            render: members => <CountTag>{members.length}</CountTag>
        }
    ];
    const getProgressColor = () => {
        const percent = getAvailabilityPercent();
        if(percent < 75) {
            return 'var(--status-success-color)';
        }
        else if(percent >= 75 && percent < 90) {
            return 'var(--status-warning-color)';
        }
        else if(percent >= 90) {
            return 'var(--status-error-color)';
        }
    };
    const getAvailabilityPercent = () => {
        if(!!accountsSummary) {
            return Math.floor(getActiveAccountsCount()/getAvailableAccountsCount() * 100);
        }
        return 0;
    };
    const getAvailableAccountsCount = () => {
        return subscription?.accounts_limit || 0;
    };
    const getActiveAccountsCount = () => {
        return accountsSummary?.totalAccounts || 0;
    };
    const hasAvailableAccounts = () => {
        return getActiveAccountsCount() < getAvailableAccountsCount();
    };
    useEffect(() => {
        fetchAccountsSummary();
        fetchCurrentSubscription();
    }, []);
    const fetchCurrentSubscription = async () => {
        try {
            const subscription = await getCurrentSubscription();
            setSubscription(subscription);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setSubscriptionLoading(false);
        }
    };
    const fetchAccountsSummary = async () => {
        try {
            setAccountsSummary(await Analytics.getAdoption());
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setAccountsSummaryLoading(false);
        }
    };
    return (
        <Container>
            <AccountsWrapper>
                <AccountsContainer>
                    <EntityDataTableActions>
                        <EntityDataTableActionsSubtitle>Administración de Residencias</EntityDataTableActionsSubtitle>
                        <LinkButton type="default" to={`${ROUTE.ROUTES.SECTIONS}`} style={{marginRight: '15px'}}>
                            <SettingOutlined /> <span>Administrar Zonas / Edificios</span>
                        </LinkButton>
                        {hasAvailableAccounts() &&
                            <LinkButton to={`${ROUTE.ROUTES.ACCOUNT}/${ROUTE.KEYWORDS.NEW}`}>
                                <PlusOutlined/> <span>Nueva Residencia</span>
                            </LinkButton>
                        }
                    </EntityDataTableActions>
                    <Card style={{marginBottom: '15px', minHeight: '190px'}}>
                        <Spin spinning={accountsSummaryLoading || subscriptionLoading}>

                            {subscriptionLoading || accountsSummaryLoading ? null: 
                            
                            !hasAvailableAccounts() ?
                            <>
                                <h3>100% de ocupación <CheckCircleFilled style={{color: "var(--status-success-color"}} /></h3>
                                <Description>En tu plan contratado puedes tener hasta <b>{getAvailableAccountsCount()}</b> residencias activas, de las cuales ya has utilizado todas. Si deseas agregar más comunicate con un asesor.</Description>
                            </> :
                            <>
                                <h3>¡Aún puedes agregar {getAvailableAccountsCount() - getActiveAccountsCount()} residencias!</h3>
                                <Description>Actualmente utilizas {getActiveAccountsCount()} de {getAvailableAccountsCount()} disponibles en tu plan actual. Si deseas aumentar tu límite de residencias permitidas por favor contáctate con un asesor.</Description>
                                <Progress
                                    showInfo={false}
                                    percent={getAvailabilityPercent()}
                                    strokeColor={getProgressColor()} />
                                <div style={{marginLeft: "auto", marginRight: "auto", marginTop: "15px", maxWidth: "250px"}}>
                                    <LinkButton style={{marginTop: '15px'}} to={`${ROUTE.ROUTES.ACCOUNT}/${ROUTE.KEYWORDS.NEW}`}>
                                        <PlusOutlined/> <span>Agrega una residencia</span>
                                    </LinkButton>
                                </div>
                            </>
                            }
                        
                        </Spin>
                    </Card>
                    <EntityDataTable
                        entity={ROUTE.ROUTES.ACCOUNT}
                        emptyTitle={'¡Aún no tienes Residencias!'}
                        emptyMessage={'Una vez que tengas Residencias podrás ver su estatus, ver detalles como residentes... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.ACCOUNTS} />
                </AccountsContainer>
            </AccountsWrapper>
        </Container>
    );
};

const AccountsWrapper = styled.div``;
const AccountsContainer = styled.div`
`;
// const AvailabilityContainer = styled.div`
//   width: 100%;
//   background-color: var(--card-background);
//   border-radius: var(--card-border-radius);
//   padding: var(--card-padding);
//   margin-bottom: 15px;
// `;

export default injectIntl(Accounts);
