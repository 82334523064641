import React, {useState} from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import {Link} from "react-router-dom";
import {
    AppstoreOutlined,
    HomeOutlined,
    CarOutlined,
    LogoutOutlined,
    NotificationOutlined,
    LikeOutlined,
} from "@ant-design/icons";
import {ROUTE} from "../../constants";
import RouteUtils from "../../utils/Route";
import {Tooltip} from "antd";
// import {connect} from "react-redux";
// import {setAuthMember, setAuthClient, setAuthResident, setAuthResidentships} from "../../redux/actions/authActions";
import App from "../../utils/App";
import Auth from "../../utils/Auth";
import Logo from "../../assets/images/main/logo.svg";
import { useSelector } from "react-redux";

const routeUtils = new RouteUtils();
const authUtils = new Auth();
// const LOGOUT_KEY = "logout";
// const SWITCH_ACCOUNTS_KEY = "switchAccounts";

const Nav = (props) => {
    const [, setActiveMenuItem] = useState(null);
    const notifications = useSelector(state => state?.notifications);
    // const menu = (
    //     <Menu onClick={async ({key})=>{
    //         switch (key) {
    //             case LOGOUT_KEY:
    //                 try {
    //                     await authUtils.signout();
    //                     window.location.href = ROUTE.ROUTES.LOGIN;
    //                 }
    //                 catch(error) {
    //                     App.showError(error.toString());
    //                 }
    //                 return;
    //             default:
    //                 return;
    //         }
    //     }}>
    //         <Menu.Item key={LOGOUT_KEY}>Cerrar sesión</Menu.Item>
    //     </Menu>
    // );
    const onLogoutClick = async () => {
        try {
            await authUtils.signout();
            window.location.href = ROUTE.ROUTES.LOGIN;
        }
        catch(error) {
            App.showError(error.toString());
        }
    };
    // const accountMenu = (
    //     <Menu onClick={async ({key}) => {
    //         switch (key) {
    //             case SWITCH_ACCOUNTS_KEY:
    //                 try {
    //                     setAuthClient(null);
    //                     setAuthResident(null);
    //                     setAuthResidentships(null);
    //                     await authUtils.residentLogout();
    //                     window.location = ROUTE.ROUTES.ACCOUNT_SELECT;
    //                 }
    //                 catch(error) {
    //                     App.showError(error.toString());
    //                 }
    //                 return;
    //             case LOGOUT_KEY:
    //                 try {
    //                     await authUtils.signout();
    //                     window.location.href = ROUTE.ROUTES.LOGIN;
    //                 }
    //                 catch(error) {
    //                     App.showError(error.toString());
    //                 }
    //                 return;
    //             default:
    //                 return;
    //         }
    //     }}>
    //         <Menu.ItemGroup title="Residenciales">
    //             <Menu.Item key={SWITCH_ACCOUNTS_KEY}>
    //                 <SwapOutlined/>
    //                 Cambiar de residencial
    //             </Menu.Item>
    //         </Menu.ItemGroup>
    //         <Menu.ItemGroup title="Manejo de sesión">
    //             <Menu.Item key={LOGOUT_KEY}>
    //                 <LogoutOutlined/>
    //                 Cerrar sesión
    //             </Menu.Item>
    //         </Menu.ItemGroup>
    //     </Menu>
    // );
    const handleMenuItemOnClick = (id) => {
        setActiveMenuItem(id);
    };
    return (
        <NavWrapper>
            <MenuContainer>
                <MenuItemsContainer>
                    <MenuLogoContainer>
                        <img src={Logo} alt="Logo" />
                    </MenuLogoContainer>
                    <MenuNavContainer>
                        <MenuItemList>
                            <Link to={ROUTE.ROUTES.HOME}>
                                <MenuItem title={"Inicio"}
                                          id={"home"}
                                          active={routeUtils.windowContainsExactRoute(ROUTE.ROUTES.HOME)}
                                          onClick={handleMenuItemOnClick}
                                          icon={AppstoreOutlined}
                                          iconActive={AppstoreOutlined} />
                            </Link>
                            <Link to={ROUTE.ROUTES.MANAGEMENT}>
                                <MenuItem title={"Residencial"}
                                          id={"residentes"}
                                          active={routeUtils.windowContainsRoute(ROUTE.ROUTES.MANAGEMENT)}
                                          onClick={handleMenuItemOnClick}
                                          icon={HomeOutlined}
                                          iconActive={HomeOutlined} />
                            </Link>
                            <Link to={ROUTE.ROUTES.VISITS}>
                                <MenuItem title={"Visitas"}
                                          id={"visitas"}
                                          active={routeUtils.windowContainsRoute(ROUTE.ROUTES.VISITS)}
                                          onClick={handleMenuItemOnClick}
                                          icon={CarOutlined}
                                          iconActive={CarOutlined} />
                            </Link>
                            <Link to={ROUTE.ROUTES.NOTICE_BOARD}>
                                <MenuItem title={"Avisos"}
                                          id={"avisos"}
                                          active={routeUtils.windowContainsRoute(ROUTE.ROUTES.NOTICE_BOARD)}
                                          onClick={handleMenuItemOnClick}
                                          icon={NotificationOutlined}
                                          iconActive={NotificationOutlined} />
                            </Link>
                            <Link to={ROUTE.ROUTES.MEMBER_REQUESTS}>
                                <MenuItem title={"Solicitudes"}
                                          id={"solicitudes_de_registro"}
                                          active={routeUtils.windowContainsRoute(ROUTE.ROUTES.MEMBER_REQUESTS)}
                                          onClick={handleMenuItemOnClick}
                                          icon={LikeOutlined}
                                          badgeCount={notifications?.currentMemberRequestsCount}
                                          iconActive={LikeOutlined} />
                            </Link>
                        </MenuItemList>
                    </MenuNavContainer>
                    {/* <MenuProfileContainer>
                        <Tooltip title="Cuenta" placement="right">
                            {!!auth.resident ?
                                <Dropdown overlay={accountMenu} trigger={['click']}>
                                    <ProfileActionsContainer>
                                        <Avatar shape="square" size={40} icon={<UserOutlined />} />
                                    </ProfileActionsContainer>
                                </Dropdown> :
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <ProfileActionsContainer>
                                        <Avatar shape="square" size={40} icon={<UserOutlined />} />
                                    </ProfileActionsContainer>
                                </Dropdown>
                            }
                        </Tooltip>
                    </MenuProfileContainer> */}
                    <MenuProfileContainer>
                        <Tooltip title="Cuenta" placement="right">
                            <MenuItem title={"Cerrar Sesión"}
                                          id={"logout"}
                                          onClick={onLogoutClick}
                                          icon={LogoutOutlined}
                                          iconActive={LogoutOutlined} />
                        </Tooltip>
                    </MenuProfileContainer>
                </MenuItemsContainer>
            </MenuContainer>
        </NavWrapper>
    );
};
const NavWrapper = styled.div`
  min-height: 100vh;
  width: var(--nav-width);
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 2;
`;
const MenuContainer = styled.div`
  /* background-image: linear-gradient(var(--primary-color) , var(--secondary-color)); */
  background-color: var(--contrast-background-color);
  height: 100vh;
  width: 100%;
`;
const MenuLogoContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: var(--nav-width);
  img {
    max-height: 36px;
    display: block;
  }
`;
const MenuProfileContainer = styled.div`
  .ant-avatar {
    background: transparent;
  }
`;
const MenuNavContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const MenuItemsContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-basis: 64px;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
`;
const MenuItemList = styled.div`
  width: 100%;
  .ant-badge {
    display: block;
  }
  .ant-badge-count {
    box-shadow: none;
    font-weight: 500;
  }
`;
// const ProfileActionsContainer = styled.div`
//   &:hover {
//     cursor: pointer;
//   }
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 8px 13px;
//   height: var(--nav-width);
//   width: var(--nav-width);
//   background: rgba(0, 0, 0, 0.25);
//   border: 2px solid transparent;
//   transition: border ease-in 0.2s;
// `;

export default Nav;
