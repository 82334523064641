import React from "react";
import styled from "styled-components";

const EntityDataTableActions = props => {
  return (
      <EntityDataTableActionsWrapper>
          {props.children}
      </EntityDataTableActionsWrapper>
  );
};

const EntityDataTableActionsWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

export default EntityDataTableActions;

