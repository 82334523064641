import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Row, Tag, Button} from "antd";
import {useParams} from "react-router-dom";
import App from "../utils/App";
import Api from "../utils/Api";
import {Spin} from "antd";
import Card from "../components/layout/Card";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import * as StaffUtil from "../utils/Staff";
import StaffForm from "../forms/StaffForm";
import * as PasscodeUtil from "../utils/Passcode";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY, ROUTE, STAFF} from "../constants";
import {LoadingOutlined, LockOutlined} from "@ant-design/icons";
import Description from "../components/layout/Description";
import * as StaffRating from "../utils/StaffRating";
import Review from "../components/layout/Review";
import CardSubtitle from "../components/layout/CardSubtitle";
import { useHistory } from "react-router-dom";
import EntityStatusButton from "../components/entity/EntityStatusButton";
import ImportantNumber from "../components/layout/ImportantNumber";
import RatingSummary from "../components/layout/RatingSummary";

const StaffDetail = props => {
    const {uuid} = useParams();
    const history = useHistory();
    const [entity, setEntity] = useState(null);
    const [newEntity, setNewEntity] = useState(true);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [entityPasscode, setEntityPassocode] = useState(null);
    const [entityPasscodeLoading, setEntityPasscodeLoading] = useState(false);
    const [entityRatings, setEntityRatings] = useState([]);
    const [, setEntityRatingsTotal] = useState(0);
    const [entityRatingsLoading, setEntityRatingsLoading] = useState(false);
    const fetchEntity = async () => {
        setLoading(true);
        try {
            const entityData = await StaffUtil.getByUuid(uuid);
            setEntity(entityData);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    const fetchEntityReviews = async () => {
        setEntityRatingsLoading(true);
        try {
            const ratingsData = await StaffRating.getAllByStaffUuid(uuid);
            setEntityRatings(ratingsData.data);
            setEntityRatingsTotal(ratingsData.metadata.total);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setEntityRatingsLoading(false);
        }
    };
    const fetchEntityPasscode = async () => {
        setEntityPasscodeLoading(true);
        try {
            const passcodeData = (await PasscodeUtil.getStaffPasscode(uuid));
            setEntityPassocode(passcodeData.passcode);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setEntityPasscodeLoading(false);
        }
    };
    const onViewEntityPasscodeClick = async () => {
        fetchEntityPasscode();
    };
    useEffect(() => {
        if(!!uuid) {
            setNewEntity(false);
            fetchEntity().then()
            fetchEntityReviews({pageSize: 5});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = async data => {
        setSubmitLoading(true);
        try {
            if(newEntity) {
                await StaffUtil.create(data);
                App.showSuccess('El staff ha sido agregado correctamente');
            }
            else {
                await StaffUtil.updateByUuid(uuid, {...data, refresh_rating: true});
                App.showSuccess('El staff ha sido actualizado correctamente');
            }
            setSubmitLoading(false);
            window.history.back();
        }
        catch(error) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha elimiado correctamente la sección");
        window.history.back();
    };
    const onSeeAllReviewsClick = () => {
        if(!!entity) {
            history.push(`${ROUTE.ROUTES.STAFF_REVIEWS}/${entity.uuid}`);
        }
    };
    return (
        <EntityDetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={entity !== null ? `${entity.first_name} ${entity.last_name}` : (loading ? <LoadingOutlined /> : 'Nuevo Staff')}
                subTitle={entity !== null ? `` : ``}
            />
            <Container>
                <Spin spinning={loading}>
                    {(!!entity || newEntity) &&
                    <EntityDetailContainer>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card>
                                    <StaffForm loading={submitLoading} entity={entity} onSubmit={onSubmit} />
                                </Card>
                            </Col>
                        </Row>
                        {!!entity && Array.isArray(entity.accounts) && entity.accounts.length > 0 &&
                            <>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Card style={{marginTop: '20px'}}>
                                            <h3>Residencias</h3>
                                            {entity.accounts.map(account => <Tag key={account.uuid}>{account?.section?.name} {account.number}</Tag>)}
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        }
                        {!!entity && !!entityRatings && entityRatings.length > 0 &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Spin spinning={entityRatingsLoading}>
                                    <Card style={{marginTop: '20px'}}>
                                        <h3>Reseñas</h3>
                                        <RatingSummary
                                            total={entity.current_reviews_count}
                                            recommended={entity.current_recommended_count}
                                            showDescription={true}
                                            personName={entity?.first_name}
                                            size={18}
                                        />
                                        <CardSubtitle style={{marginTop: '40px'}}><span style={{color: 'var(--third-color)', fontWeight: '500'}}>Las más recientes</span></CardSubtitle>
                                        { entityRatings.map(rating =>
                                            <Review
                                                name={`${rating.author?.user?.first_name}`}
                                                imgSource={rating.author?.user?.profile_image?.url}
                                                reviewBody={rating.review_body}
                                                ratingValue={rating.rating_value}
                                                date={rating.updated_at}
                                                accountName={`${rating.account?.section?.name} ${rating.account?.number}`}
                                                key={rating.uuid}
                                            />)}
                                         <Button onClick={onSeeAllReviewsClick} className="ant-btn-form">Ver todas las reseñas</Button>
                                    </Card>
                                </Spin>
                            </Col>
                        </Row>
                        }
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Spin spinning={entityPasscodeLoading}>
                                    <Card style={{marginTop: '20px'}}>
                                        <h3>Código de acceso</h3>
                                        <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                                            Por seguridad se guardará un registro de los usuarios que vean el código de acceso de esta persona.
                                        </Description>
                                        {!entityPasscode ? <Button className="ant-btn-form" onClick={onViewEntityPasscodeClick}><LockOutlined/> Ver código de acceso</Button> : <ImportantNumber>{entityPasscode}</ImportantNumber>}
                                    </Card>
                                </Spin>
                            </Col>
                        </Row>
                        }
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card style={{marginTop: '20px'}}>
                                    <h3>Restricción de acceso</h3>
                                    <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                                        Esta funcionalidad te permite autorizar o restringir la entrada de {entity.first_name} a la residencial con su código de acceso.
                                    </Description>
                                    <EntityStatusButton
                                        successModalTitle="¿Permitir acceso a la residencial?"
                                        dangerModalTitle="¿Restringir acceso a la residencial?"
                                        dangerModalContent={() => <span>{entity.first_name} ya <b>NO</b> tendrá permitido el acceso a la residencial.</span>}
                                        successModalContent={() => <span>De ahora en adelante, {entity.first_name} tendrá permitido el acceso a la residencial.</span>}
                                        uuid={entity.uuid}
                                        defaultStatus={entity.status}
                                        successStatus={STAFF.STATUS.ACTIVE}
                                        dangerStatus={STAFF.STATUS.BANNED}
                                        modalSuccessStatusText="Permitir acceso"
                                        modalDangerStatusText="Restringir acceso"
                                        entity={ENTITY.TYPE.STAFF} />
                                </Card>
                            </Col>
                        </Row>
                        }
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <DeleteEntityButton
                                    modalTitle="¿Eliminar Staff?"
                                    modalContent={() => <span>Ya no podrás volver a ver sus datos ni modificarlo.</span>}
                                    uuid={entity.uuid}
                                    onSubmit={onDeleteSubmit}
                                    entity={ENTITY.TYPE.STAFF} block danger>
                                    Eliminar Staff
                                </DeleteEntityButton>
                            </Col>
                        </Row>
                        }
                    </EntityDetailContainer>
                    }
                </Spin>
            </Container>
        </EntityDetailWrapper>
    );
};

const EntityDetailWrapper = styled.div`
  .ant-rate-text {
    font-weight: 500;
    color: var(--unselected-text-color);
  }
 `;
const EntityDetailContainer = styled.div``;

export default StaffDetail;
