import styled from  "styled-components";

const CountTag = styled.div`
  font-size: 12px;
  background-color: var(--default-color);
  font-weight: 600;
  border-radius: 50%;
  padding-top: 2px;
  color: var(--unselected-text-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

export default CountTag;
