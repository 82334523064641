import React, { ChangeEvent, useState } from "react";
import { Input, InputProps, Select, SelectProps } from "antd";
import { getCountryList } from "../../utils/countries";
import "flag-icons/css/flag-icons.min.css";

export type PhoneInputProps = {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => {}
} & InputProps;
export type CountryCodeSelectPrefixProps = {} & SelectProps<string>;
const NUMBER_REGEX = "^[0-9]+$";

const countryList = getCountryList();

function renderFlag(countryCode: string) {
    return <span className={`fi fi-${countryCode.toLowerCase()}`}></span>
}

export const CountryCodeSelectPrefix: React.FC<CountryCodeSelectPrefixProps> = (props) => (
    <Select {...props} defaultValue={"52"}>
        {countryList.map(country => 
        <Select.Option key={`country-code-${country.code}`} value={country.dial_code}>
            {renderFlag(country.code)} +{country.dial_code}
        </Select.Option>)}
    </Select>
);

const PhoneInput: React.FC<PhoneInputProps> = ({
    onChange = () => {},
    ...props
}) => {
    const [value, setValue] = useState("");
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        let modifiedEvent = event;
        if(newValue.match(NUMBER_REGEX) || newValue === "") {
            setValue(newValue);
            modifiedEvent.target.value = newValue;
        }
        onChange(modifiedEvent);
    }
    return (
        <Input type="tel" value={value || ''} onChange={onInputChange} {...props} />
    );
};

export default PhoneInput;