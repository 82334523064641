import React from "react";
import styled from "styled-components";
import {Avatar} from "antd";
import PendingRequestActionButton from "./PendingRequestActionButton";

const PendingRequest = props => {
    const { member, onFinish } = props;
    const handleOnFinish = () => {
        onFinish();
    };
    return (
        <PendingRequestWrapper>
            <AvatarContainer>
                <Avatar size={48} src={member?.user?.profile_image?.url}>
                    {!!member.user && !!member.user.first_name && member.user?.first_name[0]}
                </Avatar>
            </AvatarContainer>
            <PendingRequestMemberContainer>
                <Title>
                    {member.user?.first_name} {member.user?.last_name} {member.user?.mother_surname}
                </Title>
                <Description>
                    {member.account?.section?.name} {member.account?.number} <span className="separator">|</span> {member.user?.phone}
                </Description>
            </PendingRequestMemberContainer>
            <QuickActionsContainer>
                <PendingRequestActionButton
                    minimized={true}
                    uuid={member.uuid}
                    onRequestFinish={handleOnFinish}
                    type="reject"
                />
                <PendingRequestActionButton
                    minimized={true}
                    uuid={member.uuid}
                    onRequestFinish={handleOnFinish}
                    type="approve"
                />
            </QuickActionsContainer>
        </PendingRequestWrapper>
    );
};

const PendingRequestWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 15px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  border: var(--container-border);
  @media(max-width: 768px) {
    display: block;
  }
`;
const PendingRequestMemberContainer = styled.div`
  margin-right: 15px;
`;
const AvatarContainer = styled.div`
  margin-right: 15px;
  @media(max-width: 768px) {
    display: block;
    margin: 0;
    span {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
const QuickActionsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 768px) {
    margin-top: 15px;
  }
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color);
  @media(max-width: 768px) {
    display: block;
    text-align: center;
  }
`;
const Description = styled.div`
  font-size: 13px;
  font-weight: 500;
  @media(max-width: 768px) {
    display: block;
    text-align: center;
  }
  .separator {
    color: var(--gray-color);
  }
`;
export default PendingRequest;
