import Api from "./Api";
import {MEMBER, MEMBER_REQUEST} from "../constants";
import {ClockCircleOutlined, MailOutlined} from "@ant-design/icons";
import React from "react";

export default class Member {
    getMembers = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/members', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getMemberByUuid = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/members/${uuid}`)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    updateMemberByUuid = (uuid, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/members/${uuid}`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    static getPendingRequests = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/members/pending-requests', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getPendingRequestByUuid = (uuid) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/member-requests/${uuid}`)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getPendingRequests = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/resident-requests', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    approveRequest = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/resident-requests/${MEMBER_REQUEST.STATUS.APPROVED}/${uuid}`)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    rejectRequest = (uuid, data = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/resident-requests/${MEMBER_REQUEST.STATUS.REJECTED}/${uuid}`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getByAccountUuid = accountUuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/members/account/${accountUuid}`)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    inviteMember = data => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.post(`/members`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    resendInvitationEmail = (uuid) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.post(`/members/${uuid}/re-send/invitation-email`, null, {})).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    deleteByUuid = (uuid, params) => {
    };
    getStatusColor = (status) => {
        switch (status) {
            case MEMBER.STATUS.ACTIVE:
                return "success";
            case MEMBER.STATUS.INVITE_PENDING:
                return "processing";
            case MEMBER.STATUS.INACTIVE:
                return "error";
            case MEMBER.STATUS.PENDING:
                return "warning";
            default:
                return "default";
        }
    };
    getStatusIcon = (status) => {
        switch (status) {
            case MEMBER.STATUS.ACTIVE:
            case MEMBER.STATUS.INACTIVE:
                return null;
            case MEMBER.STATUS.PENDING:
                return <ClockCircleOutlined/>;
            case MEMBER.STATUS.INVITE_PENDING:
                return <MailOutlined />;
            default:
                return null;
        }
    };
}

export const getMembershipsByResidentUuid = async (residentUuid, params = {}) => {
    try {
        return (await Api.get(`/members/resident/${residentUuid}`, params)).data;
    }
    catch(error) {
        throw error;
    }
};
export const getMembershipByUuid = async (uuid) => {
    try {
        return (await Api.get(`/members/${uuid}`)).data;
    }
    catch(error) {
        throw error;
    }
};
export const addMembership = async (data) => {
    try {
        return (await Api.post(`/members`, null, data)).data;
    }
    catch(error) {
        throw error;
    }
};
export const updateMembershipByUuid = async (uuid, data) => {
    try {
        return (await Api.put(`/members/${uuid}`, null, data)).data;
    }
    catch(error) {
        throw error;
    }
};
export const deleteMembershipByUuid = async (uuid) => {
    try {
        return (await Api.delete(`/members/${uuid}`)).data;
    }
    catch(error) {
        throw error;
    }
}