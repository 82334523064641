import Api from "./Api";

export function getStaffPasscode(uuid) {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/passcode/staff/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
}
export function getGuardPasscode(uuid) {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/passcode/guard/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
}
