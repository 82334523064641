import { Input } from "antd";
import React, {ChangeEvent, KeyboardEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import Spin from "../layout/Spin";

type VerificationCodeInputProps = {
    numberOfDigits?: 4|5|6|7|8,
    onChange?: (value: string[]) => void,
    onFinish?: (value: string) => void,
    disabled?: boolean,
    loading?: boolean
};
const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({
    numberOfDigits = 6,
    onChange = (digits: string[]) => {},
    onFinish = (digitsString: string) => {},
    disabled = false,
    loading = false,
    ...props
}) => {
    const [digits, setDigits] = useState<string []>(Array(numberOfDigits).fill(''));
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const nextInput: any = containerRef.current?.children[currentIndex];
        if(!!nextInput) {
            nextInput.focus();
            nextInput.select();
        }
    }, [currentIndex]);

    // avoid controlled vs uncontrolled component warning
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {};

    // subscribe to digit changes
    useEffect(() => {
        onChange(digits);

        // if last character was entered
        if(digits.filter(digit => digit !== '').length === numberOfDigits) {
            onFinish(digits.join(''));
        }
    //eslint-disable-next-line
    }, [digits]);

    const handleOnKeyUp = (event: KeyboardEvent) => {
        if(!disabled && !loading) {
            // if backspace aka delete
            if(event.key === 'Backspace' && currentIndex >= 0) {
                if(digits[currentIndex] === '' && currentIndex > 0) {
                    setCurrentIndex(currentIndex - 1);
                }
                else {
                    setDigits(prevDigits => {
                        prevDigits[currentIndex] = '';
                        return [...prevDigits];
                    });
                    if(currentIndex > 0) {
                        setCurrentIndex(currentIndex - 1);
                    }
                }
            }
            // if value is a number
            else if(isFinite(parseInt(event.key))) {
                if(currentIndex < numberOfDigits) {
                    // if currentIndex in array is last index AND is empty
                    if(!(currentIndex === (digits.length - 1) && digits[currentIndex] !== '')) {
                        const digit = event.key;
                        setDigits(prevDigits => {
                            prevDigits[currentIndex] = digit;
                            return [...prevDigits];
                        });
                        if(currentIndex < numberOfDigits - 1) {
                            setCurrentIndex(currentIndex + 1);
                        }
                    }
                }
            }
        }
    };
    
    return (
        <Spin spinning={loading}>
            <VerificationCodeDigitContainer ref={containerRef} {...props}>
                {digits.map( (digit, index) => 
                <VerificationCodeIndividualInput 
                    autoFocus={index === 0} 
                    onKeyUp={handleOnKeyUp} 
                    key={`verification-code-input-index${index}`} 
                    type="text" 
                    onChange={handleOnChange} 
                    value={digit || ''} 
                />)}
            </VerificationCodeDigitContainer>
        </Spin>
    );
};
export const VerificationCodeIndividualInput = styled(Input)`
    width: 50px;
    height: 50px;
    margin-right: 7px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    &:last-child {
        margin-right: 0;
    }
`;
export const VerificationCodeDigitContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledVerificationCodeInput = styled(VerificationCodeInput)`
    opacity: ${(p) => p.disabled ? 0.5 : 1};
`;

export default StyledVerificationCodeInput;