import React from "react";
import {Link} from "react-router-dom";

const LinkButton = props => {
    const {
        type = "primary"
    } = props;
    const btnClass = type === "primary" ? "ant-btn-primary" : "ant-btn-form";
    return <Link {...props} className={`ant-btn ${btnClass} ${props.className}`}>{props.children}</Link>
};

export default LinkButton;
