import Api from "./Api";
import {RESIDENT_REQUEST} from "../constants";

export const getAll = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/resident-requests', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const approveRequest = uuid => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/resident-requests/${uuid}/status/${RESIDENT_REQUEST.STATUS.APPROVED}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const rejectRequest = (uuid, data = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/resident-requests/${uuid}/status/${RESIDENT_REQUEST.STATUS.REJECTED}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getByUuid = (uuid) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/resident-requests/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const deleteByUuid = (uuid) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/resident-requests/${uuid}`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};