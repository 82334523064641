import * as types from "../actionTypes";
import {ROUTE} from "../../constants";

const managementReducer = (
    state = {
        currentManagementSectionPage: 0,
        currentManagementSection: ROUTE.MANAGEMENT.SECTIONS.RESIDENTS,
    },
    action
) => {
    switch (action.type) {
        case types.SET_CURRENT_MANAGEMENT_SECTION:
            return { ...state, currentManagementSection: action.currentManagementSection || ROUTE.MANAGEMENT.SECTIONS.RESIDENTS};
        case types.SET_CURRENT_MANAGEMENT_SECTION_PAGE:
            return { ...state, currentManagementSectionPage: action.currentManagementSectionPage || 0};
        default:
            return state;
    }
};

export default managementReducer;
