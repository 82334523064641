import React from "react";
import {Form, Button, Input, Row, Col, Select} from 'antd';
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import Entrance from "../utils/Entrance";
import UploadDropdown from "../components/layout/UploadDropdown";
import {ArrowLeftOutlined} from "@ant-design/icons";
import PhoneInput from "../components/layout/form/PhoneInput";

class GuardForm extends React.Component {
    formRef = React.createRef();
    state = {entrancesLoading: false, entrances: []};
    componentDidMount() {
        const {entity} = this.props;
        this.fetchEntrances().then(() => {
            if(!!entity) {
                if(!!this.formRef.current) {
                    this.formRef.current.setFieldsValue({
                        first_name: entity.first_name,
                        last_name: entity.last_name,
                        mother_surname: entity.mother_surname,
                        entrance_uuid: entity.entrance.uuid,
                        phone: entity.phone,
                        email: entity.email,
                    });
                }
            }
        });
    }
    fetchEntrances = async () => {
        this.setState({entrancesLoading: true});
        try {
            const entranceUtil = new Entrance();
            const entrances = (await entranceUtil.getEntrances()).data;
            this.setState({entrances: entrances});
        }
        catch(error) {
            console.log(error);
        }
        finally {
            this.setState({entrancesLoading: false});
        }
    };
    onFinish = values => {
        let data = {...values};
        data.phone = data.phone || null;
        data.email = data.email || null;
        data.profile_image = !!this.state.profileImage ? this.state.profileImage : null;
        data.id_image = !!this.state.idImage ? this.state.idImage : null;
        data.country_code = '52';
        this.props.onSubmit(data);
    };
    onCancel = () => {
        window.history.back();
    };
    onProfileImageChange = (values) => {
        this.setState({profileImage: values.imageUrl});
    };
    onIdImageChange = (values) => {
        this.setState({idImage: values.imageUrl});
    };
    render() {
        const {entity} = this.props;
        return (
            <React.Fragment>
                <UploadDropdown src={!!entity && entity.profile_image_url} title="Foto" onChange={this.onProfileImageChange} />
                <Form ref={this.formRef} layout={"vertical"} name="control-ref" onFinish={this.onFinish}>
                <Form.Item
                    name="first_name"
                    label="Nombre"
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="last_name"
                            label="Apellido paterno"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="mother_surname"
                            label="Apellido materno"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="phone"
                            label="Teléfono celular"
                        >
                            <PhoneInput />
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="email"
                            label="Correo electrónico"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="entrance_uuid"
                    label="Caseta"
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder="Selecciona una de las siguientes opciones"
                        allowClear
                        loading={this.state.entrancesLoading}
                    >
                        {this.state.entrances.map(entrance => <Select.Option key={entrance.uuid} value={entrance.uuid}>{entrance.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <UploadDropdown src={!!entity && entity.id_image_url} title="Identificación" onChange={this.onIdImageChange} />
                <FormActionsContainer>
                    <Button htmlType="button" className="ant-btn-form" onClick={this.onCancel}>
                        <ArrowLeftOutlined />
                        Regresar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={this.props.loading}>
                        Guardar
                    </Button>
                </FormActionsContainer>
            </Form>
            </React.Fragment>
        );
    }
}

GuardForm.defaultProps = {
    loading: false,
    entity: null
};

export default injectIntl(GuardForm);
