import Api from "./Api";

export const getAll = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/emergency-contact', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/emergency-contact/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const updateByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/emergency-contact/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const create = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/emergency-contact`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/emergency-contact/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
