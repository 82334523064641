import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Row} from "antd";
import {useParams} from "react-router-dom";
import App from "../utils/App";
import Api from "../utils/Api";
import {Spin} from "antd";
import Card from "../components/layout/Card";
import * as EntityUtil from  "../utils/EmergencyContact";
import Form from "../forms/EmergencyContactForm";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY} from "../constants";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

const EmergencyContactDetail = props => {
    const {uuid} = useParams();
    const [entity, setEntity] = useState(null);
    const [newEntity, setNewEntity] = useState(true);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const fetchEntity = async () => {
        setLoading(true);
        try {
            const entityData = await EntityUtil.getByUuid(uuid);
            setEntity(entityData);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if(!!uuid) {
            setNewEntity(false);
            fetchEntity().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = async data => {
        setSubmitLoading(true);
        try {
            if(newEntity) {
                await EntityUtil.create(data);
                App.showSuccess('El contacto se ha agregado correctamente');
            }
            else {
                await EntityUtil.updateByUuid(uuid, data);
                App.showSuccess('Contacto actualizado correctamente');
            }
            setSubmitLoading(false);
            window.history.back();
        }
        catch(error) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha eliminado correctamente el contecto de emergencia");
        window.history.back();
    };
    return (
        <EntityDetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={entity !== null ? `${entity?.name}` : (loading ? <LoadingOutlined /> : 'Nuevo Contacto de Emergencia')}
                subTitle={entity !== null ? `` : ``}
            />
            <Container>
                <Spin spinning={loading}>
                    {(!!entity || newEntity) &&
                    <EntityDetailContainer>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card>
                                    <Form loading={submitLoading} entity={entity} onSubmit={onSubmit} />
                                </Card>
                            </Col>
                        </Row>
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <DeleteEntityButton
                                    modalTitle="¿Eliminar Contacto de Emergencia?"
                                    modalContent={() => <span>Ya no podrás volver a ver sus datos ni modificarlo.</span>}
                                    uuid={entity?.uuid}
                                    onSubmit={onDeleteSubmit}
                                    entity={ENTITY.TYPE.EMERGENCY_CONTACT} block danger>
                                    Eliminar Contacto de Emergencia
                                </DeleteEntityButton>
                            </Col>
                        </Row>
                        }
                    </EntityDetailContainer>
                    }
                </Spin>
            </Container>
        </EntityDetailWrapper>
    );
};

const EntityDetailWrapper = styled.div``;
const EntityDetailContainer = styled.div``;

export default EmergencyContactDetail;
