import axios from 'axios';
import {AUTH} from "../constants";

class Api {
    static apiUrl = process.env.REACT_APP_API_URL;

    static getAxiosConfig() {
        const accessToken = localStorage.getItem(AUTH.ACCESS_TOKEN);
        return {
            headers:{'Authorization': `Bearer ${accessToken}`},
            timeout: 5000,
        };
    }
    static getAdminAxiosConfig() {
        const accessToken = localStorage.getItem(AUTH.ACCESS_TOKEN);
        return {
            headers:{'Authorization': `Bearer ${accessToken}`},
            timeout: 5000,
        };
    }
    static getAuthAxiosConfig() {
        return {
            timeout: 5000,
        };
    }
    static getUrl({path, params, url = this.apiUrl, scope = null}){
        if(path.includes("http://") || path.includes("https://")) {
            return path + (params !== null ? ("?" + this.encodeDataToURL(params)) : "");
        } else {
            try {
                let prefix = url;
                if(!!scope) {
                    prefix = `${prefix}/${scope}`; 
                }
                return prefix + path + (params !== null ? ("?" + this.encodeDataToURL(params)) : "");
            }
            catch(error) {
                throw error;
            }
        }
    }
    static getAxios(){
        return axios.create(this.getAxiosConfig());
    }
    static getAdminAxios() {
        return axios.create(this.getAdminAxiosConfig());
    }
    static getAuthAxios() {
        return axios.create(this.getAuthAxiosConfig());
    }
    static send(method, path, params = null, data = null){
        try {
            const clientScope = `${(JSON.parse(localStorage.getItem('client'))?.slug)}`;
            return this.getAxios()({
                method,
                url:this.getUrl({path,params,scope:clientScope}),
                data
            })
        }
        catch(error) {
            console.log(error);
        }
    }
    static adminSend(method, path, params = null, data = null){
        return this.getAdminAxios()({
            method,
            url:this.getUrl({path,params}),
            data
        })
    }
    static authSend(method, path, params = null, data = null){
        return this.getAuthAxios()({
            method,
            url:this.getUrl({path,params}),
            data
        })
    }
    static getNonAuthorizedAxios() {
        return axios.create({timeout: 5000, headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : 'Basic YWRtaW46Q3E1amk0am5ndw=='
            }});
    }
    static nonAuthorizedSend(method, path, params = null, data = null){
        if(method === 'post') {
            return this.getNonAuthorizedAxios()({
                method: method,
                url: this.getUrl({path,params}),
                data: data,
                transformRequest: [
                    //empty post PHP solution
                    //https://github.com/axios/axios/issues/1195#issuecomment-356143270
                    (data, headers) => {
                        const serializedData = [];
                        for (const k in data) {
                            if (data[k]) {
                                serializedData.push(`${k}=${encodeURIComponent(data[k])}`)
                            }
                        }
                        return serializedData.join('&');
                    }
                ]
            })
        }
        return this.getNonAuthorizedAxios()({
            method,
            url:this.getUrl({path,params}),
            data
        })
    }
    static nonAuthorizedPost(path, params = null, data){
        return this.nonAuthorizedSend('post', path, params, data);
    }
    static nonAuthorizedGet(path, params = null){
        return this.nonAuthorizedSend('get', path, params);
    }
    static get(path, params = null){
        return this.send('get', path, params);
    }
    static post(path, params = null, data){
        return this.send('post', path, params, data);
    }
    static put(path, params = null, data){
        return this.send('put', path, params, data);
    }
    static delete(path, data = null, params = null){
        return this.send('delete', path, params, data);
    }
    static adminGet(path, params = null){
        return this.adminSend('get', path, params);
    }
    static adminPost(path, params = null, data){
        return this.adminSend('post', path, params, data);
    }
    static adminPut(path, params = null, data){
        return this.adminSend('put', path, params, data);
    }
    static adminDelete(path, data = null, params = null){
        return this.adminSend('delete', path, params, data);
    }
    static authPost(path, params = null, data){
        return this.authSend('post', path, params, data);
    }
    static getEntity(entity, uuid){
        return this.get("/" + entity + "/" + uuid );
    }
    static postEntity(entity, data){
        return this.post("/" + entity, null, data);
    }
    static putEntity(entity, uuid, data){
        return this.put("/" + entity + "/" + uuid, null, data);
    }
    static encodeDataToURL(data) {
        return Object
            .keys(data)
            .map(value => `${value}=${encodeURI(data[value])}`)
            .join('&');
    }
    static parseResponseError(error) {
        try {
            if(error.response.data) {
                const err = error.response.data;
                if(err.error && Array.isArray(err.error)) {
                    return err.error[0].message;
                }
                else if(err.message) {
                    return err.message;
                }
            }
            if(error.response.status) {
                if(error.response.status === 401) {
                    return 'No estas autorizado para hacer esa petición';
                }
            }
            return 'A ocurrido un error desconocido';
        }
        catch(error) {
            return 'A ocurrido un error desconocido';
        }
    }
}

export default Api;