import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Avatar, Col, Row, Tag} from "antd";
import {useParams} from "react-router-dom";
import App from "../utils/App";
import Api from "../utils/Api";
import {Spin} from "antd";
import Card from "../components/layout/Card";
import * as EntityApi from "../utils/VisitInvitation";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY} from "../constants";
import Column from "../components/layout/Column";
import {injectIntl} from "react-intl";
import {getTimeZoneDate} from "../utils/Date";

const VisitInvitationDetail = props => {
    const {intl} = props;
    const {uuid} = useParams();
    const [entity, setEntity] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchEntity = async () => {
        setLoading(true);
        try {
            const entityData = await EntityApi.getByUuid(uuid);
            setEntity(entityData);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if(!!uuid) {
            fetchEntity().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha eliminado correctamente la caseta");
        window.history.back();
    };
    const getTitle = () => {
        if(entity?.company) {
            return `Pase de Entrada para ${entity.company?.name}`;
        }
        else if(entity?.company_name) {
            return `Pase de Entrada para ${entity.company_name}`;
        }
        else if(entity?.name) {
            return `Pase de Entrada para ${entity.name}`;
        }
        else {
            return "Pase de Entrada"
        }
    };
    const getCompanyName = () => {
        return (
            <React.Fragment>
                <Avatar style={{fontSize: "15px", backgroundColor: !!entity.company?.image?.url ? 'var(--default-color)' : 'var(--unselected-text-color)'}} size={30} src={entity.company?.image?.url}>
                    {!!entity.company?.name && entity.company?.name.length > 0 && entity.company?.name[0]}
                </Avatar>
                <b style={{marginLeft: '8px'}}>{entity.company?.name}</b>
            </React.Fragment>
        );
    };
    const getCreatedByAvatar = () => {
        if(!!entity?.Member) {
            return (
                <React.Fragment>
                    <Avatar style={{fontSize: "15px"}} size={30} src={entity?.Member?.user?.profile_image_url}>
                        {!!entity?.Member?.user?.first_name && entity?.Member?.user?.first_name.length > 0 && entity?.Member?.user?.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{entity?.Member?.user?.first_name} {entity?.Member?.user?.last_name}</b>
                </React.Fragment>
            );
        }
        else if(!!entity?.created_by) {
            return (
                <React.Fragment>
                    <Avatar style={{fontSize: "15px"}} size={30} src={entity.created_by?.user?.profile_image_url}>
                        {!!entity.created_by?.user?.first_name && entity.created_by?.user?.first_name.length > 0 && entity.created_by?.user?.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{entity.created_by?.user?.first_name} {entity.created_by?.user?.last_name}</b>
                </React.Fragment>
            );
        }
    };
    return (
        <EntityDetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={getTitle()}
                subTitle={entity !== null ? `` : ``}
            />
            <Container>
                <Spin spinning={loading}>
                    {(!!entity) &&
                    <EntityDetailContainer>
                        <Card>
                            <Row>
                                <Column title="Nombre" text={`${entity?.name}`} className="info-column" />
                                <Column title="Empresa" text={getCompanyName()} className="info-column" />
                                <Column title="Generado por" text={getCreatedByAvatar()} className="info-column" />
                            </Row>
                            <Row>
                                <Column title="Destino" text={<Tag>{entity.account?.section?.name} {entity.account?.number}</Tag>} className="info-column" />
                                <Column title="Estatus" text={<Tag color={App.getTagColorByStatus(entity.status, ENTITY.TYPE.VISIT_INVITATION)}>{intl.formatMessage({id:`visit-invitation.status.${entity.status}`})}</Tag>} className="info-column" />
                                <Column title="Vencimiento" text={!!entity.expires && `${getTimeZoneDate({date:entity?.expires})}`} className="info-column" />
                            </Row>
                            <Row>
                                <Column title="Fecha de creación" text={!!entity.created_at && `${getTimeZoneDate({date:entity?.created_at})}`} className="info-column" />
                            </Row>
                        </Card>
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <DeleteEntityButton
                                    modalTitle="¿Eliminar Pase de Entrada?"
                                    modalContent={() => <span>Ya no estará disponible.</span>}
                                    uuid={entity.uuid}
                                    onSubmit={onDeleteSubmit}
                                    entity={ENTITY.TYPE.VISIT_INVITATION} block danger>
                                    Eliminar Pase de Entrada
                                </DeleteEntityButton>
                            </Col>
                        </Row>
                        }
                    </EntityDetailContainer>
                    }
                </Spin>
            </Container>
        </EntityDetailWrapper>
    );
};

const EntityDetailWrapper = styled.div`
  .info-column {
    margin-bottom: 15px;
  }
`;
const EntityDetailContainer = styled.div``;

export default injectIntl(VisitInvitationDetail);
