import React from "react";
import styled from "styled-components";
import {Avatar, Col, Row} from "antd";
import Card from "./Card";
import RatingSummary from "./RatingSummary";

const Profile = (props) => {
    const {
        name = '',
        imageSource = null,
        attributes = [],
        showRating = false,
        totalRatings = 0,
        recommendedRatingsTotal = 0
    } = props;
    return (
        <ProfileWrapper {...props}>
            <Row>
                <Col xs={24}  sm={24} md={24} lg={24}>
                    <Card>

                        <ProfilePictureContainer>
                            <AvatarContainer>
                                <Avatar style={{fontSize: "50px"}} size={120} src={imageSource}>
                                    {!!name && name.length > 0 && name[0]}
                                </Avatar>
                            </AvatarContainer>
                        </ProfilePictureContainer>
                        <Row>
                            {!!showRating &&
                                <Col style={{marginBottom: '30px'}} xs={24}  sm={24} md={24} lg={24}>
                                    <RatingSummary size={18} personName={name} showDescription={true} recommended={recommendedRatingsTotal} total={totalRatings} />
                                </Col>
                            }
                            <Col xs={24}  sm={24} md={12} lg={8}>
                                <DetailsTitle>Nombre</DetailsTitle>
                                <DetailsDescription>{name}</DetailsDescription>
                            </Col>
                                {attributes.map(attribute =>
                                    <Col xs={24}  sm={24} md={12} lg={8} key={`profile-attribute-${attribute.title}`}>
                                        <DetailsTitle>{attribute.title}</DetailsTitle>
                                        <DetailsDescription>{attribute.description}</DetailsDescription>
                                    </Col>)
                                }
                        </Row>
                    </Card>
                </Col>
            </Row>
        </ProfileWrapper>
    );
};

const ProfileWrapper = styled.div``;
const DetailsTitle = styled.div`
  font-weight: 600;
  color: var(--unselected-text-color);
`;
const DetailsDescription = styled.div`
  font-weight: 500;
  margin-bottom: 15px;
`;
const ProfilePictureContainer = styled.div`
  margin: -100px 0 15px 0;
  display: flex;
  justify-content: flex-start;
`;
const  AvatarContainer =styled.div`
  display: inline-block;

  border: solid 10px white;
  border-radius: 50%;
`;

export default Profile;
