import React from "react";
import styled from "styled-components";
import {Avatar, Tag} from 'antd';
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import {PlusOutlined, SettingOutlined} from "@ant-design/icons";
import Container from "../components/layout/Container";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import LinkButton from "../components/layout/LinkButton";
import App from "../utils/App";
import RatingSummary from "../components/layout/RatingSummary";
import EntityDataTableActionsSubtitle from "../components/entity/EntityDataTableActionsSubtitle";

const Staff = props => {
    const {intl} = props;
    const columns = [
        {
            title: 'Nombre',
            ellipsis: true,
            render: (record, index) =>
                <React.Fragment>
                    <Avatar style={{fontSize: "18px"}} size={40} src={record.profile_image_url}>
                        {!!record.first_name && record.first_name.length > 0 && record.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{record.first_name} {record.last_name}</b>
                </React.Fragment>
        },
        {
            title: 'Teléfono',
            responsive: ['xxl'],
            dataIndex: 'phone'
        },
        {
            title: 'Correo electrónico',
            responsive: ['xxl'],
            dataIndex: 'email'
        },
        {
            title: 'Reseñas',
            responsive: ['md'],
            render: (record, index) => {
                if(record.current_reviews_count) {
                    return(
                        <AverageRating>
                            <RatingSummary total={record.current_reviews_count} recommended={record.current_recommended_count}/>
                        </AverageRating>);
                }
                return <span className="rating-empty-text">Sin reseñas</span>
            },
        },
        {
            title: 'Residencias',
            responsive: ['lg'],
            render: (record, index) => {
                if(!!record.accounts && record.accounts.length > 0) {
                    return (
                        <span>
                            {record.accounts.map((account, index) =>
                                <Tag style={{marginBottom: '5px', display:'inline-block'}} key={`staff-account-${account.uuid}`}>{account?.section?.name} {account.number}</Tag>)}
                        </span>
                    )
                }
                return <span> </span>;
            }
        },
        {
            title: 'Categoría',
            ellipsis: true,
            responsive: ['md'],
            render: (record, index) =>
                !!record.category ? <Tag>{record.category?.name}</Tag> : null
        },
        {
            title: 'Acceso',
            dataIndex: 'status',
            responsive: ['md'],
            render: status => (
                <Tag color={App.getTagColorByStatus(status, ENTITY.TYPE.STAFF)}>{intl.formatMessage({id:`staff.status.${status}`})}</Tag>
            ),
        },

    ];
    return (
        <Container>
            <EntityWrapper>
                <EntityDataTableActions>
                    <EntityDataTableActionsSubtitle>Administración de Staff</EntityDataTableActionsSubtitle>
                    <LinkButton type="default" to={`${ROUTE.ROUTES.STAFF_CATEGORIES}`} style={{marginRight: '15px'}}>
                        <SettingOutlined /> <span>Administrar Categorías</span>
                    </LinkButton>
                    <LinkButton to={`${ROUTE.ROUTES.STAFF}/${ROUTE.KEYWORDS.NEW}`}>
                        <PlusOutlined /> <span>Nuevo Staff</span>
                    </LinkButton>
                </EntityDataTableActions>
                <EntityContainer>
                    <EntityDataTable
                        showSearch={true}
                        searchPlaceholder="Busca por nombre, residencia, teléfono, o correo electrónico..."
                        entity={ROUTE.ROUTES.STAFF}
                        emptyTitle={'¡Aún no tienes ningun Staff!'}
                        emptyMessage={'Una vez que agregues Staff podrás ver su estatus, editarlos... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.STAFF} />
                </EntityContainer>
            </EntityWrapper>
        </Container>
    );
};

const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div`
  .rating-empty-text {
    color: var(--unselected-text-color);
  }
`;
const AverageRating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  .rating-text {
    display: block;
    font-weight: 600;
    margin-right: 4px;
  }
  & .anticon.anticon-star {
    display: block;
    color: #fadb14;
    font-size: 11px;
    margin-top: 5px;
  }
`;
export default injectIntl(Staff);
