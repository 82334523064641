import React from "react";
import PrivateRoute from "./PrivateRoute";
import {REGEX, ROUTE} from "../../constants";
import HomeSection from "../../pages/Home";
import Management from "../../pages/Management";
import MemberDetail from "../../pages/MemberDetail";
import AccountDetail from "../../pages/AccountDetail";
import GuardDetail from "../../pages/GuardDetail";
import EntranceDetail from "../../pages/EntranceDetail";
import StaffDetail from "../../pages/StaffDetail";
import SectionDetail from "../../pages/SectionsDetail";
import Visits from "../../pages/Visits";
import VisitsDetail from "../../pages/VisitsDetail";
import NoticeBoard from "../../pages/NoticeBoard";
import ResidentRequests from "../../pages/ResidentRequests";
import ResidentRequestDetail from "../../pages/ResidentRequestDetail";
import NoticeDetail from "../../pages/NoticeBoardDetail";
import StaffReviews from "../../pages/StaffReviews";
import StaffReviewDetail from "../../pages/StaffReviewDetail";
import AccountActivity from "../../pages/AccountActivity";
import VisitInvitations from "../../pages/VisitInvitations";
import StaffCategoryDetail from "../../pages/StaffCategoryDetail";
import EmergencyContactDetail from "../../pages/EmergencyContactDetail";
import EmergencyContactCategoryDetail from "../../pages/EmergencyContactCategoryDetail";
import StaffCategories from "../../pages/StaffCategories";
import EmergencyContactCategories from "../../pages/EmergencyContactCategories";
import Sections from "../../pages/Sections";
import Entrances from "../../pages/Entrances";
import VisitInvitationDetail from "../../pages/VisitInvitationDetail";
import Layout from "../layout/Layout";
// import { Redirect } from "react-router-dom";
import ResidentDetail from "../../pages/ResidentDetail";
import InviteResident from "../../pages/InviteResident";
import MyProfile from "../../pages/MyProfile";

const PaidUserRoutes = () => (
    <Layout>
        <PrivateRoute exact path={ROUTE.ROUTES.HOME} type="private">
            <HomeSection />
        </PrivateRoute>
        <PrivateRoute exact path={ROUTE.ROUTES.MANAGEMENT} type="private">
            <Management />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.MEMBER}/:uuid${REGEX.UUID}`} type="private">
            <MemberDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.RESIDENT}/:uuid${REGEX.UUID}`} type="private">
            <ResidentDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.RESIDENT_INVITE}`} type="private">
            <InviteResident />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.ACCOUNT}/:uuid${REGEX.UUID}`} type="private">
            <AccountDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.ACCOUNT}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <AccountDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.GUARD}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <GuardDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.GUARD}/:uuid${REGEX.UUID}`} type="private">
            <GuardDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.ENTRANCE}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <EntranceDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.ENTRANCE}/:uuid${REGEX.UUID}`} type="private">
            <EntranceDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <StaffDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF}/:uuid${REGEX.UUID}`} type="private">
            <StaffDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.SECTION}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <SectionDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.SECTION}/:uuid${REGEX.UUID}`} type="private">
            <SectionDetail />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ROUTES.VISITS} type="private">
            <Visits />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.VISIT}/:uuid${REGEX.UUID}`} type="private">
            <VisitsDetail />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ROUTES.NOTICE_BOARD} type="private">
            <NoticeBoard />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ROUTES.MEMBER_REQUESTS} type="private">
            <ResidentRequests />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.MEMBER_REQUEST}/:uuid${REGEX.UUID}`} type="private">
            <ResidentRequestDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.NOTICE}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <NoticeDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.NOTICE}/:uuid${REGEX.UUID}`} type="private">
            <NoticeDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF_REVIEWS}/:uuid${REGEX.UUID}`} type="private">
            <StaffReviews />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF_REVIEW}/:uuid${REGEX.UUID}`} type="private">
            <StaffReviewDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.ACCOUNT_ACTIVITY}`} type="private">
            <AccountActivity />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.VISIT_INVITATIONS}`} type="private">
            <VisitInvitations />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF_CATEGORY}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <StaffCategoryDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF_CATEGORY}/:uuid${REGEX.UUID}`} type="private">
            <StaffCategoryDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.EMERGENCY_CONTACT}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <EmergencyContactDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.EMERGENCY_CONTACT}/:uuid${REGEX.UUID}`} type="private">
            <EmergencyContactDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.EMERGENCY_CONTACT_CATEGORY}/${ROUTE.KEYWORDS.NEW}`} type="private">
            <EmergencyContactCategoryDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.EMERGENCY_CONTACT_CATEGORY}/:uuid${REGEX.UUID}`} type="private">
            <EmergencyContactCategoryDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.STAFF_CATEGORIES}`} type="private">
            <StaffCategories />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.EMERGENCY_CONTACT_CATEGORIES}`} type="private">
            <EmergencyContactCategories />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.SECTIONS}`} type="private">
            <Sections />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.ENTRANCES}`} type="private">
            <Entrances />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.VISIT_INVITATION}/:uuid${REGEX.UUID}`} type="private">
            <VisitInvitationDetail />
        </PrivateRoute>
        <PrivateRoute path={`${ROUTE.ROUTES.MY_PROFILE}`} type="private" >
            <MyProfile />
        </PrivateRoute>
        {/* Fix hotreload annoying redirect */}
        {/* <Redirect to={{ pathname:ROUTE.ROUTES.HOME }} /> */}
    </Layout>
);

export default PaidUserRoutes;
