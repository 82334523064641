import React, {useRef} from 'react';
import styled from "styled-components";
import {Tag, Avatar} from "antd";
import {injectIntl} from "react-intl";
import Container from "../components/layout/Container";
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import HeaderTitle from "../components/layout/HeaderTitle";
import PendingRequestActionButton from "../components/member/PendingRequestActionButton";
import {getTimeZoneDate} from "../utils/Date";

const ResidentRequests = () => {
    const entityTableRef = useRef();
    const columns = [
        {
            title: 'Nombre',
            render: (record, index) =>
                <React.Fragment>
                    <Avatar style={{fontSize: "18px"}} size={40} src={record.user?.profile_image?.url}>
                        {!!record.user?.first_name && record.user?.first_name.length > 0 && record.user.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{record.user?.first_name} {record.user?.last_name}</b>
                </React.Fragment>,
        },
        {
            title: 'Residencia',
            dataIndex: 'account',
            render: account => (
                <Tag>{account?.section?.name} {account?.number}</Tag>
            )
            ,
        },
        {
            title: 'Teléfono',
            dataIndex: 'user',
            responsive: ['lg'],
            render: user => (
                `${user.username}`
            )
            ,
        },
        {
            title: 'Fecha',
            dataIndex: 'updated_at',
            responsive: ['lg'],
            render: date => (
                <span className="table-datetime">{getTimeZoneDate({date:date})}</span>
            )
            ,
        },
        {
            title: 'Acciónes',
            responsive: ['md'],
            render: (text, record) => (
                <TableActionsContainer>
                    <PendingRequestActionButton
                        uuid={record?.uuid}
                        onRequestFinish={handleOnActionFinish}
                        type="reject"
                    />
                    <PendingRequestActionButton
                        uuid={record?.uuid}
                        onRequestFinish={handleOnActionFinish}
                        type="approve"
                    />
                </TableActionsContainer>
            ),
        },
    ];
    const handleOnActionFinish = () => {
        entityTableRef.current.update();
    };
    return (
        <Wrapper>
            <HeaderTitle>
                Solicitudes de Registro
            </HeaderTitle>
            <Container>
                <EntityWrapper>
                    <EntityContainer>
                        <EntityDataTable
                            ref={entityTableRef}
                            entity={ROUTE.ROUTES.RESIDENT_REQUEST}
                            emptyTitle={'¡No tienes Solicitudes de Registro!'}
                            emptyMessage={'Una vez que agregues Casetas podrás ver su estatus, asignarles guardias... ¡Y mucho más!'}
                            columns={columns}
                            entityName={ENTITY.TYPE.RESIDENT_REQUEST} />
                    </EntityContainer>
                </EntityWrapper>
            </Container>
        </Wrapper>
    );
};
const TableActionsContainer = styled.div`
    display: flex;
`;
const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;
const Wrapper = styled.div``;

export default injectIntl(ResidentRequests);
