import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Avatar, Col, Row, Spin} from "antd";
import {useParams} from "react-router-dom";
import * as ResidentRequest from "../utils/ResidentRequest";
import App from "../utils/App";
import Api from "../utils/Api";
import Card from "../components/layout/Card";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import PendingRequestActionButton from "../components/member/PendingRequestActionButton";
import {getTimeZoneDate} from "../utils/Date";

const ResidentRequestDetail = props => {
    const {uuid} = useParams();
    const [residentRequest, setresidentRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchResidentRequest = async () => {
        setLoading(true);
        try {
            const data = (await ResidentRequest.getByUuid(uuid));
            setresidentRequest(data);
            console.log(data)
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchResidentRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleOnActionFinish = async () => {
        window.history.back();
    };
    return (
        <DetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={residentRequest !== null ? `${residentRequest.user?.first_name} ${residentRequest.user?.last_name || ''} ${residentRequest.user?.mother_surname || ''}` : (loading ? <LoadingOutlined /> : 'Nuevo Residente')}
                subTitle=""
            />
            <Container>
                <Spin spinning={loading}>
                    {!!residentRequest &&
                    <React.Fragment>
                        <DetailContainer>
                            <Row>
                                <Col xs={24}  sm={24} md={24} lg={24}>
                                    <Card>
                                        <ProfilePictureContainer>
                                            <AvatarContainer>
                                                <Avatar style={{fontSize: "50px"}} size={120} src={residentRequest?.user?.profile_image?.url}>
                                                    {!!residentRequest?.user?.first_name && residentRequest?.user?.first_name.length > 0 && residentRequest?.user?.first_name[0]}
                                                </Avatar>
                                            </AvatarContainer>
                                        </ProfilePictureContainer>
                                        <Row>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailsTitle>Nombre</DetailsTitle>
                                                <DetailsDescription>{residentRequest.user?.first_name} {residentRequest.user?.last_name || ''} {residentRequest.user?.mother_surname || ''}</DetailsDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailsTitle>Teléfono</DetailsTitle>
                                                <DetailsDescription>+{residentRequest?.user?.country_code} {residentRequest.user?.phone}</DetailsDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailsTitle>Correo electrónico</DetailsTitle>
                                                <DetailsDescription>{residentRequest.user?.email}</DetailsDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailsTitle>Residencia</DetailsTitle>
                                                <DetailsDescription>{residentRequest.account?.section?.name} {residentRequest.account?.number}</DetailsDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailsTitle>Fecha</DetailsTitle>
                                                <DetailsDescription>{getTimeZoneDate({date:residentRequest?.created_at})}</DetailsDescription>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24}  sm={24} md={24} lg={24}>
                                                <ResidentDetailActionContainer>
                                                    <PendingRequestActionButton
                                                        uuid={residentRequest.uuid}
                                                        onRequestFinish={handleOnActionFinish}
                                                        type="reject"
                                                    />
                                                    <PendingRequestActionButton
                                                        uuid={residentRequest.uuid}
                                                        onRequestFinish={handleOnActionFinish}
                                                        type="approve"
                                                    />
                                                </ResidentDetailActionContainer>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </DetailContainer>
                    </React.Fragment>
                    }
                </Spin>
            </Container>
        </DetailWrapper>
    );
};

const DetailWrapper = styled.div``;
const DetailContainer = styled.div`
  margin-top: 85px;
`;
const DetailsTitle = styled.div`
  font-weight: 600;
  color: var(--unselected-text-color);
`;
const DetailsDescription = styled.div`
  font-weight: 500;
  margin-bottom: 15px;
`;
const ResidentDetailActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 15px;
  }
`;
const ProfilePictureContainer = styled.div`
  margin: -100px 0 35px 0;
  display: flex;
  justify-content: flex-start;
`;
const  AvatarContainer =styled.div`
  display: inline-block;

  border: solid 10px white;
  border-radius: 50%;
`;

export default ResidentRequestDetail;
