import React, {useState, useEffect} from "react";
import {Form, Button, Select, Input, Row, Col, Spin, Modal} from 'antd';
import App from "../utils/App";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import UploadDropdown from "../components/layout/UploadDropdown";
import {ArrowLeftOutlined} from "@ant-design/icons";
import * as api from "../utils/StaffCategories";
import Api from "../utils/Api";
import StaffCategoryForm from "./StaffCategoryForm";
import EmptyCategorySelectContent from "../components/layout/EmptyCategorySelectContent";
import useDebounce from "../hooks/useDebounce";

const { Option } = Select;

const StaffForm = (props) => {
    const {entity, loading, onSubmit} = props;
    const [profileImage, setProfileImage] = useState(null);
    const [idImage, setIdImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);

    const onFinish = values => {
        onSubmit({
            first_name: values.first_name,
            last_name: values.last_name,
            mother_surname: values.mother_surname,
            profile_image: !!profileImage ? profileImage : null,
            id_image: !!idImage ? idImage : null,
            phone: !!values.phone ? values.phone : null,
            email: !!values.email ? values.email : null,
            country_code: '52',
            category_uuid: !!values.category_uuid ? values.category_uuid : null,
        });
    };
    const onCancel = () => {
        window.history.back();
    };
    const onProfileImageChange = (values) => {
        setProfileImage(values.imageUrl);
    };
    const onIdImageChange = (values) => {
        setIdImage(values.imageUrl);
    };

    // category form logic
    const fetchCategories = async (params = null) => {
        setCategoriesLoading(true);
        setCategories([]);
        try {
            let data = (await api.getAll(params)).data;
            if(!!entity?.category) {
                data = data.filter(category => category.uuid !== entity?.category.uuid);
            }
            setCategories(data);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setCategoriesLoading(false);
        }
    };
    const DEFAULT_SEARCH_DELAY = 400;
    const [categoryModalVisible, setCategoryModalVisible] = useState(false);
    const [categoryFormLoading, setCategoryFormLoading] = useState(false);
    const [currentCategorySearchQuery, setCurrentCategorySearchQuery] = useState(null);
    const [currentCategorySearchValue, setCurrentCategorySearchValue] = useState(!!entity && !!entity?.category ? entity?.category.uuid : null);
    // delayed search fetch
    const debouncedSearch = useDebounce(() => {
        if(currentCategorySearchQuery !== null) {
            fetchCategories({q: currentCategorySearchQuery})
                .then(() => {})
                .catch(() => {});
        }
    }, DEFAULT_SEARCH_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(debouncedSearch, [currentCategorySearchQuery]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {let params = {pageSize: 100};fetchCategories(params);}, []);
    const onCategorySearch = (query) => {
        setCurrentCategorySearchQuery(query);
    };
    const onStaffCategoryFormSubmit = async (values) => {
        setCategoryFormLoading(true);
        try {
            const newCategory = (await api.create({
                ...values
            }));
            await fetchCategories({pageSize: 100, q: currentCategorySearchQuery});
            App.showSuccess('Se ha añadido correctamente la categoría.');
            setCurrentCategorySearchValue(newCategory.uuid);
            setCategoryModalVisible(false);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setCategoryFormLoading(false);
        }
    };
    const showCategoriesModal = () => {
        setCategoryModalVisible(true);
    };
    const hideCategoriesModal = () => {
        setCategoryModalVisible(false);
    };
    const onSearchCategoryChanged = (value) => {
        setCurrentCategorySearchValue(value);
    };

    return (
        <React.Fragment>
            <UploadDropdown src={!!entity && entity.profile_image_url} title="Foto" onChange={onProfileImageChange} />
            <Modal visible={categoryModalVisible} footer={null} onCancel={hideCategoriesModal}>
                <h2 style={{marginBottom: '30px'}}>Nueva Categoría</h2>
                <StaffCategoryForm
                    entity={{name: currentCategorySearchQuery}}
                    showCancelButton={true}
                    cancelText="Cancelar"
                    onCancel={hideCategoriesModal}
                    loading={categoryFormLoading}
                    onSubmit={onStaffCategoryFormSubmit} />
            </Modal>
            <Form layout={"vertical"}
                  initialValues = {!!entity ? {
                      first_name: entity.first_name,
                      last_name: entity.last_name,
                      mother_surname: entity.mother_surname,
                      type: entity.type,
                      phone: entity.phone,
                      email: entity.email,
                      category_uuid: entity.category?.uuid,
                  } : {}}
                  name="control-ref"
                  onFinish={onFinish}>
                <Form.Item
                    name="first_name"
                    label="Nombre"
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="last_name"
                            label="Apellido paterno"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="mother_surname"
                            label="Apellido materno"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="phone"
                            label="Teléfono celular"
                        >
                            <Input
                                prefix="+52"
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="email"
                            label="Correo electrónico"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="category_uuid"
                    label="Categoría"
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder="Busca una categoría"
                        showSearch
                        onSearch={onCategorySearch}
                        filterOption={false}
                        value={currentCategorySearchValue}
                        notFoundContent={categoriesLoading ? <Spin /> : <EmptyCategorySelectContent onAddButtonClick={showCategoriesModal} />}
                        showArrow={false}
                        loading={categoriesLoading}
                        onChange={onSearchCategoryChanged}
                    >
                        {categories.length > 0 && categories.map(category =>
                            <Option key={`${category.uuid}-category`} value={category.uuid}>{category.name}</Option>)
                        }
                        {!!entity && !!entity.category && !currentCategorySearchQuery &&
                            <Option key={`${entity?.category?.uuid}-category-entity`}
                                    value={entity?.category?.uuid}>
                                {entity?.category?.name}
                            </Option>
                        }
                    </Select>
                </Form.Item>
                <UploadDropdown src={!!entity && entity.id_image_url} title="Identificación" onChange={onIdImageChange} />
                <FormActionsContainer>
                    <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                        <ArrowLeftOutlined />
                        Regresar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Guardar
                    </Button>
                </FormActionsContainer>
            </Form>
        </React.Fragment>

    );
};

StaffForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(StaffForm);
