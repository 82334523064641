import React from "react";
import styled from "styled-components";
import {Avatar} from "antd";
import {Link} from "react-router-dom";
import {ROUTE} from "../../constants";

const MemberListRow = props => {
    const { member } = props;
    return (
        <Link to={`${ROUTE.ROUTES.RESIDENT}/${member?.resident?.uuid}`}>
            <MemberListRowWrapper>
                <AvatarContainer>
                    <Avatar size={48} src={member?.resident?.user?.profile_image?.url}>
                        {member?.resident?.user?.first_name[0]}
                    </Avatar>
                </AvatarContainer>
                <MemberListRowContainer>
                    <Title>
                        {member?.resident?.user?.first_name} {member?.resident?.user?.last_name} {member?.resident?.user?.mother_surname}
                    </Title>
                    <Description>
                        {member?.account?.section?.name} {member?.account?.number}
                    </Description>
                </MemberListRowContainer>
                <QuickActionsContainer>

                </QuickActionsContainer>
            </MemberListRowWrapper>
        </Link>
    );
};

const MemberListRowWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 13px 23px;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--text-color);
  border: 2px solid transparent;
  transition: background-color ease-in 0.2s;
  max-width: 400px;
  border: var(--container-border);
  &:hover {
    cursor: pointer;
    background-color: var(--contrast-background-color);
  }
  @media(max-width: 800px) {
    display: block;
  }
`;
const MemberListRowContainer = styled.div`
  margin-right: 15px;
`;
const AvatarContainer = styled.div`
  margin-right: 15px;
  @media(max-width: 800px) {
    display: block;
    margin: 0;
    span {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
const QuickActionsContainer = styled.div`
  margin-left: auto;
  button{
    margin-right: 12px;
  }
  @media(max-width: 800px) {
    display: block;
    text-align: center;
  }
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color);
  @media(max-width: 800px) {
    display: block;
    text-align: center;
  }
`;
const Description = styled.div`
  font-size: 13px;
  font-weight: 500;
  @media(max-width: 800px) {
    display: block;
    text-align: center;
  }
`;
export default MemberListRow;
