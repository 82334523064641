import Api from "./Api";

export default class Parcel {
    getParcels = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/parcels', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getByUuid = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/parcels/${uuid}`)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    updateByUuid = (uuid, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/parcels/${uuid}`, null, data)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    create = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.post(`/parcels`, null, data)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    deleteByUuid = (uuid, params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.delete(`/parcels/${uuid}`, params)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
}
