import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion"

import Logo from "../../assets/images/main/logo-app-2.svg";

const SplashScreen = props => {
    return (
        <SplashScreenWrapper>
            <motion.img
                animate={{ scale: 1.1, opacity: 0.5 }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 0.4,
                            ease: "easeInOut"
                        }}
                        alt="Hausly Manager" style={{width: '250px'}} src={Logo} />
        </SplashScreenWrapper>
    );
};

SplashScreen.defaultProps = {
    isVisible: false
};

const SplashScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  background: var(--default-color);
`;

export default SplashScreen;
