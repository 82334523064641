import Api from "./Api";

export const getAll = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/staff-ratings', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getAllByStaffUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/staff-ratings/staff/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/staff-ratings/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const updateByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/staff-ratings/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const create = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/staff-ratings`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/staff-ratings/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
