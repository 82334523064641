import React from "react";
import styled from "styled-components";
import { Button } from "antd";

export interface IProviderLoginButton {
    icon: React.ReactNode,
    name: string,
    onClick?: React.MouseEventHandler<HTMLElement>,
    style?: React.CSSProperties,
    block?: boolean
};

const ProviderLoginButton: React.FC<IProviderLoginButton> = ({
    onClick = () => {},
    style = {},
    block = true,
    ...props
}: IProviderLoginButton) => {
    return (
        <Button type="default" className="ant-btn-form" onClick={onClick} style={style} block={block}>
            <ProviderLoginButtonContent>
                {props.icon} <ProviderLoginButtonText>Continuar con {props.name}</ProviderLoginButtonText>
            </ProviderLoginButtonContent>
        </Button>
    );
};

export default ProviderLoginButton;

export const ProviderLoginButtonContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
export const ProviderLoginButtonIcon = styled.div`
    margin-right: auto;
`;
export const ProviderLoginButtonText = styled.div`
    font-weight: 600;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const ProviderLoginIconImage = styled.img`
    width: 18px;
    height: 18px;
    display: block;
`;

