import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PendingRequest from "./PendingRequest";
import {Spin, Pagination} from "antd";
import App from "../../utils/App";
import Api from "../../utils/Api";
import Member from "../../utils/Member";
import Lottie from "react-lottie";
import emptyRequestsAnimation from "../../assets/lottie/empty/19243-phone-book";
import {fetchCurrentMemberRequestsCount} from "../../redux/actions/notificationsActions";
import {connect} from "react-redux";

const memberUtils = new Member();

const PendingRequestsList = props => {
    const {lengthBeforePaging, fetchNotificationsCount} = props;
    const [pendingRequests, setPendingRequests] = useState([]);
    const [loadingPendingRequests, setLoadingPendingRequests] = useState(true);
    const handleOnApproveOrRejectFinish = async () => {
        await fetchPendingRequests();
        fetchNotificationsCount();
    };
    useEffect(() => {
        fetchPendingRequests().then();
    }, []);
    const fetchPendingRequests = async () => {
        setLoadingPendingRequests(true);
        try {
            const pendingRequests = (await memberUtils.getPendingRequests()).data;
            setPendingRequests(pendingRequests);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoadingPendingRequests(false);
        }
    };
    return (
        <PendingRequestsListWrapper>
            <Spin spinning={loadingPendingRequests}>
                {pendingRequests.length > 0 ?
                    <PendingRequestListContainer>
                        {pendingRequests.map(member =>
                            <PendingRequest
                                onFinish={handleOnApproveOrRejectFinish}
                                key={member.uuid}
                                member={member}/>)}
                        {pendingRequests.length > lengthBeforePaging &&
                        <PagintionContainer>
                            <Pagination defaultCurrent={1} total={50} />
                        </PagintionContainer>
                        }
                    </PendingRequestListContainer> :
                    !loadingPendingRequests ?
                        <EmptyListContainer>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: emptyRequestsAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                width={250}
                            />
                            <h3>¡No tienes solicitudes pendientes!</h3>
                        </EmptyListContainer> : null
                }
            </Spin>
        </PendingRequestsListWrapper>
    );
};

const PendingRequestsListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;
const PagintionContainer = styled.div`
  margin-top: 15px;
`;
const EmptyListContainer = styled.div`
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  padding: 25px;
`;
const PendingRequestListContainer = styled.div``;
PendingRequestsList.defaultProps = {
  lengthBeforePaging: 5
};

const mapStateToProps = state => ({
    ...state
});
const mapDispatchToProps = dispatch => ({
    fetchNotificationsCount: () => dispatch(fetchCurrentMemberRequestsCount)
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingRequestsList);
