import { Avatar, Dropdown, Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';
import {setAuthClient, setAuthResidentships, setAuthResident} from "../../../redux/actions/authActions";
import {SwapOutlined, LogoutOutlined} from "@ant-design/icons/lib/icons";
import App from "../../../utils/App";
import Auth from "../../../utils/Auth";
import {ROUTE} from "../../../constants";
import { useAppSelector } from '../../../hooks/useAppSelector';

const authUtils = new Auth();
const LOGOUT_KEY = "logout";
const SWITCH_ACCOUNTS_KEY = "switchAccounts";

const ProfileActions = ({name = "", imageUrl = null, showAccountSwapAction = true}) => {
    const accountMenu = (
        <Menu onClick={async ({key}) => {
            switch (key) {
                case SWITCH_ACCOUNTS_KEY:
                    try {
                        setAuthClient(null);
                        setAuthResident(null);
                        setAuthResidentships(null);
                        await authUtils.residentLogout();
                        window.location.href = ROUTE.ROUTES.ACCOUNT_SELECT;
                    }
                    catch(error: any) {
                        App.showError(error.toString());
                    }
                    return;
                case LOGOUT_KEY:
                    try {
                        await authUtils.signout();
                        window.location.href = ROUTE.ROUTES.LOGIN;
                    }
                    catch(error: any) {
                        App.showError(error.toString());
                    }
                    return;
                default:
                    return;
            }
        }}>
            {showAccountSwapAction &&
                <Menu.ItemGroup>
                    <Menu.Item key={SWITCH_ACCOUNTS_KEY}>
                        <SwapOutlined/> Cambiar de residencial
                    </Menu.Item>
                </Menu.ItemGroup>
            }
            <Menu.ItemGroup>
                <Menu.Item key={LOGOUT_KEY}>
                    <LogoutOutlined/> Cerrar sesión
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );
    return (
        <Dropdown overlay={accountMenu} trigger={['click']}>
            <ProfileActionsWrapper>
                <Avatar style={{fontSize: "18px"}} size={40} src={imageUrl}>
                    {!!name && name.length > 0 && name[0]}
                </Avatar>
            </ProfileActionsWrapper>
        </Dropdown>
    );
};
type HeaderProps = {
    actionsComponent?: React.ReactNode,
    showUserProfile?: boolean,
    containerStyle?: React.CSSProperties,
}
const Header: React.FC<HeaderProps> = (props) => {
    const {
        actionsComponent = null, 
        showUserProfile = true,
        containerStyle = {},
    } = props;
    const auth = useAppSelector(state => state.auth);
    return (
        <HeaderWrapper style={containerStyle}>
            <HeaderContainer>
                <LeftContainer>
                    {props.children}
                </LeftContainer>
                <RightContainer>
                {showUserProfile &&
                    <ProfileActions 
                        name={auth?.user?.first_name || "User"} 
                        imageUrl={auth?.user?.profile_image?.url}
                        showAccountSwapAction={!!auth?.client}
                    />
                }
                {actionsComponent}
                </RightContainer>
            </HeaderContainer>
        </HeaderWrapper>
    )
};

export default Header;

const ProfileActionsWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const LeftContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const RightContainer = styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
  width: 100%;
  padding: 0 var(--container-padding);
  height: var(--header-height);
  display: flex;
  align-items: center;
  align-content: center;
  //box-shadow: var(--card-shadow);
  border-bottom: var(--container-border);
`;
