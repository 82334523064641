import React from "react";
import styled from "styled-components";
import {ENTITY, ROUTE, VISIT} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {Avatar, Tag} from "antd";
import {injectIntl} from "react-intl";
import Container from "../components/layout/Container";
import HeaderTitle from "../components/layout/HeaderTitle";
import * as VisitUtil from "../utils/Visit";
import LinkButton from "../components/layout/LinkButton";
import {QrcodeOutlined} from "@ant-design/icons";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import {getTimeZoneDate} from "../utils/Date";

const EmptyColumn = () => <EmptyColumnWrapper></EmptyColumnWrapper>;
const Visits = props => {
    const {intl} = props;
    const columns = [
        {
            title: 'Visitante',
            render: (text, record) => {
                if(record?.type === VISIT.TYPE.DELIVERY && !record?.name) {
                    return <b>{intl.formatMessage({id: `visit.type.${record?.type}`})}</b>
                }
                return (<b>{!!record?.name ? record.name : <EmptyColumn />}</b>)
            }
        },
        {
            title: 'Destino',
            dataIndex: 'account',
            responsive: ['md'],
            render: (text, record) => {
                if(!!record?.destination && Array.isArray(record?.destination) && record?.destination?.length > 0) {
                    return record?.destination.map(destination => <Tag>{destination?.account?.section?.name} {destination?.account?.number}</Tag>)
                }
                else if(!!record?.account) {
                    return <Tag>{record?.account?.section?.name} {record?.account?.number}</Tag>;
                }
                return <EmptyColumn />;
            },
        },
        {
            title: 'Empresa',
            responsive: ['lg'],
            ellipsis: true,
            render: (record, index) => {
                if(!!record?.company) {
                    return <React.Fragment>
                        <Avatar style={{fontSize: "15px", backgroundColor: !!record?.company?.image?.url ? 'var(--default-color)' : 'var(--unselected-text-color)'}} size={30} src={record?.company?.image?.url}>
                            {!!record.company?.name && record?.company?.name.length > 0 && record?.company?.name[0]}
                        </Avatar>
                        <b style={{marginLeft: '8px'}}>{record?.company?.name}</b>
                    </React.Fragment>;
                }
                else if(!!record?.company_name) {
                    return record?.company_name
                }
                return <EmptyColumn />
            }
        },
        {
            title: 'Placas',
            dataIndex: 'place_number',
            responsive: ['xxl'],
            render: plate_number => (!!plate_number ? plate_number : <EmptyColumn />)
        },
        {
            title: 'Entrada',
            dataIndex: 'check_in',
            responsive: ['md'],
            render: date => (
                <span className={'table-datetime'}>
                    {!!date ? getTimeZoneDate({date:date}) : <EmptyColumn />}
                </span>
            )
        },
        {
            title: 'Salida',
            dataIndex: 'check_out',
            responsive: ['md'],
            render: date => (
                <span className={'table-datetime'}>
                    {!!date ? getTimeZoneDate({date:date}) : <EmptyColumn />}
                </span>
            )
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            responsive: ['lg'],
            render: status => (
                <span>
                    <Tag>{intl.formatMessage({id:`visit.type.${status}`})}</Tag>
                </span>
            ),
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            render: status => (
                <span>
                    <Tag
                        icon={VisitUtil.getStatusIcon(status)}
                        color={VisitUtil.getStatusColor(status)}>
                        {intl.formatMessage({id:`visit.status.${status}`})}
                    </Tag>
                </span>
            ),
        }
    ];
    return (
        <VisitsWrapper>
            <HeaderTitle>
                Visitas
            </HeaderTitle>
            <Container>
                <VisitsContainer>
                    <EntityDataTableActions>
                        <LinkButton type="default" to={`${ROUTE.ROUTES.VISIT_INVITATIONS}`} style={{marginRight: '15px'}}>
                            <QrcodeOutlined /> <span>Administrar Pases de Entrada</span>
                        </LinkButton>
                    </EntityDataTableActions>
                    <EntityDataTable
                        showSearch={true}
                        searchPlaceholder="Busca por nombre, residencia, teléfono, placas, o empresa..."
                        entity={ROUTE.ROUTES.VISIT}
                        emptyTitle={'¡No hay visitas!'}
                        emptyMessage={'Podrás ver su estatus, fecha de llegada, fecha de salida... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.VISITS} />
                </VisitsContainer>
            </Container>
        </VisitsWrapper>
    );
};

const VisitsWrapper = styled.div`
`;
const VisitsContainer = styled.div``;
const EmptyColumnWrapper = styled.div`
  &:before {
    color: var(--unselected-text-color);
    content: '-';
  }
`;
export default injectIntl(Visits);
