import React from "react";
import styled from "styled-components";
import Accounts from "./Accounts";
import {connect} from "react-redux";
import {setCurrentSection} from "../redux/actions/managementActions";
import {ROUTE} from "../constants";
import Guards from "./Guards";
import Entrances from "./Entrances";
import { Menu } from 'antd';
import HeaderMenu from "../components/layout/HeaderMenu";
import HeaderTitle from "../components/layout/HeaderTitle";
import Staff from "./Staff";
import { useHistory } from "react-router-dom";
import EmergencyContacts from "./EmergencyContacts";
import Residents from "./Residents";

const Management = props => {
    let history = useHistory();

    const {setCurrentSection, management} = props;
    const handleClick = e => {
        // clear search params before component unmount (EntityTable)
        history.push({search: ''});

        setCurrentSection(e.key);
    };
    const renderSection = (key = management.currentManagementSection) => {
        switch (key) {
            case ROUTE.MANAGEMENT.SECTIONS.RESIDENTS:
                return <Residents />;
            case ROUTE.MANAGEMENT.SECTIONS.ACCOUNTS:
                return <Accounts/>;
            case ROUTE.MANAGEMENT.SECTIONS.GUARDS:
                return <Guards/>;
            case ROUTE.MANAGEMENT.SECTIONS.ENTRANCES:
                return <Entrances/>;
            case ROUTE.MANAGEMENT.SECTIONS.STAFF:
                return <Staff/>;
            case ROUTE.MANAGEMENT.SECTIONS.EMERGENCY_CONTACT:
                return <EmergencyContacts/>;
            default:
                return <Residents />;
        }
    };
    return (
        <ManagementWrapper>
            <HeaderTitle>
                Administración de Residencial
            </HeaderTitle>
            <HeaderMenu onClick={handleClick} selectedKeys={[management.currentManagementSection]} mode="horizontal">
                    <Menu.Item key={ROUTE.MANAGEMENT.SECTIONS.RESIDENTS}>
                        Residentes
                    </Menu.Item>
                    <Menu.Item key={ROUTE.MANAGEMENT.SECTIONS.ACCOUNTS}>
                        Residencias
                    </Menu.Item>
                    <Menu.Item key={ROUTE.MANAGEMENT.SECTIONS.GUARDS}>
                        Guardias
                    </Menu.Item>
                    <Menu.Item key={ROUTE.MANAGEMENT.SECTIONS.STAFF}>
                        Staff
                    </Menu.Item>
                    <Menu.Item key={ROUTE.MANAGEMENT.SECTIONS.EMERGENCY_CONTACT}>
                        Contactos
                    </Menu.Item>
            </HeaderMenu>
            {renderSection(management.currentManagementSection)}
        </ManagementWrapper>
    );
};

const ManagementWrapper = styled.div``;

const mapStateToProps = state => ({
    ...state
});
const mapDispatchToProps = dispatch => ({
    setCurrentSection: section => dispatch(setCurrentSection(section))
});

export default connect(mapStateToProps, mapDispatchToProps)(Management);
