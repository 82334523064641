import React from "react";
import styled from "styled-components";

const Card = props => {
    const {children, style = {}} = props;
    return(
        <CardWrapper style={style}>
            {children}
        </CardWrapper>
    )
};

const CardWrapper = styled.div`
  background: var(--card-background);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  border: var(--container-border);
`;

export default Card;
