import React, {useState} from "react";
import {Form, Button, Input, Select} from 'antd';
import App from "../utils/App";
import {NOTICE_BOARD} from "../constants";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import UploadDropdown from "../components/layout/UploadDropdown";
import {ArrowLeftOutlined} from "@ant-design/icons";

const { TextArea } = Input;

const NoticeBoardForm = props => {
    const {entity, intl, loading, onSubmit} = props;
    const [attachmentFile, setAttachmentFile] = useState(null);
    const onFinish = values => {
        onSubmit({
            title: values.title,
            description: values.description,
            status: values.status || NOTICE_BOARD.STATUS.ACTIVE,
            file_attachment: !!attachmentFile && !!attachmentFile.url ? attachmentFile.url : null,
            file_attachment_name: !!attachmentFile && !!attachmentFile.fileName ? attachmentFile.fileName : null,
            type: NOTICE_BOARD.TYPE.ANNOUNCEMENT
        });
    };
    const onCancel = () => {
        window.history.back();
    };
    const onAttachmentChange = (values) => {
        setAttachmentFile({url: values.imageUrl, fileName: values.fileName});
    };
    return (
        <Form
            layout={"vertical"}
            initialValues = {!!entity ? {
                description: entity.description,
                title: entity.title,
                type: entity.type,
            } : {}}
            name="control-ref"
            onFinish={onFinish}>
            <Form.Item
                name="title"
                label="Título"
                rules={[{required: true}]}
            >
                <Input
                />
            </Form.Item>
            <Form.Item
                name="description"
                label="Descripción"
                rules={[{required: true}]}
            >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            {!!entity &&
            <Form.Item
                name="type"
                label="Tipo"
                rules={[{required: true}]}
            >
                <Select
                    placeholder="Selecciona una de las siguientes opciones"
                    allowClear
                >
                    {App.getCatalogListAsArray(NOTICE_BOARD.TYPE).map(
                        type =>
                            <Select.Option key={`notice-board-type-${type}`}
                                    value={type}>{intl.formatMessage({id:`notice-board.type.${type}`})}
                            </Select.Option>
                    )}
                </Select>
            </Form.Item>
            }
            <UploadDropdown type="file" fileName={!!entity && entity.file_attachment_name} src={!!entity && entity.file_attachment_url} title="Documento adjunto" onChange={onAttachmentChange} />
            <FormActionsContainer>
                <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                    <ArrowLeftOutlined />
                    Regresar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Publicar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

NoticeBoardForm.defaultProps = {
    loading: false,
    entity: null
};

export default injectIntl(NoticeBoardForm);
