import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import {Tag, Avatar, Spin} from "antd";
import {injectIntl} from "react-intl";
import Container from "../components/layout/Container";
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {useParams} from "react-router-dom";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import DetailHeader from "../components/layout/DetailHeader";
import * as StaffRating from "../utils/StaffRating";
import * as Staff from "../utils/Staff";
import Profile from "../components/layout/Profile";
import App from "../utils/App";
import Api from "../utils/Api";
import Rating from "../components/layout/Rating";
import {getTimeZoneDate} from "../utils/Date";

const StaffReviews = () => {
    const {uuid} = useParams();
    const [entity, setEntity] = useState(null);
    const [entityLoading, setEntityLoading] = useState(false);
    const fetchEntity = async () => {
        setEntityLoading(true);
        try {
            const entityData = (await Staff.getByUuid(uuid));
            setEntity(entityData);
        }
        catch(error) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setEntityLoading(false);
        }
    };
    useEffect(() => {
        fetchEntity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columns = [
        {
            title: 'Nombre',
            render: (record, index) =>
                <React.Fragment>
                    <Avatar style={{fontSize: "18px"}} size={40} src={record.author?.user?.profile_image?.url}>
                        {!!record.author?.user?.first_name && record.author?.user?.first_name.length > 0 && record.author?.user.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{record.author?.user?.first_name} {record.author?.user?.last_name}</b>
                </React.Fragment>,
        },
        {
            title: 'Reseña',
            responsive: ['md'],
            render: (record, index) => <Rating rating={record.rating_value} />
        },
        {
            title: 'Opinión',
            responsive: ['lg'],
            ellipsis: true,
            dataIndex: 'review_body',
            render: (review_body) => review_body
        },
        {
            title: 'Residencia',
            dataIndex: 'account',
            render: account => (
                <Tag>{account?.section?.name} {account.number}</Tag>
            )
            ,
        },
        {
            title: 'Fecha',
            dataIndex: 'date_updated',
            responsive: ['lg'],
            render: date => (
                <span className="table-datetime">{getTimeZoneDate({date:date})}</span>
            )
            ,
        },
    ];
    return (
        <Wrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={entity !== null ? `Reseñas de ${entity?.first_name} ${entity?.last_name}` : (entityLoading ? <LoadingOutlined /> : 'Reseñas')}
            />
            <Container>
                <EntityWrapper>
                    <EntityContainer>
                        {!!entity &&
                        <Spin spinning={entityLoading}>
                            <Profile
                                style={{margin: '85px 0 25px 0', minHeight: '300px'}}
                                name={`${entity?.first_name} ${entity?.last_name}  ${entity?.mother_surname}`}
                                imageSource={entity?.profile_image_url}
                                attributes={[]}
                                totalRatings={entity.current_reviews_count || 0}
                                recommendedRatingsTotal={entity.current_recommended_count || 0}
                                showRating={true}
                            />
                        </Spin>
                        }
                        <EntityDataTable
                            entity={ROUTE.ROUTES.STAFF_REVIEW}
                            emptyTitle={'¡Esta persona aún no tiene reseñas!'}
                            emptyMessage={'Una vez que tenga reseñas podrás ver el autor, la fecha, comentarios... ¡Y mucho más!'}
                            columns={columns}
                            entityFetchOpts={{
                                fetchEntity: (params) => StaffRating.getAllByStaffUuid(uuid, params)
                            }}
                            entityName={ENTITY.TYPE.STAFF_REVIEWS} />
                    </EntityContainer>
                </EntityWrapper>
            </Container>
        </Wrapper>
    );
};
const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;
const Wrapper = styled.div``;

export default injectIntl(StaffReviews);
