import moment from "moment-timezone";
import {DATE} from "../constants";

export function getTimeZoneDate(
    {
        date,
        format = DATE.FORMAT.DATE_TIME_HUMANIZED_SHORT,
        locale = "es-us",
        calendar = false,
        calendarIsDateTime = false
    }) {
    if(calendar) {
        if(calendarIsDateTime) {
            moment(date).locale(locale).calendar({
                sameDay: '[Hoy]',
                nextDay: '[Mañana]',
                nextWeek: 'DD [de] MMM YYYY',
                lastDay: '[Ayer a las] h:mm A',
                lastWeek: 'DD [de] MMM YYYY',
                sameElse: 'DD [de] MMM YYYY',
            });
        }
        return moment(date).locale(locale).calendar({
            sameDay: '[Hoy a las] h:mm A',
            nextDay: '[Mañana]',
            nextWeek: 'DD [de] MMM YYYY h:mm A',
            lastDay: '[Ayer a las] h:mm A',
            lastWeek: 'DD [de] MMM YYYY h:mm A',
            sameElse: 'DD [de] MMM YYYY h:mm A',
        });
    }
    else {
        return moment(date).locale(locale).format(format);
    }
}
export function getUTCDate(date, format = DATE.FORMAT.DATE_TIME) {
    return moment(date).utc().format(format);
}
export function getDateDifference(date1, date2, unitOfTime = "seconds") {
    return moment(date1).diff(date2, unitOfTime, false);
}
export function getNowDate(utc = true, format = DATE.FORMAT.DATE_TIME) {
    if(!utc) {
        return moment().format(format);
    }
    return moment().utc().format(format);
}
export function getDate(date, format = DATE.FORMAT.DATE_TIME) {
    return moment(date).format(format);
}
