import React from "react";
import styled from "styled-components";
import {Tag} from 'antd';
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import {PaperClipOutlined, PlusOutlined} from "@ant-design/icons";
import Container from "../components/layout/Container";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import LinkButton from "../components/layout/LinkButton";
import HeaderTitle from "../components/layout/HeaderTitle";
import {getTimeZoneDate} from "../utils/Date";

const NoticeBoard = props => {
    const {intl} = props;
    const columns = [
        {
            title: 'Título',
            render: (record, index) => <b> {record.file_attachment_url ? <PaperClipOutlined/> : ''} {record.title}</b>
        },
        {
            title: 'Descripción',
            responsive: ['lg'],
            ellipsis: true,
            dataIndex: 'description',
            render: (description) => description
        },
        {
            title: 'Tipo',
            responsive: ['lg'],
            dataIndex: 'type',
            render: status => (
                <span>
                <Tag>{intl.formatMessage({id:`notice-board.type.${status}`})}</Tag>
            </span>
            ),
        },
        {
            title: 'Fecha',
            dataIndex: 'created_at',
            responsive: ['lg'],
            render: date_created => (
                <span className="table-datetime">{getTimeZoneDate({date:date_created})}</span>
            )
            ,
        }
    ];
    return (
        <EntityWrapper>
            <HeaderTitle>
                Avisos
            </HeaderTitle>
            <Container>
                <EntityDataTableActions>
                    <LinkButton className="pull-right" to={`${ROUTE.ROUTES.NOTICE}/${ROUTE.KEYWORDS.NEW}`}>
                        <PlusOutlined /> <span>Nuevo Aviso</span>
                    </LinkButton>
                </EntityDataTableActions>
                <EntityContainer>
                    <EntityDataTable
                        entity={ROUTE.ROUTES.NOTICE}
                        emptyTitle={'¡Aún no hay avisos!'}
                        emptyMessage={'Una vez que hagas un aviso en un instante le llegará una notificación a todos los residentes de la residencial.'}
                        columns={columns}
                        entityName={ENTITY.TYPE.NOTICE_BOARD} />
                </EntityContainer>
            </Container>
        </EntityWrapper>
    );
};

const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;

export default injectIntl(NoticeBoard);
