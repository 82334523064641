import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Avatar, Button, Col, Row, Spin, Tag, Modal} from "antd";
import ResidentForm from "../forms/ResidentForm";
import {useParams} from "react-router-dom";
import * as Resident from "../utils/Residents";
import App from "../utils/App";
import Api from "../utils/Api";
import Card from "../components/layout/Card";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY, MEMBER, RESIDENT} from "../constants";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import Column from "../components/layout/Column";
import Description from "../components/layout/Description";
import {getDateDifference, getNowDate, getDate} from "../utils/Date";
import TimerButton from "../components/layout/TimerButton";
import * as Membership from "../utils/Member";
import ResidentMembership from "../components/member/ResidentMembership";
import { PlusOutlined } from "@ant-design/icons";
import MembershipForm from "../forms/MembershipForm";

const MIN_CD_FOR_INVITATION_EMAIL = 60;

const ResidentDetail = props => {
    const {auth, intl} = props;
    const {uuid} = useParams();
    const [resident, setResident] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [membershipsLoading, setMembershipsLoading] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const [showCreateMembershipModal, setShowCreateMembershipModal] = useState(false);
    const [membershipSubmitLoading, setMembershipSubmitLoading] = useState(false);
    const fetchResident = async () => {
        setLoading(true);
        try {
            const data = await Resident.getByUuid(uuid);
            setResident(data);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    const fetchMemberships = async () => {
        setMembershipsLoading(true);
        try {
            const data = (await Membership.getMembershipsByResidentUuid(uuid)).data;
            setMemberships(data);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setMembershipsLoading(false);
        }
    };
    useEffect(() => {
        fetchResident();
        fetchMemberships();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha eliminado correctamente al residente");
        window.history.back();
    };
    const onEditSubmit = async data => {
        setSubmitLoading(true);
        try {
            await Resident.updateByUuid(uuid, data);
            App.showSuccess('Usuario actualizado correctamente');
            setSubmitLoading(false);
            window.history.back();
        }
        catch(error) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };
    const onMembershipDelete = () => {
        fetchMemberships();
    };
    const [buttonInvitationLoading, setButtonInvitationLoading] = useState(false);
    const resendInvitationEmailButtonOnClick = async () => {
        setButtonInvitationLoading(true);
        try {
            if(!!resident?.last_sent_email_invitation) {
                await Resident.resendInvitationEmail(resident?.uuid);
                App.showSuccess('Se ha reenviado correctamente la invitación.');
            }
            else {
                const prevDateSent = getDate(resident?.last_sent_email_invitation);
                const now = getNowDate();
                const timeDifferenceInMinutes = getDateDifference(prevDateSent, now, "minutes");
                if(timeDifferenceInMinutes <= MIN_CD_FOR_INVITATION_EMAIL) {
                    await Resident.resendInvitationEmail(resident?.uuid);
                    App.showSuccess('Se ha reenviado correctamente la invitación.');
                }
                else {
                    App.showError(`Es necesario esperar al menos ${timeDifferenceInMinutes} minutos antes de reenviar la invitación.`)
                }
            }
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setButtonInvitationLoading(false);
        }
    };
    const onAddMembershipClick = () => {
        setShowCreateMembershipModal(true);
    };
    const onMembershipSubmit = async (membershipData) => {
        try {
            setMembershipSubmitLoading(true);
            const membership = {
                role: membershipData.role,
                status: MEMBER.STATUS.ACTIVE,
                account_uuid: membershipData.account_uuid,
                resident_uuid: resident?.uuid
            }
            await Membership.addMembership(membership);
            fetchMemberships();
            setShowCreateMembershipModal(false);
            App.showSuccess(`Se ha ligado una residencia a ${resident?.user?.first_name} correctamente`);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setMembershipSubmitLoading(false);
        }
    };
    const onAddMembershipModalCancel = () => {
        if(!membershipSubmitLoading) {
            setShowCreateMembershipModal(false);
        }
    }
    return (
        <DetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={resident !== null ? `${resident.user?.first_name} ${resident.user?.last_name}` : (loading ? <LoadingOutlined /> : 'Nuevo Residente')}
                subTitle=""
            />
            <Modal
                destroyOnClose={true}
                visible={showCreateMembershipModal}
                onCancel={onAddMembershipModalCancel}
                footer={null}
            >
                <h2>Nueva Residencia</h2>
                <Description style={{marginBottom: '30px'}}>
                    Con ello {resident?.user?.first_name} obtendrá nuevas funcionales como:
                    <ul style={{marginTop: '5px'}}>
                        <li>Acceso a la nueva residencia desde la app</li>
                        <li>Autorización de visitas</li>
                        <li>Pagos de mantenimiento</li>
                        <li>Notificaciones</li>
                    </ul>
                </Description>
                <MembershipForm
                    loading={membershipSubmitLoading}
                    onSubmit={onMembershipSubmit} 
                    entity={null}
                    backButtonContent={"Cancelar"}
                    okButtonText={"Añadir"}
                    onCancel={onAddMembershipModalCancel}
                />
            </Modal>
            <Container>
                <Spin spinning={loading}>
                    {!!resident && (resident.status !== RESIDENT.STATUS.INVITE_PENDING && resident.status !== RESIDENT.STATUS.PENDING) &&
                    <React.Fragment>
                        <DetailContainer>
                            <Row>
                                <Col xs={24}  sm={24} md={24} lg={24}>
                                    <Card style={{marginTop: !!resident ? "85px" : "0"}}>
                                        <ProfilePictureContainer>
                                            <AvatarContainer>
                                                <Avatar style={{fontSize: "50px"}} size={120} src={resident?.user?.profile_image?.url}>
                                                    {!!resident?.user?.first_name && resident?.user?.first_name.length > 0 && resident?.user?.first_name[0]}
                                                </Avatar>
                                            </AvatarContainer>
                                        </ProfilePictureContainer>
                                        <Row>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailTitle>Nombre</DetailTitle>
                                                <DetailDescription>{resident?.user?.first_name} {resident.user?.last_name} {resident.user?.mother_surname}</DetailDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailTitle>Teléfono</DetailTitle>
                                                <DetailDescription>{resident?.user?.phone}</DetailDescription>
                                            </Col>
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailTitle>Correo electrónico</DetailTitle>
                                                <DetailDescription>{resident?.user?.email}</DetailDescription>
                                            </Col>
                                            {resident.uuid === auth?.resident?.uuid &&
                                            <Col xs={24}  sm={24} md={12} lg={8}>
                                                <DetailTitle>Rol</DetailTitle>
                                                <DetailDescription><Tag>{intl.formatMessage({id:`resident.role.${resident.role}`})}</Tag></DetailDescription>
                                            </Col>
                                            }
                                            <Column title="Estatus" text={<Tag color={Resident.getStatusColor(resident?.status)}>{intl.formatMessage({id:`resident.status.${resident?.status}`})}</Tag>} className="info-column" />
                                        </Row>
                                        {resident.uuid !== auth?.resident?.uuid &&
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={24} style={{marginTop: "20px"}}>
                                                <ResidentForm loading={submitLoading} entity={resident} onSubmit={onEditSubmit} />
                                            </Col>
                                        </Row>
                                        }
                                    </Card>
                                </Col>
                                {
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Spin spinning={membershipsLoading}>
                                            {!!memberships && memberships.length > 0 && 
                                                <h3 style={{marginTop: '20px'}}>Residencias</h3>
                                            }
                                            {memberships.map(membership => 
                                                <ResidentMembership 
                                                    key={membership.uuid} 
                                                    residentMembership={membership} 
                                                    onDelete={onMembershipDelete}
                                                />
                                            )}
                                        </Spin>
                                    </Col>
                                }
                                <Button onClick={onAddMembershipClick} className="ant-btn-form" style={{marginTop: '15px', marginLeft: 'auto', marginRight: 'auto'}}>
                                    <PlusOutlined /> Nueva Residencia
                                </Button>
                            </Row>
                            {!!resident && resident.uuid !== auth?.resident?.uuid &&
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <DeleteEntityButton
                                        modalTitle={`¿Deseas eliminar a ${resident?.user?.first_name} ${resident?.user?.last_name}?`}
                                        modalContent={() => <span>{resident?.user?.first_name} perderá el acceso a la residencial junto con sus datos.</span>}
                                        uuid={resident.uuid}
                                        onSubmit={onDeleteSubmit}
                                        entity={ENTITY.TYPE.RESIDENTS} block danger>
                                        Eliminar Residente
                                    </DeleteEntityButton>
                                </Col>
                            </Row>
                            }
                        </DetailContainer>
                    </React.Fragment>
                    }
                    {!!resident && resident.uuid !== auth?.resident?.uuid && (resident.status === RESIDENT.STATUS.INVITE_PENDING || resident.status === RESIDENT.STATUS.PENDING) &&
                    <DetailContainer>
                        <Card>
                            <Row>
                                <Column title="Nombre" text={`${!!resident?.user?.first_name && resident?.user?.first_name} ${resident?.user.last_name && resident?.user?.last_name}`} className="info-column" />
                                <Column title="Correo electrónico" text={resident?.user?.email} className="info-column" />
                                <Column title="Teléfono" text={`${resident?.user?.username}`} className="info-column" />
                                <Column title="Estatus" text={<Tag color={Resident.getStatusColor(resident?.status)}>{intl.formatMessage({id:`resident.status.${resident?.status}`})}</Tag>} className="info-column" />
                            </Row>
                        </Card>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card style={{marginTop: '20px'}}>
                                    <h3>Reenviar Invitación</h3>
                                    <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                                        El registro del usuario sigue pendiente, puedes mandarle un recordatorio reenviandole la invitación nuevamente.
                                    </Description>
                                    <TimerButton
                                        buttonProps = {{
                                            type:"default",
                                            className:"ant-btn-form",
                                        }}
                                        loading={buttonInvitationLoading}
                                        timerDate={resident?.last_sent_invitation_email}
                                        onClick={resendInvitationEmailButtonOnClick}>Reenviar Invitación</TimerButton>
                                </Card>
                            </Col>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <DeleteEntityButton
                                        modalTitle={`¿Deseas eliminar a ${resident?.user?.first_name} ${resident?.user?.last_name}?`}
                                        modalContent={() => <span>{resident?.user?.first_name} perderá el acceso a la residencial junto con sus datos.</span>}
                                        uuid={resident.uuid}
                                        onSubmit={onDeleteSubmit}
                                        entity={ENTITY.TYPE.RESIDENTS} block danger>
                                        Eliminar Residente
                                    </DeleteEntityButton>
                                </Col>
                            </Row>
                        </Row>
                    </DetailContainer>
                    }
                </Spin>
            </Container>
        </DetailWrapper>
    );
};

const DetailWrapper = styled.div``;
const DetailContainer = styled.div`
  .info-column {
    margin-bottom: 15px;
  }
`;
const DetailTitle = styled.div`
  font-weight: 600;
  color: var(--unselected-text-color);
`;
const DetailDescription = styled.div`
  font-weight: 500;
  margin-bottom: 15px;
`;
const ProfilePictureContainer = styled.div`
  margin: -100px 0 35px 0;
  display: flex;
  justify-content: flex-start;
`;
const  AvatarContainer =styled.div`
  display: inline-block;

  border: solid 10px white;
  border-radius: 50%;
`;

const mapStateToProps = state => ({
    ...state
});
export default connect(mapStateToProps)(injectIntl(ResidentDetail));
