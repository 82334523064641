import Member from "./Member";
import * as Visit from "./Visit";
import * as accountUtil from "./Account";
import {ENTITY} from "../constants";
import Guard from "./Guards";
import Entrance from "./Entrance";
import Parcel from "./Parcel";
import NoticeBoard from "./NoticeBoard";
import Alert from "./Alerts";
import * as SectionsUtil from "./Sections";
import * as StaffUtil from "./Staff";
import * as StaffReviews from "./StaffRating";
import * as AccountActivity from "./AccountActivity";
import * as StaffCategories from "./StaffCategories";
import * as EmergencyContacts from "./EmergencyContact";
import * as EmergencyContactCategories from "./EmergencyContactCategories";
import * as VisitInvitations from "./VisitInvitation";
import * as Residents from "./Residents";
import * as ResidentRequests from "./ResidentRequest";
import Api from "./Api";

export async function getEntity(entity, params, opts)  {
    try {
        const memberUtil = new Member();
        if(!!opts.path) {
            return new Promise(async (resolve, reject) => {
                try {
                    resolve((await Api.get(opts.path, params)).data);
                }
                catch(error) {
                    reject(error);
                }
            });
        }
        else {
            switch (entity) {
                case ENTITY.TYPE.MEMBERS:
                    return await memberUtil.getMembers(params);
                case ENTITY.TYPE.ACCOUNTS:
                    return await accountUtil.getAccounts(params);
                case ENTITY.TYPE.VISITS:
                    return await Visit.getVisits(params);
                case ENTITY.TYPE.GUARDS:
                    const guardUtil = new Guard();
                    return await guardUtil.getGuards(params);
                case ENTITY.TYPE.ENTRANCE:
                    const entranceUtil = new Entrance();
                    return await entranceUtil.getEntrances(params);
                case ENTITY.TYPE.PARCEL:
                    const parcelUtil = new Parcel();
                    return await parcelUtil.getParcels(params);
                case ENTITY.TYPE.NOTICE_BOARD:
                    const noticeBoardUtil = new NoticeBoard();
                    return await noticeBoardUtil.get(params);
                case ENTITY.TYPE.ALERTS:
                    const alertUtil = new Alert();
                    return await alertUtil.get(params);
                case ENTITY.TYPE.SECTIONS:
                    return await SectionsUtil.getSections(params);
                case ENTITY.TYPE.STAFF:
                    return await StaffUtil.getStaff(params);
                case ENTITY.TYPE.MEMBER_REQUESTS:
                    return await memberUtil.getPendingRequests(params);
                case ENTITY.TYPE.STAFF_REVIEWS:
                    return await StaffReviews.getAllByStaffUuid(opts.uuid, params);
                case ENTITY.TYPE.ACCOUNT_ACTIVITY:
                    return await AccountActivity.getAll(params);
                case ENTITY.TYPE.VISIT_INVITATION:
                    return await VisitInvitations.getAll(params);
                case ENTITY.TYPE.STAFF_CATEGORY:
                    return await StaffCategories.getAll(params);
                case ENTITY.TYPE.EMERGENCY_CONTACT_CATEGORY:
                    return await EmergencyContactCategories.getAll(params);
                case ENTITY.TYPE.EMERGENCY_CONTACT:
                    return await EmergencyContacts.getAll(params);
                case ENTITY.TYPE.RESIDENTS:
                    return await Residents.getAll(params);
                    case ENTITY.TYPE.RESIDENT_REQUEST:
                    return await ResidentRequests.getAll(params);
                default:
                    return new Promise((resolve, reject) => reject('undefined entity was sent to getEntity()'));
            }
        }
    }
    catch(error) {
        throw error;
    }
}
export async function deleteEntity (entity, uuid, params) {
    try {
        const memberUtil = new Member();
        switch (entity) {
            case ENTITY.TYPE.ACCOUNTS:
                return await accountUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.VISITS:
                return await Visit.deleteByUuid(uuid, params);
            case ENTITY.TYPE.GUARDS:
                const guardUtil = new Guard();
                return await guardUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.ENTRANCE:
                const entranceUtil = new Entrance();
                return await entranceUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.PARCEL:
                const parcelUtil = new Parcel();
                return await parcelUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.NOTICE_BOARD:
                const noticeBoardUtil = new NoticeBoard();
                return await noticeBoardUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.SECTIONS:
                return await SectionsUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.STAFF:
                return await StaffUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.MEMBERS:
                return await memberUtil.deleteByUuid(uuid, params);
            case ENTITY.TYPE.MEMBER_REQUESTS:
                return await memberUtil.getPendingRequests(uuid, params);
            case ENTITY.TYPE.STAFF_REVIEWS:
                return await StaffReviews.deleteByUuid(uuid, params);
            case ENTITY.TYPE.VISIT_INVITATION:
                return await VisitInvitations.deleteByUuid(uuid, params);
            case ENTITY.TYPE.STAFF_CATEGORY:
                return await StaffCategories.deleteByUuid(uuid, params);
            case ENTITY.TYPE.EMERGENCY_CONTACT_CATEGORY:
                return await EmergencyContactCategories.deleteByUuid(uuid, params);
            case ENTITY.TYPE.EMERGENCY_CONTACT:
                return await EmergencyContacts.deleteByUuid(uuid, params);
            case ENTITY.TYPE.RESIDENTS:
                return await Residents.deleteByUuid(uuid, params);
            case ENTITY.TYPE.RESIDENT_REQUEST:
                return await ResidentRequests.deleteByUuid(uuid, params);
            default:
                return new Promise((resolve, reject) => reject('undefined entity was sent to getEntity()'));
        }
    }
    catch(error) {
        throw error;
    }
}

export async function updateEntity (entity, uuid, data) {
    try {
        const memberUtil = new Member();
        switch (entity) {
            case ENTITY.TYPE.ACCOUNTS:
                return await accountUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.GUARDS:
                const guardUtil = new Guard();
                return await guardUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.ENTRANCE:
                const entranceUtil = new Entrance();
                return await entranceUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.PARCEL:
                const parcelUtil = new Parcel();
                return await parcelUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.NOTICE_BOARD:
                const noticeBoardUtil = new NoticeBoard();
                return await noticeBoardUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.SECTIONS:
                return await SectionsUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.STAFF:
                return await StaffUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.MEMBERS:
                return await memberUtil.updateByUuid(uuid, data);
            case ENTITY.TYPE.STAFF_REVIEWS:
                return await StaffReviews.updateByUuid(uuid, data);
            case ENTITY.TYPE.STAFF_CATEGORY:
                return await StaffCategories.updateByUuid(uuid, data);
            case ENTITY.TYPE.EMERGENCY_CONTACT_CATEGORY:
                return await EmergencyContactCategories.updateByUuid(uuid, data);
            case ENTITY.TYPE.EMERGENCY_CONTACT:
                return await EmergencyContacts.updateByUuid(uuid, data);
            case ENTITY.TYPE.RESIDENTS:
                return await Residents.updateByUuid(uuid, data);
            default:
                return new Promise((resolve, reject) => reject('undefined entity was sent to getEntity()'));
        }
    }
    catch(error) {
        throw error;
    }
}
