import { DefaultTheme } from "styled-components";
import { createTheme } from "@mui/material/styles";

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
      dashed: true;
    }
}

const colors = {
    red: '#E50914',
    lightRed: '#ffa39e',
    darkRed: '#B20710',
    white: '#ffffff',
    dark: '#141414',
    black: '#000',
    gray: '#777',
    lighterDark: '#181818',
    lightDark: '#333',
    orange: '#fa8c16',
    lightGreen: '#b7eb8f',
    green: '#46d369',
    darkGreen: '#5b8c00',
    purple: {
        '100': '#f3e8ff',
        '200': '#ddbbff',
        '300': '#c68eff',
        '500': '#8e1eff',
        '600': '#470f7f',
        '700': '#2a094c',
        '800': '#0e0319'
    },
    darkBlue: '#163959',
    darkPurple: '#1d0047',
    midPurple: '#4700e2',
    facebook: '#1877F2',
    linkedIn: '#0A66C2',
    reddit: '#FF5700',
    whatsApp: '#25D366',
    instagram: '#E4405F',
}

const brandColor = {
    primaryColor: colors.purple[500],
    secondaryColor: colors.midPurple,
};

const defaultTheme: DefaultTheme = {
    layout: {
        navBarHeight: 65,
    },
    color: {
        brand: brandColor,
    }
}

export const materialUITheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: brandColor.primaryColor,
            contrastText: colors.white,
        },
        secondary: {
            main: brandColor.secondaryColor,
            contrastText: colors.white,
        },
        error: {
            main: colors.red,
            light: colors.lightRed,
            dark: colors.darkRed
        },
        success: {
            main: colors.green,
            light: colors.lightGreen,
            dark: colors.darkGreen
        },
        warning: {
            main: colors.orange
        },
        text: {
            primary: colors.darkBlue
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    height: '44px'
                }
            },
            variants: [
                {
                  props: { variant: 'dashed' },
                  style: {
                    textTransform: 'none',
                    border: `2px dashed ${brandColor.primaryColor}`,
                  },
                },
                {
                  props: { variant: 'dashed', color: 'secondary' },
                  style: {
                    border: `2px dashed ${brandColor.secondaryColor}`,
                  },
                },
              ],
        }
    }
});

export default defaultTheme;

// If you are using TypeScript, you would also need 
// to use module augmentation for the theme to accept the above values.

// declare module '@mui/material/styles' {
//     interface Theme {
//       status: {
//         danger: React.CSSProperties['color'];
//       };
//     }
  
//     interface Palette {
//       neutral: Palette['primary'];
//     }
//     interface PaletteOptions {
//       neutral: PaletteOptions['primary'];
//     }
  
//     interface PaletteColor {
//       darker?: string;
//     }
//     interface SimplePaletteColorOptions {
//       darker?: string;
//     }
//     interface ThemeOptions {
//       status: {
//         danger: React.CSSProperties['color'];
//       };
//     }
//   }