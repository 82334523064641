import React from "react";
import styled from "styled-components";
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import {PlusOutlined} from "@ant-design/icons";
import Container from "../components/layout/Container";
import EntityDataTableActions from "../components/entity/EntityDataTableActions";
import LinkButton from "../components/layout/LinkButton";
import DetailHeader from "../components/layout/DetailHeader";

const StaffCategories = props => {
    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            render: (name) => <b>{name}</b>
        }
    ];
    return (
        <Wrapper>
            <DetailHeader
                title="Categorías de Staff"
                onBack={() => window.history.back()}
            />
            <Container>
                <EntityWrapper>
                    <EntityDataTableActions>
                        <LinkButton className="pull-right" to={`${ROUTE.ROUTES.STAFF_CATEGORY}/${ROUTE.KEYWORDS.NEW}`}>
                            <PlusOutlined /> <span>Nueva Categoría</span>
                        </LinkButton>
                    </EntityDataTableActions>
                    <EntityContainer>
                        <EntityDataTable
                            entity={ROUTE.ROUTES.STAFF_CATEGORY}
                            emptyTitle={'¡Aún no tienes categorías de staff!'}
                            emptyMessage={'Una vez que agregues categorías podrás ver su estatus... ¡Y mucho más!'}
                            columns={columns}
                            entityName={ENTITY.TYPE.STAFF_CATEGORY} />
                    </EntityContainer>
                </EntityWrapper>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div``;
const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;

export default injectIntl(StaffCategories);
