import React from 'react';
import styled from 'styled-components';
import {Menu} from "antd";

const HeaderMenu = props => {
    const {
        onClick = () => {},
        selectedKeys = [],
        mode = "horizontal",
        children
    } = props;
    return (
        <HeaderMenuWrapper>
            <Menu onClick={onClick} selectedKeys={selectedKeys} mode={mode}>
                {children}
            </Menu>
        </HeaderMenuWrapper>
    );
};

const HeaderMenuWrapper = styled.div`
  .ant-menu {
    height: var(--header-height);
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: var(--container-padding);
  }
  .ant-menu-horizontal {
    /* line-height: calc(var(--header-height) - 2px); */
  }
  border-bottom: var(--container-border);
`;

export default HeaderMenu;
