import React from "react";
import { Input, Select } from "antd";

const Option = Select.Option;

const CountryCodePicker = () => (
    <Select defaultValue="http://" className="select-before">
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
    </Select>
);

const PhoneInput = (props) => (
    <Input addonBefore={<CountryCodePicker />} placeholder="Ej. (999) 1969 3392" {...props}  />
);

export default PhoneInput;