import React from "react";
import {Form, Button, Select, Input, Row, Col, Spin} from 'antd';
import * as accountUtil from "../utils/Account";
import App from "../utils/App";
import {RESIDENT} from "../constants";
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import {ArrowLeftOutlined} from "@ant-design/icons";

const { Option } = Select;

class InviteResidentForm extends React.Component {
    formRef = React.createRef();
    state = {accountsLoading: false, accounts: []};
    onFinish = values => {
        this.props.onSubmit({
            ...values,
            country_code: '52'
        });
    };
    onCancel = () => {
        window.history.back();
    };
    fetchAccounts = async (params) => {
        this.setState({accountsLoading: true});
        try {
            const accounts = (await accountUtil.getAccounts(params)).data;
            this.setState({accounts: accounts});
        }
        catch(error) {
            console.log(error);
        }
        finally {
            this.setState({accountsLoading: false});
        }
    };
    onAccountSearch = async (query) => {
        await this.fetchAccounts({q: query});
    };
    render() {
        const {intl} = this.props;
        return (
            <Form ref={this.formRef} layout={"vertical"} name="control-ref" onFinish={this.onFinish}>
                <Form.Item
                    name="first_name"
                    label="Nombre"
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="last_name"
                            label="Apellido paterno"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="mother_surname"
                            label="Apellido materno"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="phone"
                            label="Teléfono celular"
                            rules={[{required: true}]}
                        >
                            <Input prefix="+52"/>
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="email"
                            label="Correo Electrónico"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="account_uuid"
                            label="Casa / Departamento"
                            rules={[{required: true}]}
                        >
                            <Select
                                placeholder="Busca una residencia"
                                showSearch
                                onSearch={this.onAccountSearch}
                                filterOption={false}
                                notFoundContent={this.state.accountsLoading ? <Spin /> : `No hay resultados que cumplan con tu búsqueda`}
                                showArrow={false}
                                loading={this.state.accountsLoading}
                            >
                                {this.state.accounts.map(account =>
                                    <Option key={`${account?.uuid}${account?.number}`} value={account.uuid}>{account?.section?.name} {account.number}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} xlg={12}>
                        <Form.Item
                            name="role"
                            label="Rol"
                            rules={[{required: true}]}
                        >
                            <Select
                                placeholder="Selecciona una de las siguientes opciones"
                            >
                                {App.getCatalogListAsArray(RESIDENT.ROLE).map(role => <Option key={`resident-role-${role}`} value={role}>{intl.formatMessage({id:`resident.role.${role}`})}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <FormActionsContainer>
                    <Button htmlType="button" className="ant-btn-form" onClick={this.onCancel}>
                        <ArrowLeftOutlined />
                        Regresar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={this.props.loading}>
                        Guardar
                    </Button>
                </FormActionsContainer>
            </Form>
        );
    }
}

InviteResidentForm.defaultProps = {
    loading: false
};

export default injectIntl(InviteResidentForm);
