import React from "react";
import {Form, Button, Input} from 'antd';
import {injectIntl} from "react-intl";
import FormActionsContainer from "../components/layout/form/FormActionsContainer";
import {ArrowLeftOutlined} from "@ant-design/icons";

const EmergencyContactCategoryForm = (props) => {
    const {entity, loading, onSubmit} = props;
    const onFinish = values => {
        onSubmit(values);
    };
    const onCancel = () => {
        window.history.back();
    };
    return (
        <Form layout={"vertical"}
              initialValues = {!!entity ? {
                  name: entity?.name
              } : {}}
              name="control-ref"
              onFinish={onFinish}>
            <Form.Item
                name="name"
                label="Nombre"
                rules={[{required: true}]}
            >
                <Input />
            </Form.Item>
            <FormActionsContainer>
                <Button htmlType="button" className="ant-btn-form" onClick={onCancel}>
                    <ArrowLeftOutlined />
                    Regresar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

EmergencyContactCategoryForm.defaultProps = {
    edit: true,
    entity: null,
    loading: false
};

export default injectIntl(EmergencyContactCategoryForm);
