import Api from "./Api";

export default class Alert {
    get = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/alerts', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getByUuid = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/alerts/${uuid}`)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    updateByUuid = (uuid, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/alerts/${uuid}`, null, data)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    create = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.post(`/alerts`, null, data)).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
}
