import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Col, Row} from "antd";
import {useParams} from "react-router-dom";
import App from "../utils/App";
import Api from "../utils/Api";
import {Spin} from "antd";
import Card from "../components/layout/Card";
import Guard from "../utils/Guards";
import GuardForm from "../forms/GuardForm";
import Container from "../components/layout/Container";
import DetailHeader from "../components/layout/DetailHeader";
import * as PasscodeUtil from "../utils/Passcode";
import DeleteEntityButton from "../components/entity/DeleteEntityButton";
import {ENTITY, GUARD} from "../constants";
import {LockOutlined} from "@ant-design/icons";
import Description from "../components/layout/Description";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import EntityStatusButton from "../components/entity/EntityStatusButton";
import ImportantNumber from "../components/layout/ImportantNumber";

const entityUtil = new Guard();

const GuardDetail = props => {
    const {uuid} = useParams();
    const [entity, setEntity] = useState(null);
    const [newEntity, setNewEntity] = useState(true);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [entityPasscode, setEntityPassocode] = useState(null);
    const [entityPasscodeLoading, setEntityPasscodeLoading] = useState(false);
    const fetchEntity = async () => {
        setLoading(true);
        try {
            const entityData = await entityUtil.getByUuid(uuid);
            setEntity(entityData);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    const fetchEntityPasscode = async () => {
        setEntityPasscodeLoading(true);
        try {
            const passcodeData = (await PasscodeUtil.getGuardPasscode(uuid));
            setEntityPassocode(passcodeData.passcode);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setEntityPasscodeLoading(false);
        }
    };
    const onViewEntityPasscodeClick = async () => {
        fetchEntityPasscode();
    };
    useEffect(() => {
        if(!!uuid) {
            setNewEntity(false);
            fetchEntity().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = async data => {
        setSubmitLoading(true);
        try {
            if(newEntity) {
                await entityUtil.create(data);
                App.showSuccess('El guardia se ha agregado correctamente');
            }
            else {
                await entityUtil.updateByUuid(uuid, data);
                App.showSuccess('El guardia se ha actualizado correctamente');
            }
            setSubmitLoading(false);
            window.history.back();
        }
        catch(error) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };
    const onDeleteSubmit = () => {
        App.showSuccess("Se ha eliminado correctamente el guardia");
        window.history.back();
    };
    return (
        <EntityDetailWrapper>
            <DetailHeader
                onBack={() => window.history.back()}
                title={entity !== null ? `${entity.first_name} ${entity.last_name}` : (loading ? <LoadingOutlined /> : 'Nuevo Guardia')}
                subTitle={entity !== null ? `` : ``}
            />
            <Container>
                <Spin spinning={loading}>
                    {(!!entity || newEntity) &&
                    <EntityDetailContainer>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card>
                                    <GuardForm loading={submitLoading} entity={entity} onSubmit={onSubmit} />
                                </Card>
                            </Col>
                        </Row>
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Spin spinning={entityPasscodeLoading}>
                                    <Card style={{marginTop: '20px'}}>
                                        <h3>Código de acceso</h3>
                                        <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                                            Por seguridad se guardará un registro de los usuarios que vean el código de acceso de esta persona.
                                        </Description>
                                        {!entityPasscode ? <Button className="ant-btn-form" onClick={onViewEntityPasscodeClick}><LockOutlined/> Ver código de acceso</Button> : <ImportantNumber>{entityPasscode}</ImportantNumber>}
                                    </Card>
                                </Spin>
                            </Col>
                        </Row>
                        }
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Card style={{marginTop: '20px'}}>
                                    <h3>Restricción de acceso</h3>
                                    <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                                        Esta funcionalidad te permite autorizar o restringir la entrada de {entity.first_name} a la residencial con su código de acceso.
                                    </Description>
                                    <EntityStatusButton
                                        successModalTitle="¿Permitir acceso a la residencial?"
                                        dangerModalTitle="¿Restringir acceso a la residencial?"
                                        dangerModalContent={() => <span>{entity.first_name} ya <b>NO</b> tendrá permitido el acceso a la residencial.</span>}
                                        successModalContent={() => <span>De ahora en adelante, {entity.first_name} tendrá permitido el acceso a la residencial.</span>}
                                        uuid={entity.uuid}
                                        defaultStatus={entity.status}
                                        successStatus={GUARD.STATUS.ACTIVE}
                                        dangerStatus={GUARD.STATUS.BANNED}
                                        modalSuccessStatusText="Permitir acceso"
                                        modalDangerStatusText="Restringir acceso"
                                        entity={ENTITY.TYPE.GUARDS} />
                                </Card>
                            </Col>
                        </Row>
                        }
                        {!!entity &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <DeleteEntityButton
                                    modalTitle="¿Eliminar Guardia?"
                                    modalContent={() => <span>Ya no podrás volver a ver sus datos ni modificarlo.</span>}
                                    uuid={entity.uuid}
                                    onSubmit={onDeleteSubmit}
                                    entity={ENTITY.TYPE.GUARDS} block danger>
                                    Eliminar Guardia
                                </DeleteEntityButton>
                            </Col>
                        </Row>
                        }
                    </EntityDetailContainer>
                    }
                </Spin>
            </Container>
        </EntityDetailWrapper>
    );
};

const EntityDetailWrapper = styled.div``;
const EntityDetailContainer = styled.div``;

export default GuardDetail;
