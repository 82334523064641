import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import GlobalStyle from './GlobalStyle';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import theme, {materialUITheme} from "./theme";
import { ThemeProvider } from "styled-components";
import store from './redux/store';
import AuthProvider from './providers/AuthProvider';
import {IntlProvider} from "react-intl";
import AppLocale from './translations';
import { Provider } from "react-redux";
import ErrorBoundary from "./components/GeneralErrorBoundary";
import Root from "./pages/Root";
import './assets/css/index.css';
import './assets/css/antd-override.css';

const currentAppLocale = AppLocale['es'];

const App = () => {
    return(
        <>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <MUIThemeProvider theme={materialUITheme}>
                    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <ErrorBoundary message="Ha ocurrido un error">
                            <Provider store={store}>
                                {/* Broswer router tiene que estar afuera para que useLocation (AuthProvider) funcione (router) */}
                                {/* https://reactrouter.com/web/api/Hooks/uselocation */}
                                <Router>
                                    <AuthProvider>
                                        <Root />
                                    </AuthProvider>
                                </Router>
                            </Provider>
                        </ErrorBoundary>
                    </IntlProvider>
                </MUIThemeProvider>
            </ThemeProvider>
        </>
    );
};
export default App;
