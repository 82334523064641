import React from "react";
import styled from "styled-components";
import Description from "./Description";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";

const EmptyCategorySelectContent = ({onAddButtonClick}) => {
    return (
        <Wrapper>
            <h3>¿No encontraste lo que buscabas?</h3>
            <Description>No hay ningún problema, agrega tu propia categoría.</Description>
            <Button type="primary" onClick={onAddButtonClick}><PlusOutlined /> Nueva Categoría</Button>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  text-align: center;
  padding: 20px;
  button {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
  }
`;

export default EmptyCategorySelectContent;
