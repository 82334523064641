import React, {useEffect, useState} from 'react';
import LoginForm, { LoginFormSubmitData } from "../../../forms/LoginForm";
import AuthUtils from "../../../utils/Auth";
import styled from "styled-components";
import Api from "../../../utils/Api";
import {setAuthIsNewUser, setAuthUser} from "../../../redux/actions/authActions";
import App from "../../../utils/App";
import Container from "../../../components/layout/Container";
import { 
    getAuth,
    signInWithPhoneNumber,
    OAuthCredential,
    RecaptchaVerifier,
    ConfirmationResult,
    getAdditionalUserInfo,
} from "firebase/auth";
import { AUTH } from '../../../constants';
import { RouteProps } from 'react-router';
import SocialLoginButton from '../../../components/social/SocialLoginButton';
import { Button, Divider } from "antd";
import { Link } from 'react-router-dom';
import { isValidPhone } from '../Auth.utils';
import VerificationCodeForm from '../../../forms/VerificationCodeForm';
import { VerificationCode } from '../../../types';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

// Fix
// Property recaptchaVerifier does not exist on type 'Window'
// https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window/56458070
declare global {
    interface Window {
        recaptchaVerifier: any
    }
}

const authUtils = new AuthUtils();

const Login: React.FC = (props: RouteProps) => {

    const auth = getAuth();

    // Redux store
    const dispatch = useAppDispatch();

    // State
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showCodeVerification, setShowCodeVerification] = useState(false);
    const [codeSubmitLoading, setCodeSubmitLoading] = useState(false);
    const [phoneConfirmationResult, setPhoneConfirmationResult] = useState<ConfirmationResult | null>(null);
    const [verificationCode, setVerificationCode] = useState<VerificationCode | null>(null);
    const [username, setUsername] = useState<string | null>(null);

    const handleOnSubmit = async (data: LoginFormSubmitData) => {
        setSubmitLoading(true);
        try {
            const phoneWithCountryCode = `+${data.countryCode}${data.phone}`;
            if(isValidPhone(phoneWithCountryCode)) {
                setUsername(phoneWithCountryCode);
                sendPhoneVerificationCode(phoneWithCountryCode);
            }
            else {
                throw new Error("El el número de teléfono no es válido");
            }
        }
        catch(error: any) {
            setSubmitLoading(false);
            App.showError(Api.parseResponseError(error));
        }
    };

    const sendPhoneVerificationCode = async (phone: string) => {
        try {
            const appVerifier = new RecaptchaVerifier('recaptcha-container', {size: 'invisible', callback: (response: any) => {
                console.log(response);
            }}, auth);
            const phoneSignIn = await signInWithPhoneNumber(auth, phone, appVerifier);
            setPhoneConfirmationResult(phoneSignIn);
            setShowCodeVerification(true);
        }
        catch(error: any) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
    }

    const confirmVerificationCode = async () => {
        if(!!phoneConfirmationResult && !!verificationCode) {
            setCodeSubmitLoading(true);
            try {
                const result = await phoneConfirmationResult.confirm(verificationCode.code);
                const additionalInfo = getAdditionalUserInfo(result);
                if(additionalInfo) {
                    const isNewUser = additionalInfo.isNewUser;
                    dispatch(setAuthIsNewUser(isNewUser));
                }
            }
            catch(error) {
                console.log(error);
                App.showError(Api.parseResponseError(error));
            }
            finally {
                setCodeSubmitLoading(false);
            }
        }
    };

    useEffect(() => {
        confirmVerificationCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verificationCode]);

    const onSocialLoginSuccess = async (credential: OAuthCredential | null) => {
        try {
            if(!!credential?.accessToken) {
                localStorage.setItem(AUTH.ACCESS_TOKEN, credential.accessToken);
                const currentUser = await authUtils.getCurrentUser();
    
                setSubmitLoading(false);
                dispatch(setAuthUser(currentUser));
    
                App.showSuccess(`¡Bienvenido, ${currentUser.first_name}!`);
            }
            else {
                throw new Error("Ha ocurrido un error al intentar iniciar sesión");
            }
        }
        catch(error: any) {
            App.showError(Api.parseResponseError(error));
        }
    }
    const onSocialLoginError = (error: Error) => {
        console.log(error.message);
    }
    const handleOnVerificationCodeSubmit = (data: VerificationCode) => {
        setVerificationCode(data);
    }
    const handleBackButtonClick = () => {
        // clean up
        // setVerificationCode(null);
        // setPhoneConfirmationResult(null);
        // setUsername(null);
        // recaptchaVerifier?.clear();
        // setSubmitLoading(false);
        // setShowCodeVerification(false);
        window.location.reload();
    }
    return (
        <LoginWrapper>
            <LoginContainer>
                <Container>
                    <InnerContentContainer>
                        {!showCodeVerification ? 
                            <>
                                <h2 style={{marginBottom: '25px'}}>Iniciar sesión</h2> 
                                <SocialLoginButton 
                                    onSuccess={onSocialLoginSuccess}
                                    onError={onSocialLoginError}
                                    provider="google"
                                />
                                <SocialLoginButton 
                                    onSuccess={onSocialLoginSuccess}
                                    onError={onSocialLoginError}
                                    provider="apple"
                                />
                                <Divider style={{margin: '30px 0'}}>o</Divider>
                                <LoginForm submitButtonProps={{id: 'sign-in-button'}} submitLoading={submitLoading} onSubmit={handleOnSubmit} />
                            </> : 
                            <>
                                <h2>Envíamos un código de verificación de 6 dígitos a {username}</h2>
                                <VerificationCodeForm 
                                    loading={codeSubmitLoading} 
                                    onSubmit={handleOnVerificationCodeSubmit}
                                />
                                <BackButton 
                                    disabled={codeSubmitLoading} 
                                    onClick={handleBackButtonClick} 
                                    type="link"
                                >
                                    Regresar
                                </BackButton>
                            </>
                        }
                        <div id="recaptcha-container"></div>
                    </InnerContentContainer>        
                </Container>
            </LoginContainer>
        </LoginWrapper>
    )
};
export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: var(--contrast-background-color);
  min-height: 100vh;
  .grecaptcha-badge { 
    visibility: hidden;
  }
  button {
      margin-bottom: 10px;
  }
`;
export const LoginContainer = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  background: var(--default-color-contrast);
  border-radius: var(--card-border-radius);
  justify-content: center;
  img.logo {
    max-width: 80px;
    display: block;
    margin: 0 auto 30px auto;
  }
`;
export const InnerContentContainer = styled.div`
    padding: 15px;

`;
export const RecoverPasswordLink = styled(Link)`
    margin-top: 20px;
    text-align: center;
    display: block;
    &:hover {
        text-decoration: underline;
    }
`;
const BackButton = styled(Button)`
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
`;
export default Login;
