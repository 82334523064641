import React, {useEffect} from "react";
import { 
    getAuth, 
    GoogleAuthProvider, 
    FacebookAuthProvider, 
    signInWithRedirect, 
    getRedirectResult,
    OAuthCredential,
} from "firebase/auth";
import googleLogo from "../../../assets/images/main/social/google.svg";
import facebookLogo from "../../../assets/images/main/social/facebook.svg";
import microsoftLogo from "../../../assets/images/main/social/microsoft.svg";
import appleLogo from "../../../assets/images/main/social/apple.svg";
import LoginButton, { ProviderLoginIconImage } from "../LoginButton";

export type AuthProvider = "google" | "facebook" | "microsoft" | "apple";

export interface ISocialLoginButton {
    provider: AuthProvider,
    onSuccess?: (credential: OAuthCredential | null) => void,
    onError?: (error: any) => void,
    style?: React.CSSProperties,
    method?: "login" | "register"
}

const SocialLoginButton: React.FC<ISocialLoginButton> = ({
    onSuccess = (credential: OAuthCredential | null) => {},
    onError = (error: any) => {},
    method = "login",
    ...props
}: ISocialLoginButton) => {

    const auth = getAuth();
    auth.useDeviceLanguage();

    useEffect(() => {
        void async function() {
            const redirectResult = await getRedirectResult(auth);
            // const provider = getAuthProviderFromProps();
            if(!!redirectResult) {
                // const credential = provider.credentialFromResult(redirectResult);
               // onSuccess(credential);
            }
        }
    }, [auth]);

    const onClick = async () => {
        const authProvider = new (getAuthProviderFromProps())();
        signInWithRedirect(auth, authProvider);
        // signInWithPopup(auth, authProvider).then( (result: any) => {
        //     const additionalInfo = getAdditionalUserInfo(result);
        //     console.log(additionalInfo)
        //     const isNewUser = additionalInfo?.isNewUser;
        // })
    }

    const getAuthProviderFromProps = () => {
        if(props.provider === "google") {
            return GoogleAuthProvider;
        }
        else {
            return FacebookAuthProvider;
        }
    }

    return (
        <LoginButton 
            {...props} 
            icon={getAuthProviderIcon(props.provider)} 
            name={getAuthProviderName(props.provider)}
            onClick={onClick}
        />
    );
};

export default SocialLoginButton;

export function getAuthProviderIcon(provider: AuthProvider) {
    switch (provider) {
        case "google":
            return <ProviderLoginIconImage src={googleLogo} alt="Login with Google" />;
        case "facebook": 
            return <ProviderLoginIconImage src={facebookLogo} alt="Login with Facebook" />;
        case "microsoft": 
            return <ProviderLoginIconImage src={microsoftLogo} alt="Login with Microsoft" />;
        case "apple": 
            return <ProviderLoginIconImage src={appleLogo} alt="Login with Apple" />;
        default:
            return null;
    }
}

export function getAuthProviderName(provider: AuthProvider) {
    switch (provider) {
        case "google":
            return "Google";
        case "facebook": 
            return "Facebook";
        case "microsoft": 
            return "Microsoft";
        case "apple": 
            return "Apple";
        default:
            return "";
    }
}