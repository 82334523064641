import { AnyAction } from "redux";
import * as types from "../actionTypes";

const notificationsReducer = (
    state = {
        currentMemberRequestsCount: 0,
    },
    action: AnyAction
) => {
    switch (action.type) {
        case types.SET_CURRENT_MEMBER_REQUESTS_COUNT:
            return {...state, currentMemberRequestsCount: action.currentMemberRequestsCount};
        default:
            return state;
    }
};

export default notificationsReducer;
