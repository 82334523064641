import Api from "./Api";

export const getAll = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/visit-invitations', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/visit-invitations/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/visit-invitations/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
