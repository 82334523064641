import Api from "./Api";

export const getAdoption = () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/analytics/adoption`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getVisitsSummary = () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/analytics/visits-summary`)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
