import React from 'react';
import { Form, Button } from 'antd';
import FormActionsContainer from "../../components/layout/form/FormActionsContainer";
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import PhoneInput, { CountryCodeSelectPrefix } from '../../components/PhoneInput';

export type LoginFormSubmitData = {
    countryCode?: string,
    phone: string
}

type LoginFormProps = {
    onSubmit?: (data: LoginFormSubmitData) => void,
    submitLoading: boolean,
    submitButtonProps: {}
}

const LoginForm = (
    {   onSubmit, 
        submitLoading = false, 
        submitButtonProps = {}
    }: LoginFormProps) => {
    const onFinish = (data: LoginFormSubmitData) => {
        if(!!onSubmit) {
            onSubmit(data);
        }
    };
    const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
        console.log('Failed:', errorInfo);
    };
    const [form] = Form.useForm();
    return (
        <Form
            onFinish={onFinish}
            layout={"vertical"}
            onFinishFailed={onFinishFailed}
            requiredMark="optional"
            initialValues={{
                countryCode: '52'
            }}
            onFieldsChange={(changedFields: any) => {
                const fieldName = changedFields[0].name[0];
                if(fieldName === "phone") {
                    const newValue = changedFields[0].value;
                    console.log(newValue)
                    if(isFinite(parseInt(newValue)) || newValue === "") {
                    }
                    else {
                        form.setFieldsValue({
                            "phone": newValue.concat(0, -1)
                        })
                    }
                }
            }}
        >
            <Form.Item
                name="phone"
                label="Número de teléfono"
                rules={[{ required: true, message: 'El número de teléfono es requerido.' }]}
            >
                <PhoneInput addonBefore={
                    <Form.Item
                        name="countryCode"
                        noStyle
                    >
                        <CountryCodeSelectPrefix />
                    </Form.Item>
                } placeholder="Escribe tu número de teléfono" />
            </Form.Item>
            <FormActionsContainer>
                <Button 
                    {...submitButtonProps} 
                    style={{margin: '0'}} 
                    loading={submitLoading} 
                    type="primary" 
                    htmlType="submit" 
                    block
                >
                    Siguiente
                </Button>
            </FormActionsContainer>
        </Form>
    );
};

export default LoginForm;
