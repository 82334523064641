import Api from "./Api";

export const getStaff = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/staff', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/staff/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const updateByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/staff/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const create = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/staff`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/staff/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
