import React from "react";
import { Spin as AntdSpin } from "antd";

const Spin = (props) => (
    <AntdSpin {...props} />
)

Spin.defaultProps = {
    spinning: false,
};

export default Spin;