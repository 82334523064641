import React from "react";
import {Col} from "antd";
import styled from "styled-components";

const View = styled.div``;
const Text = styled.span`
  display: block;
  font-weight: 500;
  &.rowTitle {
    color: var(--unselected-text-color);
    font-weight: 600;
  }
`;
const Column = React.memo((props) => {
    const {title, text = null} = props;
    return (
        <Col xs={24}  sm={24} md={12} lg={8}>
            <View className="cardRow" {...props}>
                <Text className="rowTitle">{title}</Text>
                <Text className="rowText">{text ? text : '-'}</Text>
            </View>
        </Col>
    );
});

export default Column;
