import React, {useState} from "react";
import {Button, Modal, Input} from "antd";
import {DislikeOutlined, LikeOutlined} from "@ant-design/icons";
import * as ResidentRequest from "../../utils/ResidentRequest";
import {fetchCurrentMemberRequestsCount} from "../../redux/actions/notificationsActions";
import {connect} from "react-redux";
import App from "../../utils/App";
import Api from "../../utils/Api";
import Description from "../layout/Description";
import styled from "styled-components";

const PendingRequestActionButton = (props) => {
    const {
        type,
        uuid,
        minimized = false,
        onRequestStart = () => {},
        onRequestFinish = () => {},
        onError = (error = null) => {},
        fetchNotificationsCount
    } = props;
    const [approveLoading, setApproveLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [rejectReason, setRejectReason] = useState(null);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const onApproveClick = async (e) => {
        e.stopPropagation();
        setApproveLoading(true);
        try {
            onRequestStart();
            await ResidentRequest.approveRequest(uuid);
            App.showSuccess('¡Has aprobado una solicitud de registro de manera exitosa!');
            fetchNotificationsCount();
            setApproveLoading(false);
            onRequestFinish();
        }
        catch(error) {
            console.log(error);
            setApproveLoading(false);
            App.showError(Api.parseResponseError(error));
            onError(error);
        }
    };

    const onRejectReasonChange = (e) => {
        e.stopPropagation();
        setRejectReason(e.target.value);
    };

    const onRejectClick = (e) => {
        e.stopPropagation();
        setRejectModalVisible(true);
    };
    const onRejectModalCancel = () => {
        setRejectModalVisible(false);
    };
    const onRejectConfirmClick = async (e) => {
        setRejectLoading(true);
        try {
            onRequestStart();
            await ResidentRequest.rejectRequest(uuid, {message: rejectReason});
            App.showSuccess('Has rechazado una solicitud de registro de manera exitosa');
            fetchNotificationsCount();
            setRejectLoading(false);
            setRejectModalVisible(false);
            onRequestFinish();
        }
        catch(error) {
            console.log(error);
            setRejectLoading(false);
            App.showError(Api.parseResponseError(error));
            onError(error);
        }
    };
    return (
        type === "approve" ?
            <Button style={{marginLeft: "15px"}} loading={approveLoading} type="primary" onClick={onApproveClick}><LikeOutlined/> {!minimized && "Aprobar"}</Button>
            :
            <RejectButtonWrapper onClick={(e) => {e.stopPropagation();}}>
                <Modal title="¿Cuál es el motivo?"
                       visible={rejectModalVisible}
                       closable={false}
                       keyboard={false}
                       onCancel={onRejectModalCancel}
                       footer={[
                           <Button disabled={rejectLoading} key="back" onClick={onRejectModalCancel} className="ant-btn-form">Cancelar</Button>,
                           <Button disabled={!rejectReason} key="submit" type="primary" loading={rejectLoading} onClick={onRejectConfirmClick}><DislikeOutlined/> Rechazar</Button>]}
                >
                    <Description style={{marginBottom: '20px', fontStyle: 'normal'}}>
                        El usuario podrá ver el motivo del rechazo, esto evitará malentendidos y abre la posibilidad a correcciones en la solicitud de ingreso.
                    </Description>
                    <Input.TextArea onChange={onRejectReasonChange} placeholder="Escribe tus comentarios..." autoSize={{ minRows: 2, maxRows: 6 }} />
                </Modal>
                <Button style={{marginLeft: "15px"}} loading={rejectLoading} className="ant-btn-form" onClick={onRejectClick}><DislikeOutlined/> {!minimized && "Rechazar"}</Button>
            </RejectButtonWrapper>
    );
};

const RejectButtonWrapper = styled.div``;

const mapStateToProps = state => ({
    ...state
});
const mapDispatchToProps = dispatch => ({
    fetchNotificationsCount: () => dispatch(fetchCurrentMemberRequestsCount)
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingRequestActionButton);
