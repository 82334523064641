import React, {useState} from "react";
import App from "../../utils/App";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {Button, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import styled from "styled-components";

const UploadDropdown = ({onChange, src = null, title = "", type = "image", fileName = ""}) => {

    const defaultFileList = !!src ? [{
        uid: '-1',
        url: src,
        name: `${fileName}.${src.split('?')[0].split('.').pop()}`,
        status: 'done'
    }] : [];

    const [fileList, setFileList] = useState(defaultFileList);
    const [imageLoading, setImageLoading] = useState(false);

    const getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    };

    const beforeUpload = (file) => {
        setImageLoading(true);
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            App.showError('El archivo no puede pesar más de 1 MB!');
        }
        if(type === "image") {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                App.showError('Solo puedes subir imagenes con formato png o jpg!');
            }
        }
        try {
            getBase64(file, base64 => {
                setFileList([{
                    uid: '-1',
                    url: base64,
                    status: 'done'
                }]);
                setImageLoading(false);

                // trim file extension
                const fileName = file.name.replace(/\.[^/.]+$/, "");

                onChange({imageUrl: base64, imageLoading: imageLoading, fileName: fileName });
            })
        }
        catch(error) {
            setImageLoading(false);
            App.showError('Ha ocurrido un error al intentar subir la imagen')
        }
        //Upload files manually after beforeUpload returns false;
        return false;
    };

    const uploadButton = () => {
        return (imageLoading ? <LoadingOutlined /> : <PlusOutlined />);
    };

    const showUploadList = {
    };

    const onRemove = () => {
        setFileList([]);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <UploadContainer>
            <label>{title}</label>
            {type === "image" ?
                <ImgCrop rotate modalOk={'Aceptar'} modalCancel={'Cancelar'} modalTitle={'Edita la imagen'}>
                    <Upload
                        maxCount={1}
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        onPreview={onPreview}
                        onRemove={onRemove}
                    >
                        {fileList.length < 1 && uploadButton()}
                    </Upload>
                </ImgCrop>
            :
                <Upload
                    maxCount={1}
                    listType="picture"
                    defaultFileList={defaultFileList}
                    showUploadList={showUploadList}
                    beforeUpload={beforeUpload}>
                    <Button className="ant-btn-form" icon={<UploadOutlined color={'#8e1eff'} />}>Subir archivo</Button>
                </Upload>
            }
        </UploadContainer>
    );
};

const UploadContainer = styled.div`
  label {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }
  .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    border: none;
  }
  .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn:hover {
    border: none;
  }
  margin-bottom: 20px;
`;

export default UploadDropdown;
