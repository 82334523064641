import React, {useState} from "react";
import styled from "styled-components";
import { Select, Spin, Row, Col, Button, Tag } from "antd";
import Card from "../layout/Card";
import * as Membership from "../../utils/Member";
import Api from "../../utils/Api";
import App from "../../utils/App";
import { ENTITY, MEMBER } from "../../constants";
import {injectIntl} from "react-intl";
import DeleteEntityButton from "../entity/DeleteEntityButton";

const ResidentMembership = ({
    residentMembership,
    intl,
    onDelete = ()=>{},
    onSubmit = ()=>{}
}) => {
    const [loading, setLoading] = useState(false);
    const [roleSelected, setRoleSelected] = useState(residentMembership?.role || null);
    const [editMode, setEditMode] = useState(false);
    const [membership, setMembership] = useState(residentMembership);

    const onRoleChange = (value) => {
        setRoleSelected(value);
    }

    const submitChanges = async () => {
        setLoading(true);
        const data = {
            resident_uuid: membership?.resident?.uuid,
            status: MEMBER.STATUS.ACTIVE,
            role: roleSelected,
        }
        try {
            const newMembership = await Membership.updateMembershipByUuid(membership?.uuid, data);
            onSubmit(newMembership);
            const tempMembership = membership;
            tempMembership.role = newMembership.role;
            tempMembership.status = newMembership.status;
            setMembership(tempMembership);
            App.showSuccess('El residente ha sido actualizado correctamente.');
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setEditMode(false);
            setLoading(false);
        }
    };
    
    const onEditModeClick = () => {
        setEditMode(true);
    }

    const onEditModeCancel = () => {
        setEditMode(false);
    }

    const onSubmitClick = () => {
        submitChanges();
    }

    // useEffect(() => {
    //     if(!!roleSelected && roleSelected !== membership?.role) {
    //         submitChanges();
    //     }
    // }, [roleSelected]);

    const onDeleteSubmit = async () => {
        try {
            await Membership.deleteMembershipByUuid(membership?.uuid);
            onDelete();
            App.showSuccess('El residente ha sido actualizado correctamente.');
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Wrapper>
            <Spin spinning={loading}>
                <Card style={{paddingTop: '10px', paddingBottom: '10px'}}>
                    <Container>
                        <Row justify="flex-start" align="middle" gutter={{
                            xs: 0,
                            sm: 0,
                            md: 2,
                            lg: 4
                        }}>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Item>
                                    <h4 style={{marginBottom: 0}}>{membership?.account?.section?.name} {membership?.account?.number}</h4>
                                </Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Item>
                                    {editMode ?
                                    <Select
                                    defaultValue={membership?.role || null}
                                    placeholder="Selecciona una de las siguientes opciones"
                                    onChange={onRoleChange}
                                    >
                                        {App.getCatalogListAsArray(MEMBER.ROLE).map(
                                            role =>
                                                <Select.Option key={`member-role-${role}`}
                                                        value={role}>{intl.formatMessage({id:`member.role.${role}`})}
                                                </Select.Option>
                                        )}
                                    </Select>:
                                    <Tag>{intl.formatMessage({id:`member.role.${membership?.role}`})}</Tag>
                                }
                                </Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {editMode ? 
                                        <>
                                            <Button onClick={onEditModeCancel} className="ant-btn ant-btn-form">Cancelar</Button>
                                            <Button onClick={onSubmitClick} style={{marginLeft: '5px'}} type="primary">Guardar cambios</Button>
                                        </>
                                        
                                        :
                                        <>
                                            <Button onClick={onEditModeClick} className="ant-btn ant-btn-form">Editar</Button>
                                            <DeleteEntityButton
                                                modalTitle={`${membership?.resident?.user?.first_name} ${membership?.resident?.user?.last_name} perderá lo siguiente:`}
                                                modalContent={() => <span>
                                                        <ul>
                                                            <li>Acceso a la residencia <b>{membership?.account?.section?.name} {membership?.account?.number}</b></li>
                                                            <li>Autorización de visitas</li>
                                                            <li>Pagos de mantenimiento</li>
                                                            <li>Notificaciones</li>
                                                        </ul>
                                                    </span>}
                                                uuid={membership.uuid}
                                                onSubmit={onDeleteSubmit}
                                                containerStyle={{marginLeft: '5px'}}
                                                entity={ENTITY.TYPE.MEMBERS} block danger>
                                                Eliminar
                                            </DeleteEntityButton>
                                        </>
                                    }
                                </Item>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </Spin>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
    .ant-select {
        width: 100%;
    }
`;
const Container = styled.div`
`;
const Item = styled.div`
    display: flex;
    align-items: center;
`;

export default injectIntl(ResidentMembership);