import React from "react";
import styled from "styled-components";
import {ENTITY, ROUTE} from "../../constants";
import {useIntl} from "react-intl";
import EntityDataTable from "../../components/entity/EntityDataTable";
import LayoutContainer from "../../components/layout/Container";
import {PlusOutlined} from "@ant-design/icons";
import EntityDataTableActions from "../../components/entity/EntityDataTableActions";
import EntityDataTableActionsSubtitle from "../../components/entity/EntityDataTableActionsSubtitle";
import { Membership, User } from "../../types";
// import NavigateButton from "../../components/NavigateButton";
// import { GridCellParams } from "@mui/x-data-grid";
// import Chip from "../../components/Chip";
// import Typography from "../../components/Typography";
import { Tag, Avatar } from "antd";
import LinkButton from "../../components/layout/LinkButton";

const Residents = () => {
    const intl = useIntl();
    // const columns2 = [
    //     {
    //         field: 'user',
    //         headerName: 'Nombre',
    //         flex: 0.33,
    //         sortable: false,
    //         renderCell: (params: GridCellParams) => {
    //             const user: User = params.row.user;
    //             return (
    //                 <>
    //                 <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.profile_image?.url} />
    //                 <Typography><b style={{marginLeft: '10px'}}>{user.first_name} {user.last_name}</b></Typography>
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         field: 'residence',
    //         headerName: 'Residencia',
    //         flex: 0.33,
    //         sortable: false,
    //         renderCell: (params: GridCellParams) => {
    //             const memberships: Membership[] = params.row.memberships || [];
    //             return memberships.map(membership => <Chip label={`${membership?.account?.section?.name} ${membership?.account?.number}`} key={membership.uuid} />)
    //         }
    //     },
    //     {
    //         field: 'role',
    //         headerName: 'Rol',
    //         flex: 0.33,
    //         sortable: false,
    //         renderCell: (params: GridCellParams) => {
    //             const role: number | string = params.row.role;
    //             return <Typography>{intl.formatMessage({id: `resident.role.${role}`})}</Typography>
    //         }
    //     }
    // ]

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'user',
            render: (record: User, index: number) =>
                <React.Fragment>
                    <Avatar style={{fontSize: "18px"}} size={40} src={record?.profile_image?.url}>
                        {!!record?.first_name && record?.first_name?.length > 0 && record?.first_name[0]}
                    </Avatar>
                    <b style={{marginLeft: '10px'}}>{record?.first_name} {record?.last_name}</b>
                </React.Fragment>,
        },
        {
            title: 'Residencia',
            dataIndex: 'memberships',
            render: (memberships: Membership[]) => {
                if(!!memberships && Array.isArray(memberships)) {
                    return memberships.map(membership => <Tag key={membership.uuid}>{membership?.account?.section?.name} {membership?.account?.number}</Tag>)
                }
                return null;
            },
        },
        {
            title: 'Teléfono',
            dataIndex: 'user',
            responsive: ['xxl'],
            render: (user: User) => user?.phone,
        },
        {
            title: 'Correo electrónico',
            dataIndex: 'user',
            responsive: ['xxl'],
            render: (user: User) => user?.email,
        },
        {
            title: 'Rol',
            dataIndex: 'role',
            responsive: ['lg'],
            render: (role: number | string) => (
                <span>
                <Tag>{intl.formatMessage({id:`resident.role.${role}`})}</Tag>
            </span>
            ),
        }
    ];
    return (
        <LayoutContainer>
            <Wrapper>
                <EntityDataTableActions>
                    <EntityDataTableActionsSubtitle>Directorio de Residentes</EntityDataTableActionsSubtitle>
                    {/* <NavigateButton
                        to={`${ROUTE.ROUTES.RESIDENT_INVITE}`} 
                        variant="contained"
                        startIcon={<PlusOutlined />}>
                        Nuevo Residente
                    </NavigateButton> */}
                    <LinkButton to={ROUTE.ROUTES.RESIDENT_INVITE}>
                        <PlusOutlined /> Nuevo Residente
                    </LinkButton>
                </EntityDataTableActions>
                <Container>
                    <EntityDataTable
                        showSearch={true}
                        searchPlaceholder="Busca por nombre, residencia, teléfono, o correo electrónico..."
                        entity={ROUTE.ROUTES.RESIDENT}
                        emptyTitle={'¡No hay tienes residentes!'}
                        emptyMessage={'Puedes ver su información de contacto,  estatus, residencia actual... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.RESIDENTS} />
                </Container>
            </Wrapper>
        </LayoutContainer>
    );
};

const Wrapper = styled.div`
`;
const Container = styled.div``;

export default Residents;
