import React, { ErrorInfo } from "react";

type Props = {
    message: string
}
type State = {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<Props, State> {

    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error):State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log('Uncaught error: ', error);
        this.setState({ hasError: true });
        const warning = `${error.name}: ${error.message}, ${errorInfo.componentStack?.split('\n')[1]}`;
        console.log(warning);
    }

    public render(): React.ReactNode {
        if(this.state.hasError) {
            // You can render any custom fallback UI
            return <p>{ this.props.message }</p>
        }

        return <>{this.props.children}</>;
    }
}