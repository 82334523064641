import React from "react";
import styled from "styled-components";
import {ENTITY, ROUTE} from "../constants";
import EntityDataTable from "../components/entity/EntityDataTable";
import {injectIntl} from "react-intl";
import Container from "../components/layout/Container";

const AccountActivity = props => {
    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            render: (name) => <b>{name}</b>
        }
    ];
    return (
        <Container>
            <EntityWrapper>
                <EntityContainer>
                    <EntityDataTable
                        entity={ROUTE.ROUTES.ACCOUNT_ACTIVITY}
                        emptyTitle={'¡Aún no tienes actividad!'}
                        emptyMessage={'Aquí podrás ver la actividad de tu cuenta... ¡Y mucho más!'}
                        columns={columns}
                        entityName={ENTITY.TYPE.ACCOUNT_ACTIVITY} />
                </EntityContainer>
            </EntityWrapper>
        </Container>
    );
};

const EntityWrapper = styled.div`
`;
const EntityContainer = styled.div``;

export default injectIntl(AccountActivity);
