import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Progress, Statistic} from "antd";
import * as AnalyticsUtils from "../../utils/Analytics";
import {Spin, Row, Col} from "antd";
import App from "../../utils/App";
import Api from "../../utils/Api";

const AdoptionProgress = props => {
    const [adoptionStatistics, setAdoptionStatistics] = useState({
        totalAccounts: 0,
        totalActiveAccounts: 0,
        totalMembers: 0
    });
    const [loading, setLoading] = useState(true);
    const fetchAdoptionAnalytics = async () => {
        try {
            const adoption = await AnalyticsUtils.getAdoption();
            setAdoptionStatistics({
                totalAccounts: adoption.totalAccounts ,
                totalActiveAccouns: adoption.totalActiveAccounts,
                totalMembers: adoption.totalMembers
            });
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAdoptionAnalytics().then()
    },[]);
    const getProgressColor = () => {
        const percent = getAdoptionPercent();
        if(percent < 75) {
            return 'var(--status-warning-color)';
        }
        else if(percent >= 75) {
            return 'var(--status-success-color)';
        }
    };
    const getAdoptionPercent = () => {
        return Math.floor((Number(adoptionStatistics.totalActiveAccouns)/Number(adoptionStatistics.totalAccounts))*100);
    };
    return (
        <AdoptionProgressWrapper>
            <Spin spinning={loading}>
                <Row jusify="space-between" align="middle">
                    <Col xs={24} sm={24} md={10} lg={10} >
                        <StatisticsContainer>
                            <Progress
                                width={150}
                                type="circle"
                                status="active"
                                strokeColor={getProgressColor()}
                                percent={getAdoptionPercent()}
                            />
                        </StatisticsContainer>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} >
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <StatisticsContainer>
                                    <Statistic title="Residencias" value={adoptionStatistics.totalActiveAccouns}
                                               // suffix={() => <span>/ ${adoptionStatistics.totalAccounts}</span>}
                                    />
                                </StatisticsContainer>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <StatisticsContainer>
                                    <Statistic title="Usuarios" value={adoptionStatistics.totalMembers} />
                                </StatisticsContainer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </AdoptionProgressWrapper>
    );
};

const AdoptionProgressWrapper = styled.div`
  width: 100%;
`;
const StatisticsContainer = styled.div`
  @media(max-width: 768px) {
      display: flex;
      justify-content: center;
      align-content: center;
      text-align: center;
      margin-bottom: 15px;
  }
`;
export default AdoptionProgress;
