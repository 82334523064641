import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MemberListRow from "./MemberListRow";
import {Spin, Pagination} from "antd";
import App from "../../utils/App";
import Api from "../../utils/Api";
import Lottie from "react-lottie";
import emptyRequestsAnimation from "../../assets/lottie/empty/19243-phone-book";
import { getAllActiveMembersByUuid } from "../../utils/Account";

const MemberList = props => {
    const {lengthBeforePaging, accountUuid} = props;
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchMembers().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchMembers = async () => {
        setLoading(true);
        try {
            const members = (await getAllActiveMembersByUuid(accountUuid)).data;
            setMembers(members);
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setLoading(false);
        }
    };
    return (
        <MemberListWrapper>
            <Spin spinning={loading}>
                {members.length > 0 ?
                    <MemberListContainer>
                        {members.map(member =>
                            <MemberListRow
                                key={member.uuid}
                                member={member}/>)}
                        {members.length > lengthBeforePaging &&
                        <PagintionContainer>
                            <Pagination defaultCurrent={1} total={50} />
                        </PagintionContainer>
                        }
                    </MemberListContainer> :
                    !loading ?
                        <EmptyListContainer>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: emptyRequestsAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                width={250}
                            />
                            <h3>No hay usuarios activos</h3>
                        </EmptyListContainer> : null
                }
            </Spin>
        </MemberListWrapper>
    );
};

const MemberListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;
const PagintionContainer = styled.div`
  margin-top: 15px;
`;
const EmptyListContainer = styled.div`
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  padding: 25px;
`;
const MemberListContainer = styled.div``;
MemberList.defaultProps = {
    lengthBeforePaging: 5
};
export default MemberList;
