import React, {useEffect, useState} from "react";
import {Button} from "antd";
import {getDate} from "../../utils/Date";
import moment from "moment"
const TimerButton = (props) => {
    const {
        buttonProps = {},
        timerDate = getDate(),
        loading = false,
        onClick = () => {},
    } = props;
    const getETA = (unitOfTime = "minutes") => {
        const anHourFromTimerDate = moment(timerDate).clone().utc().add(1, 'hours');
        return anHourFromTimerDate.diff(moment().utc(), unitOfTime, false);
    };
    const [timeLeft, setTimeLeft] = useState(getETA());
    useEffect(() => {
        if(!timeIsUp()) {
            const timer = setInterval(() => {
                setTimeLeft(getETA());
            }, 1000 * 60);
            return () => clearInterval(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const timeIsUp = () => {
        return getETA() <= 0;
    };
    return (
        <Button {...buttonProps} onClick={onClick} loading={loading} disabled={!timeIsUp()}>
            {timeIsUp() ? props?.children : `Disponible en ${timeLeft} minuto(s)`}
        </Button>
    );
};

export default TimerButton;
