import React from "react";
import styled from "styled-components";

const EntityDataTableActionsSubtitle = React.memo((props) => <Wrapper>{props.children}</Wrapper>);
const Wrapper = styled.h3`
  align-self: flex-end;
  flex-grow: 1;
`;

export default EntityDataTableActionsSubtitle;
