import Api from "./Api";
import {VISIT} from "../constants";
import {createIntl, createIntlCache} from "react-intl";
import AppLocale from '../translations';
const currentAppLocale = AppLocale['es'];
const cache = createIntlCache();
const intl = createIntl({
    locale: currentAppLocale.locale,
    messages: currentAppLocale.messages
}, cache);

export const getVisits = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/visits', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getVisit = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/visits/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
export const getStatusColor = (status) => {
    switch (status) {
        case VISIT.STATUS.APPROVED:
        case VISIT.STATUS.CHECK_IN:
            return "success";
        case VISIT.STATUS.PENDING:
            return "processing";
        case VISIT.STATUS.DENIED:
        case VISIT.STATUS.CHECK_OUT:
            return "error";
        case VISIT.STATUS.PRE_APPROVED:
            return "geekblue";
        case VISIT.STATUS.NOT_ANSWERED:
        case VISIT.STATUS.NOT_ANSWERED_2:
        case VISIT.STATUS.NOT_ANSWERED_3:
            return "warning";
        default:
            return "default";
    }
};
export const getStatusIcon = (status) => {
    return null;
    // switch (status) {
    //     case VISIT.STATUS.APPROVED:
    //         return <CheckCircleOutlined />;
    //     case VISIT.STATUS.PENDING:
    //         return <SyncOutlined spin />;
    //     case VISIT.STATUS.DENIED:
    //         return <CloseCircleOutlined />;
    //     case VISIT.STATUS.PRE_APPROVED:
    //         return <ClockCircleOutlined />;
    //     case VISIT.STATUS.NOT_ANSWERED:
    //     case VISIT.STATUS.NOT_ANSWERED_2:
    //     case VISIT.STATUS.NOT_ANSWERED_3:
    //         return <MinusCircleOutlined />;
    //     default:
    //         return null;
    // }
};
export const getVisitName = (visit) => {
    if(visit.type === VISIT.TYPE.DELIVERY && !visit.name) {
        return intl.formatMessage({id: `visit.type.${visit.type}`})
    }
    return (`${!!visit.name ? visit.name : '-'}`)
};
export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/visit/${uuid}`, params)).data.data);
        }
        catch(error) {
            reject(error);
        }
    });
};
