import React, { useState } from "react";
import styled from "styled-components";
import CompleteSignUpForm, { CompleteSignUpSubmitData } from "../../../forms/CompleteSignUpForm";
import { RootState } from "../../../redux/store";
import Api from "../../../utils/Api";
import App from "../../../utils/App";
import Layout from "../../../components/layout/Layout";
import Card from "../../../components/layout/Card";
import Header from "../../../components/layout/Header";
import { updateUser } from "../../../utils/User";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setAuthIsNewUser, setAuthUser } from "../../../redux/actions/authActions";
import { UserRequestData } from "../../../types";

const CompleteSignUp = () => {
    const auth = useAppSelector( (state: RootState) => state.auth);
    const dispatch = useAppDispatch();
    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async (data: CompleteSignUpSubmitData) => {
        try {
            setSubmitLoading(true);
            let userData: UserRequestData = {
                first_name: data.first_name,
                last_name: data.last_name,
                registered: 1
            };

            // add missing fields if required
            if(!auth.user?.email) {
                userData = {...userData, email: data.email};
            }
            if(!auth.user?.phone) {
                userData = {...userData, phone: data.phone, country_code: data.country_code};
            }
            
            const user = await updateUser(userData);
            dispatch(setAuthUser(user));
            dispatch(setAuthIsNewUser(false));
        }
        catch(error: any) {
            console.log(error);
            App.showError(Api.parseResponseError(error));
        }
        finally {
            setSubmitLoading(false);
        }
    }
    return (
        <Layout showNavbar={false}>
            <Header />
            <Wrapper>
                <Container>
                    <Card>
                        <h1>Finaliza tu registro</h1>
                        <CompleteSignUpForm 
                            initialValues={{
                                first_name: auth.user?.first_name,
                                last_name: auth.user?.last_name,
                                phone: auth.user?.phone,
                                country_code: auth.user?.country_code || "52",
                                email: auth.user?.email,
                            }} 
                            onSubmit={onSubmit} 
                            loading={submitLoading}
                        />
                    </Card>
                </Container>
            </Wrapper>           
        </Layout>
    );    
};

const Wrapper = styled.div`
  height: calc(100vh - 98px);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
`;
const Container = styled.div`
    width: 100%;
`;

export default CompleteSignUp;