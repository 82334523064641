import { getAuth, signOut, onAuthStateChanged, getRedirectResult, getAdditionalUserInfo } from "firebase/auth";
import Api from "./Api";
import {AUTH} from "../constants";
import { reject } from "lodash";
import App from "./App";
import store from "../redux/store";
import { setAuthIsNewUser, setAuthLoading, setAuthUser } from "../redux/actions/authActions";
import { User } from "../types";
import { destructPhoneNumberData } from "./phoneNumber";

export const REFRESH_TOKEN_TIMEOUT = 3000000; // 50 minutes in ms

export default class Auth {
    login = (username: string, password: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const login = (await Api.post('/auth/login', null, {username, password}));
                const loginToken = login?.data?.data?.token;
                localStorage.setItem(AUTH.ACCESS_TOKEN, loginToken);
                const user = (await this.getUser()).data.data;
                resolve(user);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    memberLogin = (memberUuid:string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const login = (await Api.adminPost(`/members/authorize/${memberUuid}`, null, {client: 'web'})).data.data;
                localStorage.setItem(AUTH.MEMBER_ACCESS_TOKEN, login.token);
                resolve(login.member);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    signout = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const auth = getAuth();
                await signOut(auth);
                this.cleanUpMember();
                this.cleanUpResident();
                this.cleanUpUser();
                resolve(null);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    cleanUpMember = () => {
        localStorage.removeItem(AUTH.MEMBER_ACCESS_TOKEN);
        localStorage.removeItem(AUTH.MEMBER);
        localStorage.removeItem(AUTH.CLIENT);
    };
    cleanUpResident = () => {
        localStorage.removeItem(AUTH.RESIDENT);
        localStorage.removeItem(AUTH.RESIDENTSHIPS);
        localStorage.removeItem(AUTH.CLIENT);
    };
    cleanUpUser = () => {
        localStorage.removeItem(AUTH.ACCESS_TOKEN);
        localStorage.removeItem(AUTH.USER);
    };
    cleanUpSession = () => {
        this.cleanUpMember();
        this.cleanUpUser();
        this.cleanUpResident();
    };
    accountLogout = () => {
        return new Promise((resolve) => {
            this.cleanUpMember();
            resolve(null);
        });
    };
    isLoggedIn = async () => {
        if (localStorage.getItem(AUTH.ACCESS_TOKEN)) {
            try {
                return (await this.getUser()).data.data;
            } catch (error) {
                return null;
            }
        }
        return null;
    };
    residentLogout = () => {
        return new Promise((resolve) => {
            try {
                this.cleanUpResident();
                resolve(null);
            }
            catch(error: any) {
                console.log(error);
                reject(error);
            }
        });
    };

    getAccessToken = async (forceRefresh = false) => {
        try {
            const auth = getAuth();

            // @remarks
            // Returns the current token if it has not expired or if it will not expire in the next five minutes. 
            // Otherwise, this will refresh the token and return a new one.
            //
            // @param forceRefresh — Force refresh regardless of token expiration.
            const accessToken = await auth?.currentUser?.getIdToken(forceRefresh);
            
            return accessToken;
        }
        catch(error) {
            throw error;
        }
    }

    setAutomaticAccessTokenRefreshInterval() {
        const ms = REFRESH_TOKEN_TIMEOUT;
        const forceRefresh = true;
        return setInterval( async () => {
            // this will trigger firebase onIdTokenChanged
            this.getAccessToken(forceRefresh);
        }, ms);
    }

    setIsSignedInObserver = ({
        isSignedIn = (currentUser: any) => {},
        isSignedOut = () => {},
        sessionWasRefreshed = (currentUser: any) => {}
    }) => {
        const auth = getAuth();

        // set refresh token observer
        auth.onIdTokenChanged(async firebaseUser => {

            // FirebaseUser is signed in or token was refreshed.
            if(firebaseUser) {
                try {

                    // new user
                    const redirectResult = await getRedirectResult(auth);
                    let isNewUser = false;   
                    let additionalInfo = null;
                    if(redirectResult) {
                        additionalInfo = getAdditionalUserInfo(redirectResult);
                        isNewUser = additionalInfo?.isNewUser || false;
                    }

                    // persist latest token
                    const accessToken = await firebaseUser.getIdToken();
                    localStorage.setItem(AUTH.ACCESS_TOKEN, accessToken);

                    if(!isNewUser) {
                        // fetch currentUser
                        const currentUser: User = await this.getCurrentUser();

                        // persist currentUser in local storage
                        localStorage.setItem(AUTH.USER, JSON.stringify(currentUser));

                        // run cb
                        sessionWasRefreshed(currentUser);
                    }
                    else {
                        let userData = {};
                        if(firebaseUser.email) {
                            userData = {email: firebaseUser.email, ...userData};
                        }
                        if(firebaseUser.phoneNumber) {
                            console.log(firebaseUser.phoneNumber)
                            console.log(destructPhoneNumberData(firebaseUser.phoneNumber))
                             const phoneNumberData = destructPhoneNumberData(firebaseUser.phoneNumber);
                            userData = {phone: phoneNumberData.nationalPhoneNumber, country_code: phoneNumberData.countryCode, ...userData};
                        }
                        if(additionalInfo?.profile?.family_name) {
                            userData = {last_name: additionalInfo?.profile?.family_name, ...userData};
                        }
                        if(additionalInfo?.profile?.given_name) {
                            userData = {first_name: additionalInfo?.profile?.given_name, ...userData};
                        }
                        store.dispatch(setAuthUser(userData));
                        store.dispatch(setAuthIsNewUser(isNewUser));
                        store.dispatch(setAuthLoading(false));
                    }
                }
                catch(error: any) {
                    if(!!error?.response?.status) {
                        if(error.response.status === 403) {
                            // this.cleanUpSession();
                            // auth.signOut();

                            // App.showError('El usuario no existe.');
                        }
                        else {
                            
                        }
                        console.log(error?.message);
                        App.showError(Api.parseResponseError(error));
                    }
                }
            }
            else {
                // clean up
                // this.cleanUpSession();

                // reload
                // window.location.reload();
            }
        })

        // set session observer
        onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                try {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User

                    // fetch new accessToken with refreshToken
                    const accessToken = await firebaseUser.getIdToken();
                    const currentAccessToken = localStorage.getItem(AUTH.ACCESS_TOKEN);

                    // get current user from local storage, if any
                    let currentUser = localStorage.getItem(AUTH.USER) || null;

                    // if token was refreshed
                    if(currentAccessToken !== accessToken) {

                        // persist accessToken
                        localStorage.setItem(AUTH.ACCESS_TOKEN, accessToken);

                        // fetch currentUser
                        currentUser = await this.getCurrentUser();
                            
                        // persist currentUser in local storage
                        localStorage.setItem(AUTH.USER, JSON.stringify(currentUser));
                    }
                    // if access token is the same use the user stored in local storage
                    else if(!!currentUser && currentAccessToken === accessToken) {

                        // get currentUser from local storage
                        currentUser = JSON.parse(currentUser);
                    }
                    // if access token is the same but no user was stored in local storage
                    else if(!currentUser && currentAccessToken === accessToken) {

                        // fetch currentUser
                        currentUser = await this.getCurrentUser();

                        // persist user in local storage
                        localStorage.setItem(AUTH.USER, JSON.stringify(currentUser));
                    }
                    
                    // run cb
                    isSignedIn(currentUser);
                }
                catch(error) {
                    throw error;
                }
              } else {
                // user is signed out
                isSignedOut();
              }
        });
    };
    getUser = () => {
        return Api.adminGet("/users/me");
    };
    getCurrentUser = async () => {
        return (await Api.adminGet("/users/me")).data;
    };
    userIsAllowed(user: any, allowed: string[]) {
        if(allowed.length > 0) {
            const isAllowed = (role:string) => allowed.indexOf(role) > -1;
            return isAllowed(user.type);
        }
        return true;
    }
    getAuthUserMemberships = () => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.adminGet('/users/me/memberships')).data.data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
}
