import {useRef, useEffect, useCallback} from "react";
import _ from "lodash"

// type F = () => void

export default function useDebounce(cb: any, delay: number) {
    const options = {
        leading: false,
        trailing: true
    };
    const isMounted = useIsMounted();
    const inputsRef = useRef(cb); // mutable ref like with useThrottle
    useEffect(() => { inputsRef.current = { cb, delay }; }); //also track cur. delay

    // fixes warning: React Hook useCallback received a function whose dependencies are unknown.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        _.debounce((...args) => {
                // Debounce is an async callback. Cancel it, if in the meanwhile
                // (1) component has been unmounted (see isMounted in snippet)
                // (2) delay has changed
                if (inputsRef.current.delay === delay && isMounted())
                    inputsRef.current.cb(...args);
            }, delay, options
        ),
        [delay, _.debounce]
    );
}

function useIsMounted() {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    return () => isMountedRef.current;
}
