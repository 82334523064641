import Api from "./Api";

export default class NoticeBoard {
    get = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get('/notice-board', params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    getByUuid = uuid => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.get(`/notice-board/${uuid}`)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    updateByUuid = (uuid, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.put(`/notice-board/${uuid}`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    create = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.post(`/notice-board`, null, data)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
    deleteByUuid = (uuid, params) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve((await Api.delete(`/notice-board/${uuid}`, params)).data);
            }
            catch(error) {
                reject(error);
            }
        });
    };
}
