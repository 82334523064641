import { PhoneNumberUtil } from "google-libphonenumber";

export function verifyPhoneNumber(phone: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);

    return phoneUtil.isValidNumber(phoneNumber);
}

export function destructPhoneNumberData(phone: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);

    return {countryCode: phoneNumber.getCountryCode(), nationalPhoneNumber: phoneNumber.getNationalNumber()}
}