import { AUTH } from "../../constants";
import * as types from "../actionTypes";

export type Action = {
    type: string,
}

export function setAuthUser(user: any) {
    localStorage.setItem(AUTH.USER, JSON.stringify(user));
    return {
        type: types.SET_AUTH_USER,
        user
    }
}
export function setAuthSubscriptionStatus(subscriptionStatus: any) {
    return {
        type: types.SET_AUTH_SUBSCRIPTION_STATUS,
        subscriptionStatus
    }
}
export function setAuthLoading(loading: boolean) {
    return {
        type: types.SET_AUTH_LOADING,
        loading
    }
}
export function setAuthIsNewUser(isNewUser: boolean = false) {
    return {
        type: types.SET_AUTH_IS_NEW_USER,
        isNewUser
    }
}
export function setAuthMember(member: any) {
    localStorage.setItem(AUTH.MEMBER, JSON.stringify(member));
    return {
        type: types.SET_AUTH_MEMBER,
        member
    }
}
export function setAuthMemberships(memberships: any) {
    return {
        type: types.SET_AUTH_MEMBERSHIPS,
        memberships
    }
}
export function setAuthClient(client: any) {
    localStorage.setItem(AUTH.CLIENT, JSON.stringify(client));
    return {
        type: types.SET_AUTH_CLIENT,
        client
    }
}
export function setAuthResident(resident: any) {
    localStorage.setItem(AUTH.RESIDENT, JSON.stringify(resident));
    return {
        type: types.SET_AUTH_RESIDENT,
        resident
    }
}
export function setAuthAccount(account: any) {
    localStorage.setItem(AUTH.ACCOUNT, JSON.stringify(account));
    return {
        type: types.SET_AUTH_RESIDENT,
        account
    }
}
export function setAuthResidentships(residentships: any) {
    return {
        type: types.SET_AUTH_RESIDENTSHIPS,
        residentships
    }
}
