import React, {useState} from "react";
import styled from "styled-components";
import {Button, Modal} from "antd";
import * as entityUtil from "../../utils/Entity";
import App from "../../utils/App";
import Api from "../../utils/Api";
import {WarningFilled} from "@ant-design/icons";

const EntityStatusButton = (props) => {
    const {
        dangerModalContent = () => {},
        successModalContent = () => {},
        successModalTitle="",
        dangerModalTitle="",
        onSubmit = () => {},
        entity,
        uuid,
        defaultStatus,
        successStatus,
        dangerStatus,
        modalSuccessStatusText = "Permitir",
        modalDangerStatusText = "Negar",
    } = props;
    const [submitLoading, setSubmitLoading] = useState(false);
    const [status, setStatus] = useState(defaultStatus);
    const onOk = async () => {
        setSubmitLoading(true);
        const newStatus = status === successStatus ? dangerStatus : successStatus;
        try {
            await entityUtil.updateEntity(entity, uuid, {
                status: newStatus
            });
            setSubmitLoading(false);
            setStatus(newStatus);
            onSubmit();
            App.showSuccess('El estatus se ha actualizado correctamente')
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
    };
    const onButtonClick = () => {
        Modal.confirm({
            title: status === dangerStatus ? successModalTitle : dangerModalTitle,
            icon: <WarningFilled />,
            cancelButtonProps: {className:"ant-btn-form"},
            okButtonProps: {className:status === successStatus ? "ant-btn-danger" : "ant-btn-success"},
            content: status === dangerStatus ? successModalContent() : dangerModalContent(),
            okText: status === dangerStatus ? modalSuccessStatusText : modalDangerStatusText,
            okType: "default",
            cancelText: 'Cancelar',
            confirmLoading: submitLoading,
            onOk: onOk,
        });
    };
    return (
        <EntityButtonWrapper>
            <Button onClick={onButtonClick} className={status === successStatus ? "ant-btn-danger" : "ant-btn-success"}>
                {status === dangerStatus ? modalSuccessStatusText : modalDangerStatusText}
            </Button>
        </EntityButtonWrapper>
    );
};

const EntityButtonWrapper = styled.div`
  margin: 20px 0;
  
`;

export default EntityStatusButton;
