import Api from "./Api";

export const getSections = (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get('/sections', params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const getByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.get(`/sections/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const updateByUuid = (uuid, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.put(`/sections/${uuid}`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const create = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.post(`/sections`, null, data)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};

export const deleteByUuid = (uuid, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve((await Api.delete(`/sections/${uuid}`, params)).data);
        }
        catch(error) {
            reject(error);
        }
    });
};
