import React from "react";
import styled from "styled-components";
import {Tag} from "antd";
import {LikeOutlined, DislikeOutlined} from "@ant-design/icons";

const RECOMMENDED_RATING_MIN = 3;

const Rating = (props) => {
    const {
        rating = 0,
        tagProps = {},
    } = props;
    const getRatingTagColor = () => {
        if(rating < RECOMMENDED_RATING_MIN) {
            return "error";
        }
        else if(rating >= RECOMMENDED_RATING_MIN) {
            return "success";
        }
    };
    const getRatingText = () => {
        if(rating < RECOMMENDED_RATING_MIN) {
            return "No Recomendado";
        }
        else if(rating >= RECOMMENDED_RATING_MIN) {
            return "Recomendado";
        }
    };
    const getIcon = () => {
        if(rating < RECOMMENDED_RATING_MIN) {
            return <DislikeOutlined />;
        }
        else if(rating >= RECOMMENDED_RATING_MIN) {
            return <LikeOutlined/>;
        }
    };
    return (
        <Wrapper {...props}>
            <Tag icon={getIcon()} {...tagProps} color={getRatingTagColor()}>{getRatingText()}</Tag>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

export default Rating;
