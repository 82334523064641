import React from 'react';
import styled from 'styled-components';
import {Avatar} from 'antd';
import moment from "moment";
import Rating from "./Rating";

const Review = React.memo((props) => {
    const {reviewBody = null, ratingValue = 0, name = null, imgSource = null, date = null, accountName = null} = props;
    return (
        <ReviewWrapper {...props}>
            <ReviewContainer>
                <ReviewUserProfile>
                    <Avatar style={{fontSize: "11px"}} size={20} src={imgSource}>
                        {!!name && name.length > 0 && name[0]}
                    </Avatar>
                    <ReviewUser>{name} <AccountName> · {accountName}</AccountName></ReviewUser>
                </ReviewUserProfile>
                <Rating rating={ratingValue} />
                <span className="table-datetime">{moment(date).format('DD/MM/YY')}</span>
                <ReviewBody>{reviewBody}</ReviewBody>
            </ReviewContainer>
        </ReviewWrapper>
    );
});

const ReviewWrapper = styled.div``;
const ReviewContainer = styled.div`
  padding: 10px 0;
  .ant-rate {
    font-size: 15px !important;
  }
  .table-datetime {
    margin-left: 10px;
    font-weight: 500;
    font-size: 13px;
  }
`;
const AccountName = styled.span`
  font-weight: 500;
  font-size: 13px;
  color: var(--unselected-text-color);
`;
const ReviewBody = styled.div`
  font-weight: 500;
  padding: 5px 0;
  color: var(--unselected-text-color)
`;
const ReviewUser = styled.span`
  color: var(--third-color);
  font-weight: 500;
  margin-left: 10px;
  display: inline-block;
`;
const ReviewUserProfile = styled.div`
  margin: 5px 0;
`;

export default Review;
