import React from "react";
import PrivateRoute from "./PrivateRoute";
import {ROUTE} from "../../constants";
import PayWall from "../../pages/PayWall";
import Layout from "../layout/Layout";
import AccountSelect from "../../pages/AccountSelect";
import { Redirect } from "react-router";
const UnpaidUserRoutes = () => (
    <React.Fragment>
        <PrivateRoute exact path={ROUTE.ROUTES.ACCOUNT_SELECT} type="private">
            <Layout showNavbar={false}>
                <AccountSelect autoselect={false} />
            </Layout>
        </PrivateRoute>
        <PrivateRoute exact path={ROUTE.ROUTES.HOME} type="private">
            <PayWall />
        </PrivateRoute>
        <Redirect to={{ pathname:ROUTE.ROUTES.HOME }} />
    </React.Fragment>
);
export default UnpaidUserRoutes;
