import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { 
    useSelector, 
    // useDispatch 
} from "react-redux";
import { RootState } from "../redux/store";
import { RouteProps } from "react-router";
import { 
    getAuth, 
    linkWithRedirect, 
    getRedirectResult, 
    GoogleAuthProvider, 
    unlink, 
    User,
    UserInfo
} from "firebase/auth";
import App from "../utils/App";
import Api from "../utils/Api";
import { Button } from "antd";
import Description from "../components/layout/Description";
import { getTimeZoneDate } from "../utils/Date";

const googleProvider = new GoogleAuthProvider();

const MyProfile = (props: RouteProps) => {
    const auth:any = useSelector<RootState>((state: RootState) => state.auth);
    // const dispatch = useDispatch();

    const firebaseAuth = getAuth();
    const user: User | null = firebaseAuth?.currentUser;

    const [googleProviderLinked, setGoogelProviderLinked] = useState<UserInfo | null>(user?.providerData.filter(provider => provider.providerId === "google.com")[0] || null);

    void async function grabRedirectCredentials() {
        try {
            const redirectResult = await getRedirectResult(auth);
            if(!!redirectResult) {
                const credential = GoogleAuthProvider.credentialFromResult(redirectResult);
                if(credential) {
                    // Accounts successfully linked.
                    App.showSuccess('Tu cuenta ha sido ligada a Google correctamente');
                }
            }
        }
        catch(error) {
            App.showError(Api.parseResponseError(error));
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        void async function grabRedirectCredentials() {
            try {
                const redirectResult = await getRedirectResult(auth);
                if(!!redirectResult) {
                    const credential = GoogleAuthProvider.credentialFromResult(redirectResult);
                    if(credential) {
                        // Accounts successfully linked.
                        App.showSuccess('Tu cuenta ha sido ligada a Google correctamente');
                    }
                }
            }
            catch(error) {
                App.showError(Api.parseResponseError(error));
            }
        }
        return (() => {
            abortController.abort()
        });
    }, [auth]);

    const onGoogleLinkClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            if(!!googleProviderLinked) {
                if(!!user) {
                    await unlink(user, googleProviderLinked.providerId)
                    setGoogelProviderLinked(null);
                }
            }
            else {
                if(!!user) {
                    await linkWithRedirect(user, googleProvider);
                }
                else {
                    App.showError('User not found');
                }
            }
            
        }
        catch(error: any) {
            App.showError(Api.parseResponseError(error));
        }
    };

    return (
        <Wrapper>
            <Container>
                <h1>{auth?.user?.first_name} {auth?.user?.last_name}</h1>
                <Description>Miembro desde {getTimeZoneDate({date: auth?.user?.created_at, format: 'DD/MM/YYYY'})}</Description>
                <Button
                    onClick={onGoogleLinkClick}
                    type="default" 
                    className={!!googleProviderLinked ? 'ant-btn-danger' : 'ant-btn-form'}>
                    {!!googleProviderLinked ? 'Desligar cuenta de Google' : 'Liga una cuenta de Google'}
                </Button>
            </Container>
        </Wrapper>
    );
};

export default MyProfile;

const Wrapper = styled.div`
`;
const Container = styled.div`
`;