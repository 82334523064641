import React from "react";
import Nav from "./Nav";
import styled from "styled-components";

class Layout extends React.Component {
    state = {
        collapsed: false,
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    render() {
        return (
            <React.Fragment>
                <LayoutWrapper>
                    {this.props.showNavbar && <Nav />}
                    <LayoutContainer className={this.props.showNavbar ? '' : 'no-navbar'}>
                        {this.props.children}
                    </LayoutContainer>
                </LayoutWrapper>
            </React.Fragment>
        );
    }
}

Layout.defaultProps = {
    showNavbar: true
};

const LayoutWrapper = styled.div`
  display: flex;
`;
const LayoutContainer = styled.div`
    width: 100%;
    padding: 0 0 0 var(--nav-width);
  &.no-navbar {
    margin-left: 0;
  }
`;

export default Layout;
