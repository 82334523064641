import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {CLIENT, RESIDENT} from "../../constants";
import AuthUtils from "../../utils/Auth";
import {
    setAuthClient, 
    setAuthLoading, 
    setAuthResidentships, 
    setAuthUser
} from "../../redux/actions/authActions";
import {fetchCurrentMemberRequestsCount} from "../../redux/actions/notificationsActions";
import SplashScreen from "../../components/layout/SplashScreen";
import AppUtils from "../../utils/App";
import Api from "../../utils/Api";
import { getCurrentSubscription } from '../../utils/Clients';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

const authUtils = new AuthUtils();

type AuthProviderProps = {
    children: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {

    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);

    const location = useLocation();
    
    const updateCurrentUserStore = async (currentUser: any) => {
        dispatch(setAuthUser(currentUser));

        if(!!currentUser?.resident) {
            // set current user residentships
            const residentships = currentUser?.resident;
            if(!!residentships && Array.isArray(residentships)) {
                const adminResidentships = residentships.filter(resident => resident.role === RESIDENT.ROLE.ADMIN)
                dispatch(setAuthResidentships(adminResidentships));
            }

            // fetch current subscription status
            if(!!auth.client) {
                try {
                    await fetchCurrentClientSubscription();
                }
                catch(error) {
                    throw error;
                }
            }
        }
        
    }

    // on location/route change
    useEffect(() => {
        // setSessionObserver
        authUtils.setIsSignedInObserver({
            isSignedIn: async (currentUser: any) => {
                try {
                    // if it is a new user then show complete signup screen
                    if(!auth.isNewUser) {
                        await updateCurrentUserStore(currentUser);
                    }
                    // finish loading status
                    dispatch(setAuthLoading(false));
                }
                catch(error) {
                    console.log(error);
                }
            },
            isSignedOut: () => {
                try {
                    authUtils.cleanUpSession();
                    dispatch(setAuthLoading(false));
                }
                catch(error) {
                    console.log(error);
                    AppUtils.showError(Api.parseResponseError(error));
                }
            },
            sessionWasRefreshed: (currentUser: any) => {
                updateCurrentUserStore(currentUser);
            }
        });

        // set refreshToken interval
        // default is once per hour
        const accessTokenInterval = authUtils.setAutomaticAccessTokenRefreshInterval();

        return () => {
            clearInterval(accessTokenInterval);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //}, [location]);

    // on location change hook
    useEffect(() => {
        // check user subscription status
        if(!!auth.client) {
            fetchCurrentClientSubscription();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    // on redux resident store change
    useEffect(() => {
        if(!!auth.resident && clientSubscriptionIsActive()) {
            dispatch(fetchCurrentMemberRequestsCount());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.resident]);

    // fetch current subscription
    const fetchCurrentClientSubscription = async () => {
        try {
            const client = await getCurrentSubscription();
            setAuthClient(client);
        }
        catch(error) {
            console.log(error);
        }
    }
    
    // verify current subscription status
    const clientSubscriptionIsActive = () => {
        return auth?.client?.status === CLIENT.STATUS.PAID; 
    };

    // if app is loading
    if(auth.loading) {
        return <SplashScreen />;
    }
    return <>{props.children}</>;
};

export default AuthProvider;